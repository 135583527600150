import * as types from '../actions/actionTypes'

export default function userReducer(state = {}, action){
    switch (action.type) {
        case types.LOGIN_SUCCESS:
           return {...state, user: {...state.user, ...action.user}, isLoggedIn: true }
        case types.LOGIN_FAILED:
           return {...state, isLoggedIn: false }
        case types.USER_PROGRESS:
            return {...state, ...action}
        case types.USER_DETAILS:
            return {...state, user: {...state.user, ...action.user}}
        case types.USER_UPDATE:
            return {...state, user: {...state.user, ...action.user}}
        case types.USER_UPLOAD_FILE:
            return {...state, user: {...state.user, ...action.user}}
        case types.USER_REMOVE_CV:
            return {...state, user: {...state.user, ...action.user}}
        default:
            return state;
    }
}
