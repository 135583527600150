import React from 'react'
import Logo from '../Logo/Logo'
import './FooterDesign.css'
import Image from 'react-bootstrap/Image';

export default function Footer() {
    return (
        <div className="footer">
            <Image className="decoration" src="/footer-decoration.png"/>
            <Image className="decoration-rotated" src="/footer-decoration-rotated.png"/>
            <div className="footerWrapper">
                <div className="footerLinks">
                    <Logo image="/brainster-learn-logo-white.svg" />
                </div>
                <div className="footerLinks">
                    <p>Social Media</p>
                    <div>
                        <a href="https://www.facebook.com/brainster.io/" target="_blank" rel="noopener noreferrer">Fb</a>
                        <a href="https://www.instagram.com/brainsterco/" target="_blank" rel="noopener noreferrer">Ig</a>
                        <a href="https://www.linkedin.com/school/brainster-co/" target="_blank" rel="noopener noreferrer">Li</a>
                    </div>
                </div>
            </div>
            <div className="footerMessage">
                <p>Made with <span className="heartIcon">&#9829; </span>by Brainster Students</p>
            </div>
        </div>
    )
}
