import styled from 'styled-components';

const Navbar = styled.nav`
    display: flex;
    flex-direction: row;
    padding: 0px;
    @media screen and (max-width: 1240px)  {
        display: none;
    }
`;

export default Navbar
