import React from 'react'
import styled from 'styled-components';

const CloseMenuIcon = styled.img`
    display: none;
    float: right;
    @media screen and (max-width: 1240px) { {
        display: block;
        margin-top: 21px;
        margin-right: 31px;
    }
`

export default function CloseMenu({ closeMenu }) {
    return (
        <CloseMenuIcon src="/close-menu.png" onClick={closeMenu} />
    )
}
