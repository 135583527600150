import React, { useState, useEffect } from 'react'
import styled from 'styled-components'
import { Link, animateScroll as scroll, scrollSpy, scroller } from 'react-scroll'
import { Collapse } from 'antd'
import 'antd/dist/antd.css'
import './MenuItem.css'

import { connect } from 'react-redux'

const slugify = require('slugify')

const LeftSidebarWrapper = styled.div`
    width: 25%;
    @media only screen and (max-width: 910px) {
        display: none;
    }
`
const ContentMenuWrapper = styled.div`
    position: sticky;
    top: 10px;
    width: 100%;
    margin: auto;
    min-height: 200px;
    height: auto;
    filter: drop-shadow(0px 8px 30px rgba(0, 0, 0, 0.08));
    border-radius: 50px 50px 10px 10px;
    background: #FFFFFF;
    margin-bottom: 800px;
`

const ModuleBarHeader = styled.div`
    height: 50px;
    background: #F9F9F9;
    border-radius: 50px 50px 0px 0px;
`
const ModuleBarHeaderTitle = styled.div`
    border-radius: 50px 0px 50px 0px;
    background: #FFFFFF;
    width: 60%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;

    p {
        font-family: Ubuntu;
        font-style: normal;
        font-weight: bold;
        font-size: 12px;
        line-height: 14px;
        text-transform: uppercase;
        color: #65676C;
        margin-bottom: 0px;
    }
`

const ModuleBarBody = styled.div`
    height: 85vh;
    overflow: auto;
    padding: 32px 24px;
    background: #FFFFFF;
    border-radius: 0px 0px 10px 10px;

    &::-webkit-scrollbar {
        width: 3px;
    }

    &::-webkit-scrollbar-track {
        box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
    }

    &::-webkit-scrollbar-thumb {
        background-color: darkgrey;
        outline: 1px solid slategrey;
    }
`
const CollapseModuleTitle = styled.span`
    font-family: Ubuntu;
    font-style: normal;
    font-weight: bold;
    font-size: 16px;
    line-height: 18px;
    color: #191919;
`

const CollapseModuleSubTitle = styled.span`
    font-family: Ubuntu;
    font-style: normal;
    font-weight: bold;
    font-size: 14px;
    line-height: 16px;
    color: #005EFF;
`
const CollapseModuleLecture = styled(Link)`
    font-family: Ubuntu;
    font-style: normal;
    font-weight: normal;
    font-size: 14px;
    line-height: 16px;
    color: #191919;
    display: block;
    margin: 10px 0px;
`

const { Panel } = Collapse

function ScrollSpyMenu({ materials, slug }) {

    const [activeMenu, setActiveMenu] = useState(undefined)
    const [activeSubmenu, setActiveSubmenu] = useState(undefined)

    const [menus, setMenus] = useState([])
    const [submenus, setSubmenus] = useState([])

    const [units, setUnits] = useState([]);
    const [subunits, setSubunits] = useState([]);
    const [activeCategory, setActiveCategory] = useState({});

    const handleSetActiveMenu = (e) => {
        setActiveMenu(menus.indexOf(e))
    }

    const handleSetActiveSubmenu = (e) => {
        setActiveSubmenu(submenus.indexOf(e))
    }

    useEffect(() => {
        if (materials) {
            setActiveCategory(() => {
                return materials.categories.categories.find((category) => category.slug === slug)
            })
            setUnits(() => {
                return materials.units.filter((unit) => unit.categorySlug === slug)
            })
            setMenus(() => {
                let filteredData = materials.units.filter((unit) => unit.categorySlug === slug)
                let mappedData = filteredData.map((unit) => {
                    return slugify(unit.title)
                })
                let finalArray = ['', ...mappedData]
                return finalArray
            })
            setSubmenus([''])
            materials.units.forEach(unit => {
                if(unit.categorySlug === slug){
                    let temp = []
                    unit.subunits.forEach((subunit) => {
                        temp.push(slugify(subunit.id + subunit.title))
                    })
                    setSubmenus((submenus) => {
                        submenus = [...submenus, ...temp]
                        return submenus
                    })
                }
            })
            setActiveMenu(undefined)
            setActiveSubmenu(undefined)
        }
    }, [materials, slug])



    return (
        <LeftSidebarWrapper>
            <ContentMenuWrapper>
                <ModuleBarHeader>
                    <ModuleBarHeaderTitle>
                        <p>Module Bar</p>
                    </ModuleBarHeaderTitle>
                    <ModuleBarBody>
                        <Collapse accordion bordered={false} ghost activeKey={activeMenu}>
                            {units && units.map((unit, index) => {
                                return <Panel
                                    header={
                                        <Link to={slugify(unit.title)} spy={true} smooth={true} duration={50} onSetActive={handleSetActiveMenu} activeClass="activeMenuItem">
                                            <CollapseModuleTitle>{index + 1 + '. ' + unit.title}</CollapseModuleTitle>
                                        </Link>
                                    }
                                    key={menus.indexOf(slugify(unit.title))}
                                    showArrow={false}
                                >
                                    <Collapse accordion bordered={false} ghost activeKey={activeSubmenu}>
                                        {unit.subunits.map((subunit, index) => {
                                            return subunit.resources.length > 0 ? <Panel
                                                header={
                                                    <Link to={slugify(subunit.id + subunit.title)} spy={true} smooth={true} duration={50} onSetActive={handleSetActiveSubmenu} activeClass="activeMenuItem">
                                                        <CollapseModuleSubTitle>{subunit.title}</CollapseModuleSubTitle>
                                                    </Link>
                                                }
                                                key={submenus.indexOf(slugify(subunit.id + subunit.title))}
                                                showArrow={false}
                                            >
                                            {
                                                subunit.resources.map((resource) => {
                                                    return <CollapseModuleLecture to={slugify(resource.id + resource.title)} spy={true} smooth={true} duration={50} activeClass="activeMenuItem">{resource.title}</CollapseModuleLecture>
                                                })
                                            }
                                            </Panel>
                                            :
                                            null
                                        })
                                        }
                                    </Collapse>
                                </Panel>
                            })}
                        </Collapse>
                    </ModuleBarBody>
                </ModuleBarHeader>
            </ContentMenuWrapper>
        </LeftSidebarWrapper>
    )
}

function mapStateToProps(state) {
    return {
        materials: state.materialReducer.materials
    }
}

export default connect(mapStateToProps, null)(ScrollSpyMenu)
