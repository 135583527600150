import * as types from './actionTypes'
import * as pointsApi from "../../api/pointsApi"
import { logout } from './userActions'
import { navigate } from '@reach/router'

export function points(points) {
    return { type: types.GET_POINTS, points }
}

export function totalPoints(totalPoints) {
    return { type: types.GET_TOTAL_POINTS, totalPoints }
}

export function studentPoints(studentPoints) {
    return { type: types.GET_STUDENT_POINTS, studentPoints }
}

export function attendancePoints(points) {
    return { type: types.SET_ATTENDANCE_POINTS, points }
}

export function setAttendancePoints(data) {
    return function (dispatch) {
        return pointsApi.setAttendancePoints(data)
            .then((result) => {
                dispatch(attendancePoints(result))
            }).catch((err) => {
                if(err.status === 401 && err.statusText === "Unauthorized"){
                    localStorage.removeItem('userAT')
                    dispatch(logout())
                    navigate('/login')
                }
                throw err
            });
    }
}

export function getPoints() {
    return function (dispatch) {
        return pointsApi.getPoints()
            .then((result) => {
                dispatch(points(result))
            }).catch((err) => {
                if(err.status === 401 && err.statusText === "Unauthorized"){
                    localStorage.removeItem('userAT')
                    dispatch(logout())
                    navigate('/login')
                }
                throw err
            });
    }
}

export function getTotalPoints() {
    return function (dispatch) {
        return pointsApi.getTotalPoints()
            .then((result) => {
                dispatch(totalPoints(result))
            }).catch((err) => {
                if(err.status === 401 && err.statusText === "Unauthorized"){
                    localStorage.removeItem('userAT')
                    dispatch(logout())
                    navigate('/login')
                }
                throw err
            });
    }
}
export function getStudentPoints(studentId) {
    return function (dispatch) {
        return pointsApi.getStudentPoints(studentId)
            .then((result) => {
                dispatch(studentPoints(result))
            }).catch((err) => {
                if(err.status === 401 && err.statusText === "Unauthorized"){
                    localStorage.removeItem('userAT')
                    dispatch(logout())
                    navigate('/login')
                }else if(err.status === 403){
                    navigate('/')
                }
                throw err
            });
    }
}
