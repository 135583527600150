import React from "react";
import styled from "styled-components";

// styled-components
const StyledContainer = styled.section`
  display: ${(props) => (props.show ? "block" : "none")};
  width: 100%;
  max-width: 1200px;
  margin: 20px auto 0  0;
  background-color: '#E1E2E7';
  border-radius: 10px;
  position: relative;

`;
const StyledInnerWrap = styled.section`
  display: flex;
  flex-direction: column;

  @media only screen and (min-width: 992px) {
    flex-direction: row;
  }

`;
const StyledImageBox = styled.article`
  width: 100%;
  border-radius: 10px;

  img {
    width: 100%;
    display: block;
    border-radius: 10px;
    overflow: hidden;
  }
`;
const StyledButtonsGroup = styled.article`
  width: 100%;
  margin-top: 20px;

  h3 {
    text-align: center;
    line-height: 1.5;
    font-size: 1.2rem;
    font-weight: bold;
    color: '#232426';

    @media only screen and (min-width: 992px) {
        margin-bottom: 30px;
        font-size: 25px;
      }
  }

  .social-icons {
    display: flex;
    justify-content: center;
    align-items: center;
    padding-bottom: 30px;
    margin-top: 10px;

    button {
      border: none;
      margin-left: 10px;
      background-color: transparent;
      cursor: pointer;

      :hover {
        transform: scale(1.05);
      }
    }

    img {
      width: 35px;
      display: block;
      -webkit-box-reflect: below 3px
        linear-gradient(to bottom, rgba(0, 0, 0, 0), rgba(0, 0, 0, 0.4));

      @media only screen and (min-width: 992px) {
        width: 50px;
      }
    }
  }

  @media only screen and (min-width: 992px) {
    margin-top: 0;
    margin-left: 30px;
    align-self: center;
    width: 60%;
  }
`;
const StyledShareButtonsWrap = styled.div`
  width: 100%;
  text-align: center;
  border-radius: 8px;
  margin-top: 15px;
  background-color: #fff;
  padding: 15px 0;

  @media only screen and (min-width: 992px) {
    padding: 40px 0;

    .share-title {
      font-size: 20px;
      font-weight: bold;
      margin-bottom: 40px;

      @media only screen and (min-width: 992px) {
        margin-bottom: 30px;
        font-size: 25px;
      }
    }
  }
`;
const ShareCertificate = ({ show, user }) => {
  const onShareCertificate = (media) => {
    let text = 'Brainster Graduate';
    let urlToShare = user?.certificate?.image_url;
    let socialMediaShareUrl = false;
    switch (media) {
        case 'Facebook':
            socialMediaShareUrl = `https://www.facebook.com/sharer/sharer.php?u=${urlToShare}&quote=${text}`;
            break;
        case 'Twitter': 
            socialMediaShareUrl = `https://twitter.com/intent/tweet?url=${urlToShare}&text=${text}`;
            break;
        case 'LinkedIn':
            socialMediaShareUrl = `https://www.linkedin.com/sharing/share-offsite/?url=${urlToShare}`;
            break;
        default:
            break;
    }

    if(socialMediaShareUrl) {
        javascript:window.open(socialMediaShareUrl, `${media} Share`,'width=600,height=400')
    }
};
  return (
      <StyledContainer show={show} onClick={(e) => e.stopPropagation()}>
        <StyledInnerWrap>
          <StyledImageBox>
            <img src={user?.certificate?.image_url} alt="Certificate Image" />
          </StyledImageBox>
          <StyledButtonsGroup>
            <h3 className="">
              Every achievement and success is worth spreading!
            </h3>
            <StyledShareButtonsWrap>
              <p className="share-title">
                <span>Share yours on</span>
              </p>
              <div className="social-icons">
                <button type="button" onClick={() => onShareCertificate('LinkedIn')}>
                  <img
                    src="/icons8-linkedin-2.svg"
                    alt="LinkedIn Icon"
                  />
                </button>
                <button type="button" onClick={() => onShareCertificate('Twitter')}>
                  <img src="/icons8-twitter.svg" alt="Twitter Icon" />
                </button>
                <button type="button" onClick={() => onShareCertificate('Facebook')}>
                  <img src="/icons8-facebook.svg" alt="Facebook Icon" />
                </button>
              </div>
            </StyledShareButtonsWrap>
          </StyledButtonsGroup>
        </StyledInnerWrap>
      </StyledContainer>

  );
};
export default ShareCertificate;
