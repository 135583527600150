import React, { useState, useEffect } from 'react'
import { connect } from 'react-redux'
import PageWrapper from '../Components/Wrappers/PageWrapper'
import Header from '../Components/Common/Header/Header'
import Footer from '../Components/Common/Footer/Footer'
import SurveyContentWrapper from '../Components/Wrappers/SurveyContentWrapper'

const Survey = ({ surveyId }) => {

    return (
        <PageWrapper>
            <Header />
            <SurveyContentWrapper surveyId={surveyId}/>
            <Footer />
        </PageWrapper>
    )

}

function mapStateToProps(state) {
    return {

    }
}

const mapDispatchToProps = {

}

export default connect(null, null)(Survey)
