import React, { useState, useEffect, useCallback } from 'react'
import styled from 'styled-components'
import { trackPromise } from 'react-promise-tracker'
import { connect } from 'react-redux'
import { Element } from 'react-scroll'
import { navigate } from "@reach/router"

import ProfileInfo from './ProfileInfo'
import Leaderboard from './Leaderboard'
import ProgressTracker from './ProgressTracker'
import AchievementsWrapper from './AchievementsWrapper'

import { Swiper, SwiperSlide } from 'swiper/react';
import 'swiper/swiper.min.css';

import * as jobOfferActions from "../../../redux/actions/jobOfferActions"

const HomepageSection = styled.div`
    margin-top: 50px;
`

const HomepageSectionTitle = styled.p`
    font-family: Ubuntu;
    font-style: normal;
    font-weight: bold;
    font-size: 24px;
    line-height: 28px;
    color: #191919;
    display: flex;
    justify-content: space-between;
`

const LeaderboardsWrapper = styled.div`
    display: flex;
    justify-content: space-between;
    .leaderB {
        display: flex;
        justify-content: space-between;
        width: 100%;
        margin-bottom: 180px;
    }
    .leaderBSwiper {
        display: none !important;
    }
    @media only screen and (max-width: 600px) {
        .leaderB {
            display: none !important;
        }
        .leaderBSwiper {
            display: block !important;
        }
    }
`
export const Wrapper = styled.div`
    width: 70%;
    @media screen and (max-width: 1240px) {
        width: 100%;
        padding: 0% 5%;
    }
`

const SwipeImg = styled.img`
    display: none;

    @media only screen and (max-width: 600px) {
        display: inline;
    }
`

const TalentMarketWrapper = styled.div`
    background: radial-gradient(131.25% 39562.5% at 1.09% 0%,#00D762 15.51%,#005EFF 100%);;
    text-align: center;
    padding: 20px;
    color: white;
    border-radius: 10px;
    font-weight: bold;

    &:hover {
        cursor: pointer;
    }
`

function ContentWrapper({ user, offers, getJobOffers }) {
    useEffect(() => {
        trackPromise(getJobOffers())
    }, [getJobOffers]
    )

    return (
        <Wrapper>
            {(offers && Object.keys(offers).length > 0) ?
                <TalentMarketWrapper onClick={() => navigate('/offer')}>
                    A new job offer is available for you. To see more details and respond to the offer, click this box.
                </TalentMarketWrapper>
                : null
            }
            <ProfileInfo />
            <Element name="progressTracker">
                <HomepageSection>
                    <HomepageSectionTitle>Progress Tracker</HomepageSectionTitle>
                    <ProgressTracker />
                </HomepageSection>
            </Element>
            <Element name="achievements">
                <HomepageSection>
                    <HomepageSectionTitle>Achievements</HomepageSectionTitle>
                    <AchievementsWrapper />
                </HomepageSection>
            </Element>
            {
                user && user.group && user.group.status === 'LECTURES IN PROGRESS' ?
                    <Element name="leaderboards">
                        <HomepageSection>
                            <HomepageSectionTitle><span>Leaderboards</span> <SwipeImg src="/swipe.svg" /></HomepageSectionTitle>
                            <LeaderboardsWrapper>
                                <div className="leaderB">
                                    <Leaderboard header="Total Points" type="total" />
                                    <Leaderboard header="Challenges" type="challenge" />
                                    <Leaderboard header="Attendance" type="attendance" />
                                </div>
                                <Swiper className="leaderBSwiper" spaceBetween={50} slidesPerView={1}>
                                    <SwiperSlide>
                                        <Leaderboard header="Total Points" type="total" />
                                    </SwiperSlide>
                                    <SwiperSlide>
                                        <Leaderboard header="Challenges" type="challenge" />
                                    </SwiperSlide>
                                    <SwiperSlide>
                                        <Leaderboard header="Attendance" type="attendance" />
                                    </SwiperSlide>
                                </Swiper>
                            </LeaderboardsWrapper>
                        </HomepageSection>
                    </Element>
                    :
                    ''
            }

        </Wrapper>
    )
}

const mapDispatchToProps = {
    getJobOffers: jobOfferActions.getJobOffers,
}

function mapStateToProps(state) {
    return {
        offers: state.jobOfferReducer.offers,
        user: state.userReducer.user
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(ContentWrapper)
