import React, { useState, useEffect, useCallback } from 'react'

import Col from 'react-bootstrap/Col'
import Image from 'react-bootstrap/Image'
import Form from 'react-bootstrap/Form'
import Loader from "react-loader-spinner";
import "react-loader-spinner/dist/loader/css/react-spinner-loader.css"

import { connect } from 'react-redux'
import * as userActions from '../../redux/actions/userActions'
import { UserDetailsWrapper, ImageSection, StyledImage, UploadImageDiv, FormSection, StyledInputMask } from './Components/UserDetailsComponents';
import { ImageCropper } from '../RedesignComponents/Common/ImageCropper';
import { ProfileImage, ProfileImageAcronym } from '../RedesignComponents/Homepage/ProfileInfo'
import { getCroppedImg } from '../RedesignComponents/Common/ImageCropperLib';


function UserDetails({ user, countries, userUpdate, userUpload }) {
    const [inputs, setInputs] = useState({});
    const [countriesArray, setCountriesArray] = useState([])
    const [saveBtnDisabled, setSaveBtnDisabled] = useState(true)
    const [saveBtnSpinner, setSaveBtnSpinner] = useState(false)
    const [uploadPhotoSpinner, setUploadPhotoSpinner] = useState(false)
    const [showCropper, setShowCropper] = useState(false)
    const [crop, setCrop] = useState({ x: 0, y: 0 })
    const [croppedAreaPixels, setCroppedAreaPixels] = useState(null)
    const [croppedImage, setCroppedImage] = useState(null)
    const [zoom, setZoom] = useState(1)
    const [acronym, setAcronym] = useState('')

    const handleInputChange = (e) => {
        e.persist()
        setInputs(inputs => ({...inputs, [e.target.name]: e.target.value}))
    }

    const handleFormSubmit = (e) => {
        setSaveBtnSpinner(true)
        e.preventDefault()
        const dataToUpdate = {
            name: inputs.name,
            countryId: inputs.countryId,
            city: inputs.city,
            phone: inputs.phone,
            dob: inputs.dob,
            gender: inputs.gender
        }
        userUpdate(dataToUpdate)
        .then(() => {
            setSaveBtnSpinner(false)
            setSaveBtnDisabled(true)
        })
    }

    const browseImage = () => {
        document.getElementById('browsePhoto').click()
    }

    const handlePhotoSelected = (e) => {
        const data = new FormData()
        data.append(e.target.name, e.target.files[0])
        userUpload(data)
        .then((resp) => {
            setTimeout(() => {
                setShowCropper(true)
            }, 1000)
        })
    }

    const onCropComplete = useCallback((croppedArea, croppedAreaPixels) => {
        setCroppedAreaPixels(croppedAreaPixels)
    }, [])

    const handleCloseImageCropper = () => {
        setShowCropper(false)
    }

    const showCroppedImage = useCallback(async () => {
        try {
            const croppedPhoto = await getCroppedImg(process.env.REACT_APP_FILES_URL + user.profile, croppedAreaPixels)
            setCroppedImage(croppedPhoto)
            const data = new FormData()
            data.append('croppedPhoto', croppedPhoto)
            userUpload(data)
            .then((resp) => {
                setShowCropper(false)
            })
        } catch (e) {
            console.error(e)
        }
    }, [croppedAreaPixels])

    useEffect(() => {
        setInputs(() => ({...inputs, ...user}))
    },
    [user]
    )

    useEffect(() => {
        if(user){
            if(JSON.stringify(inputs).replace(/null/gi, '""') !== JSON.stringify(user).replace(/null/gi, '""')){
                setSaveBtnDisabled(false)
            }else {
                setSaveBtnDisabled(true)
            }
        }
    }, [inputs])

    useEffect(() => {
        setCountriesArray((countriesArray) => {
            if(countries){
                return Object.keys(countries).map((countryId) => { return countries[countryId] })
            }else{
                return []
            }
        })
    }, [countries])

    useEffect(() => {
       if(user){
           let name = user.name
           let splittedName = name.split(' ').reverse()
           if(splittedName.length > 1){
               setAcronym(splittedName[0].substr(0,1) + splittedName[1].substr(0,1))
           }else{
               setAcronym(splittedName[0].substr(0,2))
           }
       }
    }, [user])

    return (
        <section id="section-1" className="section1">
            <UserDetailsWrapper isdisabled={saveBtnDisabled}>
                <p>Basic Info</p>
                <ImageSection sm={12} md={12} lg={2} className="width-fix">
                    {
                        (showCropper && user) ? <ImageCropper show={showCropper} handleCloseTable={handleCloseImageCropper} user={user} crop={crop} setCrop={setCrop} zoom={zoom} setZoom={setZoom} onCropComplete={onCropComplete} showCroppedImage={showCroppedImage}/> : null
                    }
                    <input type="file" name="photo" id="browsePhoto" style={{display: "none"}} onChange={handlePhotoSelected}/>
                    {
                        user && user.profile ?
                        <ProfileImage imageSrc={user && user.profile ? (process.env.REACT_APP_FILES_URL + user.profile) : "profile-picture.png"}>
                            <img src="/add-image.svg" alt="user" onClick={browseImage} />
                        </ProfileImage>
                        :
                        <ProfileImageAcronym>
                            <span>{acronym}</span>
                            <img src="/add-image.svg" alt="user" onClick={browseImage} />
                        </ProfileImageAcronym>
                    }
                </ImageSection>
                <FormSection sm={12} lg={10}>
                    <Form onSubmit={handleFormSubmit}>
                        <Form.Row className="mobileDesignName">
                            <Form.Group as={Col} controlId="formName">
                                <Form.Label>Name</Form.Label>
                                <Form.Control type="text" placeholder="Insert Your Name" name="name" onChange={handleInputChange} value={inputs.name || ''} />
                            </Form.Group>
                        </Form.Row>

                        <Form.Row className="mobileDesignEmail">
                            <Form.Group as={Col} controlId="formEmail">
                                <Form.Label>Email</Form.Label>
                                <Form.Control type="email" placeholder="Enter email" disabled name="email" onChange={handleInputChange} value={inputs.email || ''} />
                            </Form.Group>
                        </Form.Row>

                        <Form.Row className="mobileDesignLocation">
                            <Form.Group className="mobileDesignCountry" as={Col} controlId="formCountry">
                                <Form.Label>Location</Form.Label>
                                <Form.Control as="select" value={inputs.countryId} name="countryId" onChange={handleInputChange}>
                                    <option>Please select</option>
                                    {
                                        countriesArray.map((country) => {
                                            return <option value={country.id} key={country.id}>{country.name}</option>
                                        })
                                    }
                                </Form.Control>
                            </Form.Group>

                            <Form.Group className="mobileDesignCity" as={Col} controlId="formCity">
                                <Form.Label>City</Form.Label>
                                <Form.Control type="text" placeholder="City" name="city" onChange={handleInputChange} value={inputs.city || ''} />
                            </Form.Group>
                        </Form.Row>

                        <Form.Row>
                            <Form.Group as={Col} sm={6} controlId="formPhone">
                                <Form.Label>Telephone number</Form.Label>
                                <Form.Control type="text" placeholder="+3897xxxxxxx" name="phone"  onChange={handleInputChange} value={inputs.phone || ''}/>
                            </Form.Group>
                        </Form.Row>

                        <Form.Row>
                            <Form.Group as={Col} xs={12} sm={12} md={3} lg={3} controlId="formDOB">
                                <Form.Label>Birth Date</Form.Label>
                                <StyledInputMask mask="99.99.9999" alwaysShowMask={true} name="dob" onChange={handleInputChange} value={inputs.dob || ''} />
                            </Form.Group>
                            <Form.Group as={Col} xs={12} sm={12} md={3} lg={3} controlId="formGender">
                                <Form.Label>Gender</Form.Label>
                                <Form.Control as="select" value={inputs.gender} name="gender" onChange={handleInputChange}>
                                    <option>Please select</option>
                                    <option value="MALE">Male</option>
                                    <option value="FEMALE">Female</option>
                                    <option value="OTHER">Other</option>
                                </Form.Control>
                            </Form.Group>
                        </Form.Row>
                        <button type="submit" disabled={saveBtnDisabled} >Save Changes</button>
                        {
                            saveBtnSpinner &&
                            <Loader type="ThreeDots" color="#0A08E5" height={60} width={60} style={{display: "inline-block", "paddingLeft": "40px"}}/>
                        }
                    </Form>
                </FormSection>
            </UserDetailsWrapper>
        </section>
    )
}


const mapDispatchToProps = {
    userUpdate: userActions.update,
    userUpload: userActions.upload
}

export default connect(null, mapDispatchToProps)(UserDetails)
