import * as types from './actionTypes'
import * as materialsApi from "../../api/materialsApi"
import { logout } from './userActions'
import { navigate } from '@reach/router'


export function materials(materials) {
    return { type: types.ALL_MATERIALS, materials }
}

export function updatedResource(materials) {
    return { type: types.UPDATE_RESOURCE_STATUS, materials }
}

export function getMaterials() {
    return function (dispatch) {
        return materialsApi.materialsByGroup()
            .then((result) => {
                dispatch(materials(result))
            }).catch((err) => {
                if (err.status === 401 && err.statusText === "Unauthorized") {
                    localStorage.removeItem('userAT')
                    dispatch(logout())
                    navigate('/login')
                }
                throw err
            });
    }
}


export function updateResource(data) {
    return function (dispatch) {
        return materialsApi.updateResource(data)
            .then((result) => {
                dispatch(updatedResource(result))
            }).catch((err) => {
                if (err.status === 401 && err.statusText === "Unauthorized") {
                    localStorage.removeItem('userAT')
                    dispatch(logout())
                    navigate('/login')
                }
                throw err
            });
    }
}

export function downloadMaterials(id, service) {
    return function (dispatch) {
        return materialsApi.downloadMaterials(id, service)
            .then((result) => {
            }).catch((err) => {
                if (err.status === 401 && err.statusText === "Unauthorized") {
                    localStorage.removeItem('userAT')
                    dispatch(logout())
                    navigate('/login')
                }
                throw err
            });
    }
}
