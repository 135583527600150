import React, { useState, useEffect } from "react";

import { trackPromise } from "react-promise-tracker";
import { Link, navigate } from "@reach/router";
import { connect } from "react-redux";
import styled from "styled-components";

import Navbar from "./components/Navbar";
import Logo from "../Logo/Logo";
import HamburgerMenu from "../HamburgerMenu/HamburgerMenu";
import CloseMenu from "../HamburgerMenu/CloseMenu";

import "./HeaderStyle.css";
import * as categoryActions from "../../../redux/actions/categoryActions";
import * as lectureActions from "../../../redux/actions/lectureActions";
import * as pointActions from "../../../redux/actions/pointActions";

import {
  ProfileBtn,
  HeaderWrapper,
  HeaderContainer,
  BtnContainer,
  BackgroundMobileMenuWrapper,
  MobileMenu,
  MobileMenuNavbarContainer,
  LectureWrapper,
  TalentMarketBtn,
  TalentMarketIcon,
} from "./components/HeaderComponents";
import Logout from "../../../Pages/Auth/Logout";

import { Menu } from "antd";
import {
  ProfileImage,
  ProfileImageAcronym,
} from "../../RedesignComponents/Points/StudentInfo";
import Icon from "../Icon/Icon";
const { SubMenu } = Menu;

const isActiveLink = ({ isCurrent }) => {
  return {
    style: {
      color: isCurrent ? "#0A08E5" : "#232426",
    },
  };
};

const isActiveBtn = ({ isCurrent }) => {
  return isCurrent ? { className: "active" } : {};
};

const NavItem = (props) => <Link getProps={isActiveLink} {...props} />;
const NavItemBtn = (props) => (
  <Link getProps={isActiveBtn} {...props}>
    {" "}
    <ProfileBtn>{props.text}</ProfileBtn>{" "}
  </Link>
);
const StyledNavItem = styled(NavItem)`
  display: flex;
  flex-direction: row;
  padding: 0px 18px;
  align-self: center;
  text-decoration: none;

  &:hover {
    text-decoration: none;
  }

  p {
    position: static;
    font-family: Ubuntu;
    font-style: normal;
    font-weight: bold;
    font-size: 15px;
    line-height: 18px;
    margin: 9px 0px;
    padding-left: 5px;
  }
`;

const SubMenuItems = styled.p`
  font-style: normal;
  font-weight: bold;
  font-size: 16px;
  line-height: 18px;
  margin-bottom: 0px;
  &:hover {
    text-decoration: none;
    color: rgb(10, 8, 229);
  }

  a:hover {
    text-decoration: none;
    color: rgb(10, 8, 229);
  }
`;

function Header({
  user,
  categories,
  getCategories,
  lectures,
  getUpcomingLectures,
  setAttendancePoints,
  offers,
  getJobOffers,
}) {
  const [categoriesArray, setCategoriesArray] = useState([]);
  const [lecturesArray, setLecturesArray] = useState([]);
  const [showMobileMenu, setShowMobileMenu] = useState(false);
  const [showLogoutPopup, setshowLogoutPopup] = useState(false);
  const [acronym, setAcronym] = useState("");

  useEffect(() => {
    if (user) {
      let name = user.name;
      let splittedName = name.split(" ").reverse();
      if (splittedName.length > 1) {
        setAcronym(splittedName[0].substr(0, 1) + splittedName[1].substr(0, 1));
      } else {
        setAcronym(splittedName[0].substr(0, 2));
      }
    }
  }, [user]);

  useEffect(() => {
    trackPromise(getCategories());
    trackPromise(getUpcomingLectures());
  }, [getCategories, getUpcomingLectures, user]);

  useEffect(() => {
    setCategoriesArray(() => {
      if (categories) {
        return Object.keys(categories).map((categoryId) => {
          return categories[categoryId];
        });
      } else {
        return [];
      }
    });
  }, [categories]);

  useEffect(() => {
    setLecturesArray(() => {
      if (lectures) {
        return Object.keys(lectures).map((lecturesId) => {
          return lectures[lecturesId];
        });
      } else {
        return [];
      }
    });
  }, [lectures]);

  const handleCancelButtonClicked = () => {
    setshowLogoutPopup(false);
  };

  const handleClickOpenLogoutPopup = () => {
    setShowMobileMenu(false);
    setshowLogoutPopup(true);
  };

  const handleClickOpenMenu = () => {
    setShowMobileMenu(true);
  };

  const handleClickCloseMenu = () => {
    setShowMobileMenu(false);
  };

  const handleClickLectureOpen = (lecture) => {
    const dataToSubmit = {
      lectureId: lecture.id,
    };
    setAttendancePoints(dataToSubmit);
  };

  const handleProfileClick = () => navigate("/profile");

  return (
    <HeaderContainer>
      <HeaderWrapper>
        <Logo />
        <Navbar>
          {categoriesArray.map((category) => {
            return (
              <StyledNavItem
                to={"/materials/" + category.slug}
                key={category.id}
              >
                <p>{category.title}</p>
              </StyledNavItem>
            );
          })}
        </Navbar>
        <BtnContainer>
          {/* Only users from Macedonia (countryId: 1) should see the button */}
          {user && user.group && user.group.countryId == 1 && (
            <TalentMarketBtn
              onClick={() =>
                window.open(
                  "https://www.facebook.com/groups/326847685817052",
                  "_blank"
                )
              }
            >
              Join hiring group
            </TalentMarketBtn>
          )}
          <Menu
            mode="horizontal"
            style={{ background: "transparent" }}
            triggerSubMenuAction="click"
          >
            <SubMenu
              key="SubMenu"
              title={
                user && user.profile ? (
                  <ProfileImage
                    header
                    imageSrc={
                      user && user.profile
                        ? process.env.REACT_APP_FILES_URL + user.profile
                        : "profile-picture.png"
                    }
                  ></ProfileImage>
                ) : (
                  <ProfileImageAcronym header>
                    <span>{acronym}</span>
                  </ProfileImageAcronym>
                )
              }
            >
              <Menu.ItemGroup
                className="ant-menu-submenu-custom"
                style={{ borderRadius: "18px" }}
              >
                <Menu.Item key="setting:1" onClick={handleProfileClick}>
                  <SubMenuItems onClick={handleProfileClick}>
                    {user && user.profile ? (
                      <ProfileImage
                        className="dropdown-profile-image-icon"
                        header
                        imageSrc={
                          user && user.profile
                            ? process.env.REACT_APP_FILES_URL + user.profile
                            : "profile-picture.png"
                        }
                      ></ProfileImage>
                    ) : (
                      <ProfileImageAcronym
                        className="dropdown-profile-image-icon"
                        header
                      >
                        <span>{acronym}</span>
                      </ProfileImageAcronym>
                    )}
                    Profile
                  </SubMenuItems>
                </Menu.Item>
                {user && user.rocketChatUrl ? (
                  <Menu.Item key="setting:3">
                    <SubMenuItems>
                      <a
                        href={user.rocketChatUrl}
                        rel="noopener noreferrer"
                        target="_blank"
                      >
                        <Icon icon="rocketChat" />
                        Rocket Chat
                      </a>
                    </SubMenuItems>
                  </Menu.Item>
                ) : null}
                <Menu.Item key="setting:3">
                  <SubMenuItems>
                    <Link to={`/points/${user?.id}`}>
                      <Icon icon="leaderboard" />
                      Student Dashboard
                    </Link>
                  </SubMenuItems>
                </Menu.Item>
                <div className="ant-menu-submenu-gray-border">
                  <div></div>
                </div>
                <Menu.Item
                  key="setting:2"
                  onClick={handleClickOpenLogoutPopup}
                  className="logout-item"
                >
                  <SubMenuItems
                    id="logoutBtn"
                    onClick={handleClickOpenLogoutPopup}
                  >
                    <Icon icon="logout" />
                    Logout
                  </SubMenuItems>
                </Menu.Item>
              </Menu.ItemGroup>
            </SubMenu>
          </Menu>
        </BtnContainer>
        <HamburgerMenu openMenu={handleClickOpenMenu} />
        <BackgroundMobileMenuWrapper showMenu={showMobileMenu}>
          <MobileMenu showMenu={showMobileMenu}>
            <CloseMenu closeMenu={handleClickCloseMenu} />
            <MobileMenuNavbarContainer>
              {categoriesArray.map((category) => {
                return (
                  <StyledNavItem
                    to={"/materials/" + category.slug}
                    key={category.id}
                  >
                    <p>{category.title}</p>
                  </StyledNavItem>
                );
              })}
              {user && user.rocketChatUrl ? (
                <a
                  href={user.rocketChatUrl}
                  rel="noopener noreferrer"
                  target="_blank"
                >
                  <ProfileBtn
                    id="logoutBtn"
                    onClick={handleClickOpenLogoutPopup}
                  >
                    Rocket Chat
                  </ProfileBtn>
                </a>
              ) : null}
              <NavItemBtn to="/profile" text="Profile" />

              <NavItemBtn to={`/points/${user?.id}`} text="Student Dashboard" />
              <ProfileBtn
                onClick={() =>
                  window.open(
                    "https://www.facebook.com/groups/326847685817052",
                    "_blank"
                  )
                }
              >
                Join hiring group
              </ProfileBtn>
              <ProfileBtn id="logoutBtn" onClick={handleClickOpenLogoutPopup}>
                Log Out
              </ProfileBtn>
            </MobileMenuNavbarContainer>
          </MobileMenu>
        </BackgroundMobileMenuWrapper>
        <Logout
          show={showLogoutPopup}
          handleCancelButtonClicked={handleCancelButtonClicked}
        />
      </HeaderWrapper>
      <LectureWrapper>
        <ul>
          {lecturesArray.map((lecture) => {
            return (
              <li>
                <a
                  href={lecture.zoom_link}
                  rel="noopener noreferrer"
                  target="_blank"
                  onClick={() => handleClickLectureOpen(lecture)}
                >
                  Zoom Link: {lecture.title} (start: {lecture.start_time})
                </a>
              </li>
            );
          })}
        </ul>
      </LectureWrapper>
    </HeaderContainer>
  );
}

function mapStateToProps(state) {
  return {
    user: state.userReducer.user,
    categories: state.categoryReducer.categories,
    lectures: state.lectureReducer.lectures,
    attendancePoints: state.pointReducer.attendancePoints,
  };
}

const mapDispatchToProps = {
  getCategories: categoryActions.getCategories,
  getUpcomingLectures: lectureActions.getUpcomingLectures,
  setAttendancePoints: pointActions.setAttendancePoints,
};

export default connect(mapStateToProps, mapDispatchToProps)(Header);
