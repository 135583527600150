import React from 'react'
import styled from 'styled-components';

import Moment from 'react-moment';

const SurveyHeaderWrapper = styled.div`
    width: 80%;
    margin: auto;
`

const SurveyHeaderTitle = styled.p`
    font-family: Ubuntu;
    font-style: normal;
    font-weight: bold;
    font-size: 24px;
    line-height: 28px;
    color: #232426;
    text-align: center;
`



export default function SurveyHeader({ dateFrom, dateTo }) {
    return (
        <SurveyHeaderWrapper>
            <SurveyHeaderTitle>
                Survey (
                    {
                        dateFrom ? <Moment format="DD.MM.YYYY">{dateFrom}</Moment> : ""
                    }

                    -
                    {
                        dateTo ? <Moment format="DD.MM.YYYY">{dateTo}</Moment>  : ""
                    }
                )
            </SurveyHeaderTitle>
        </SurveyHeaderWrapper>
    )
}
