export const ATTEMPT_LOGIN = "ATTEMPT_LOGIN"
export const LOGIN_SUCCESS = "LOGIN_SUCCESS"
export const LOGIN_FAILED = "LOGIN_FAILED"
export const LOGOUT_SUCCESS = "LOGOUT_SUCCESS"

export const USER_PROGRESS = "LOGIN_FAILED"
export const USER_DETAILS = "USER_DETAILS"
export const USER_UPDATE = "USER_UPDATE"
export const USER_UPLOAD_FILE = "USER_UPLOAD_FILE"
export const USER_DOWNLOAD_CV = "USER_DOWNLOAD_CV"
export const USER_REMOVE_CV = "USER_REMOVE_CV"
export const USER_PASSWORD_CHANGE = "USER_PASSWORD_CHANGE"
export const USER_VALIDATE_EMAIL = "USER_VALIDATE_EMAIL"
export const USER_GENERATE_PASSWORD = "USER_GENERATE_PASSWORD"

export const ALL_COUNTRIES = "ALL_COUNTRIES"

export const ALL_MATERIALS = "ALL_MATERIALS"
export const UPDATE_RESOURCE_STATUS = "UPDATE_RESOURCE_STATUS"

export const ALL_CATEGORIES = "ALL_CATEGORIES"

export const ALL_MESSAGES = "ALL_MESSAGES"

export const ALL_UPCOMING_LECTURES = "ALL_UPCOMING_LECTURES"

export const SURVEY_DETAILS = "SURVEY_DETAILS"
export const SURVEY_ANSWERS = "SURVEY_ANSWERS"

export const SET_ATTENDANCE_POINTS = "SET_ATTENDANCE_POINTS"
export const GET_POINTS = "GET_POINTS"
export const GET_TOTAL_POINTS = "GET_TOTAL_POINTS"
export const GET_STUDENT_POINTS = "GET_STUDENT_POINTS"
export const ALL_SKILLS = "ALL_SKILLS"
export const ALL_JOB_OFFERS = "All_JOB_OFFERS"
