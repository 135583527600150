import React from 'react'
import styled from 'styled-components'

const AchievementWrapper = styled.div`
    float: left;
    width: 22%;
    box-sizing: border-box;
    margin-bottom: 2%;
    margin-right: 3%;
    background: #FFFFFF;
    box-shadow: 0px 8px 30px rgba(0, 0, 0, 0.08);
    border-radius: 16px;
    padding: 15px;
    text-align: center;
    height: 180px;

    img {
        filter: ${props => props.blured ? 'blur(4px)' : 'unset'};
    }

    p.header{
        margin-top: 8%;
        margin-bottom: 3px;
        font-family: Ubuntu;
        font-style: normal;
        font-weight: bold;
        font-size: 16px;
        line-height: 18px;
        text-align: center;
        color: ${props => props.blured ? 'transparent' : '#232426'};
        text-shadow: ${props => props.blured ? '0 0 5px rgba(0,0,0,0.5)' : 'unset'};
    }

    p {
        font-family: Ubuntu;
        font-style: normal;
        font-weight: normal;
        font-size: 14px;
        line-height: 16px;
        text-align: center;
        color: ${props => props.blured ? 'transparent' : '#65676C'};
        text-shadow: ${props => props.blured ? '0 0 5px rgba(0,0,0,0.5)' : 'unset'};
    }

    @media screen and (max-width: 1240px) {
        width: 45%;
    }
`

function Achievement({image, header, text, blured}) {
    return (
        <AchievementWrapper blured={blured}>
            <img src={image} alt={header} />
            <p className="header">{header}</p>
            <p>{text}</p>
        </AchievementWrapper>
    )
}

export default Achievement
