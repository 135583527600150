import React from 'react'
import { setOfferStatus } from '../../api/jobOfferApi';
import Offer from '../OfferPageComponent/Offer';
import Banner from '../OfferPageComponent/Banner';
import OfferContent from '../OfferPageComponent/OfferContent';
import { OfferPageWrapper, OfferPageContainer } from './Components/OfferPageComponents';


export default function OfferPageContentWrapper({ offers }) {

    return (
        <OfferPageContainer fluid>
            <OfferPageWrapper>
                <Banner />
                <OfferContent offers={offers} />
                <Offer offers={offers} />
            </OfferPageWrapper>
        </OfferPageContainer>
    )
}
