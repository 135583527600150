import styled, {keyframes} from 'styled-components'
import Container from 'react-bootstrap/Container'
import Row from 'react-bootstrap/Row'
import { fadeInUp } from 'react-animations';

const fadeInAnimation = keyframes`${fadeInUp}`;

export const OfferPageContainer = styled(Container)`
    animation: 1s ${fadeInAnimation};
    margin-top: -15px !important;
    padding: 0px !important;

`
export const OfferPageWrapper = styled(Row)`
    margin: 0px !important;
    padding: 0px !important;
`
