import React, { useState } from 'react'

import Col from 'react-bootstrap/Col';
import Form from 'react-bootstrap/Form';
import Loader from "react-loader-spinner";
import "react-loader-spinner/dist/loader/css/react-spinner-loader.css"

import { connect } from 'react-redux'
import * as userActions from '../../redux/actions/userActions'
import { WebDetailsWrapper } from './Components/PrivacyDetailsComponents';

function PrivacyDetails({ userPasswordChange }) {
    const [inputs, setInputs] = useState({});
    const [saveBtnDisabled, setSaveBtnDisabled] = useState(true)
    const [saveBtnSpinner, setSaveBtnSpinner] = useState(false)

    const handleInputChange = (e) => {
        setSaveBtnDisabled(false)
        e.persist()
        setInputs(inputs => ({...inputs, [e.target.name]: e.target.value}))
    }

    const handleFormSubmit = (e) => {
        setSaveBtnSpinner(true)
        e.preventDefault()
        const dataToUpdate = {
            currentPassword: inputs.currentPassword,
            password: inputs.password,
            confirmPassword: inputs.confirmPassword
        }

        if(dataToUpdate.password === dataToUpdate.confirmPassword){
            userPasswordChange(dataToUpdate)
            .then(() => {
                setSaveBtnSpinner(false)
                setSaveBtnDisabled(true)
            })
        }
    }

    return (
        <section id="section-4">
            <WebDetailsWrapper isdisabled={saveBtnDisabled}>
                <Col className="ppPPFormWrapper" sm={6}>
                    <Form onSubmit={handleFormSubmit} autocomplete="off">
                        <p>Privacy</p>
                        <Form.Row>
                            <Form.Group as={Col} controlId="formOldPass">
                                <Form.Label>Confirm your current password</Form.Label>
                                <Form.Control type="text" name="currentPassword" onChange={handleInputChange}/>
                            </Form.Group>
                        </Form.Row>
                        <Form.Row>
                            <Form.Group as={Col} controlId="formNewPass">
                                <Form.Label>Type your new password</Form.Label>
                                <Form.Control type="text" name="password" onChange={handleInputChange} autocomplete="new-password"/>
                            </Form.Group>
                        </Form.Row>
                        <Form.Row>
                            <Form.Group as={Col} controlId="formConfirmNewPass">
                                <Form.Label>Rewrite your new password</Form.Label>
                                <Form.Control type="text" name="confirmPassword" onChange={handleInputChange}/>
                            </Form.Group>
                        </Form.Row>
                        <button disabled={saveBtnDisabled}>Save Changes</button>
                        {
                            saveBtnSpinner &&
                            <Loader type="ThreeDots" color="#0A08E5" height={60} width={60} style={{display: "inline-block", "paddingLeft": "40px"}}/>
                        }
                    </Form>
                </Col>
            </WebDetailsWrapper>
        </section>
    )
}

const mapDispatchToProps = {
    userPasswordChange: userActions.passwordChange
}

export default connect(null, mapDispatchToProps)(PrivacyDetails)
