const axios = require('axios');
const baseUrl = process.env.REACT_APP_API_URL || 'http://localhost:8887/api'

export function attemptLogin(credentials) {
    return axios.post(baseUrl + '/login', credentials)
        .then(function (response) {
            if (response.status === 200) {
                localStorage.setItem("userAT", response.data.access_token)
                return response.data;
            } else {
                throw new Error("Bad Request");
            }
        })
        .catch(function (error) {
            console.log("Api failed", error.response);
            throw error.response
        });
}

export function userLogout() {
    const AuthStr = 'Bearer ' + (localStorage.getItem('userAT') || '')
    return axios.get(baseUrl + '/logout', { headers: { Authorization: AuthStr } })
        .then(function (response) {
            if (response.status === 200) {
                localStorage.removeItem('userAT')
                return response.data;
            } else {
                throw new Error("Bad Request");
            }
        })
        .catch(function (error) {
            console.log("Api failed", error.response);
            throw error.response
        });
}

export function userProgress() {
    const AuthStr = 'Bearer ' + (localStorage.getItem('userAT') || '')
    return axios.get(baseUrl + '/progress', { headers: { Authorization: AuthStr } })
        .then(function (response) {
            if (response.status === 200) {
                return response.data;
            } else {
                throw new Error("Bad Request");
            }
        })
        .catch(function (error) {
            console.log("Api failed", error);
            throw error.response
        });
}

export function userDetails() {
    const AuthStr = 'Bearer ' + (localStorage.getItem('userAT') || '')
    return axios.get(baseUrl + '/students', { headers: { Authorization: AuthStr } })
        .then(function (response) {
            if (response.status === 200) {
                return response.data.data;
            } else {
                throw new Error("Bad Request");
            }
        })
        .catch(function (error) {
            console.log("Api failed", error);
            throw error.response
        });
}

export function userUpdate(data) {
    const AuthStr = 'Bearer ' + (localStorage.getItem('userAT') || '')
    return axios.put(baseUrl + '/students', data, { headers: { Authorization: AuthStr } })
        .then(function (response) {
            if (response.status === 200) {
                return response.data.data;
            } else {
                throw new Error("Bad Request");
            }
        })
        .catch(function (error) {
            console.log("Api failed", error);
            throw error.response
        });
}

export function userUploadFile(data) {
    const AuthStr = 'Bearer ' + (localStorage.getItem('userAT') || '')
    return axios.post(baseUrl + '/students/upload', data, { headers: { Authorization: AuthStr, 'Content-Type': 'multipart/form-data' } })
        .then(function (response) {
            if (response.status === 200) {
                return response.data.data;
            } else {
                throw new Error("Bad Request");
            }
        })
        .catch(function (error) {
            console.log("Api failed", error);
            throw error.response
        });
}

export function userChangePassword(data) {
    const AuthStr = 'Bearer ' + (localStorage.getItem('userAT') || '')
    return axios.put(baseUrl + '/students/password', data, { headers: { Authorization: AuthStr } })
        .then(function (response) {
            if (response.status === 200) {
                return response.data.data;
            } else {
                throw new Error("Bad Request");
            }
        })
        .catch(function (error) {
            console.log("Api failed", error);
            throw error.response
        });
}

export function userValidateEmail(data) {
    return axios.post(baseUrl + '/validate-email', data)
        .then(function (response) {
            if (response.status === 200) {
                return response.data
            } else {
                throw new Error("Bad Request");
            }
        })
        .catch(function (error) {
            console.log("Api failed", error.response);
            throw error.response
        });
}

export function userGeneratePassword(data) {
    return axios.post(baseUrl + '/change-password', data)
        .then(function (response) {
            if (response.status === 200) {
                return response.data
            } else {
                throw new Error("Bad Request");
            }
        })
        .catch(function (error) {
            console.log("Api failed", error);
            throw error.response
        });
}

export function userDownloadCV() {
    const AuthStr = 'Bearer ' + (localStorage.getItem('userAT') || '')
    return axios.get(baseUrl + '/students/download', { headers: { Authorization: AuthStr }, responseType: 'blob' })
        .then(function (response) {
            if (response.status === 200) {
                const url = window.URL.createObjectURL(new Blob([response.data]));
                const link = document.createElement('a');
                link.href = url;
                link.setAttribute('download', 'cv.pdf');
                document.body.appendChild(link);
                link.click();
                link.remove()
            } else {
                throw new Error("Bad Request");
            }
        })
        .catch(function (error) {
            console.log("Api failed", error);
            throw error.response
        });
}

export function userRemoveCV() {
    const AuthStr = 'Bearer ' + (localStorage.getItem('userAT') || '')
    return axios.get(baseUrl + '/students/remove-cv', { headers: { Authorization: AuthStr } })
        .then(function (response) {
            console.log("OVA E RESPONSE", response)
            if (response.status === 200) {
                return response.data.data
            } else {
                throw new Error("Bad Request");
            }
        })
        .catch(function (error) {
            console.log("Api failed", error);
            throw error.response
        });
}
