import * as types from './actionTypes'
import * as jobOfferApi from "../../api/jobOfferApi"
import { logout } from './userActions';
import { navigate } from '@reach/router';


export function allJobOffers(offers) {
    return { type: types.ALL_JOB_OFFERS, offers }
}

export function getJobOffers() {
    return function (dispatch) {
        return jobOfferApi.allJobOffers()
            .then((result) => {
                dispatch(allJobOffers(result))
            }).catch((err) => {
                if (err.status === 401 && err.statusText === "Unauthorized") {
                    localStorage.removeItem('userAT')
                    dispatch(logout())
                    navigate('/login')
                }
                throw err
            });
    }
}

export function setOfferStatus(data) {
    return function (dispatch) {
        return jobOfferApi.setOfferStatus(data)
            .then((result) => {
                dispatch(setOfferStatus(result))
            }).catch((err) => {
                if (err.status === 401 && err.statusText === "Unauthorized") {
                    localStorage.removeItem('userAT')
                    dispatch(logout())
                    navigate('/login')
                }
                throw err
            });
    }
}
