import React from "react";
import Loader from "react-loader-spinner"
import { usePromiseTracker } from "react-promise-tracker";
import "./Spinner.css";

export default function Spinner(props){
    const { promiseInProgress } = usePromiseTracker();
  return (
      promiseInProgress && (
        <div className="spinner">
            <Loader type="ThreeDots" color="#0A08E5" height="100" width="100" />
        </div>
        )
    )
};

