import styled from 'styled-components';

export const HeaderContainer = styled.div`
    position: relative;
    top: 0px;
    width: 100%;
    background: #F9F9F9;
    box-shadow: 0px 8px 30px rgba(0, 0, 0, 0.08);
`;

export const HeaderWrapper = styled.div`
    padding: 0.3rem;
    display: flex;
    justify-content: space-between;
    align-items: center;

`

export const ProfileBtn = styled.button`
    border: none;
    outline: none;
    width: 100px;
    height: 34px;
    float: right;
    margin-top: 16px;
    font-family: Ubuntu;
    font-style: normal;
    font-weight: bold;
    font-size: 12px;
    line-height: 14px;
    color: ${props => props.color ? props.color : '#FFFFFF'};
    background: ${props => props.background ? props.background : '#191919'};
    cursor: pointer;
    margin-left: 10px;
     a{
         width: 100%;
         height: 100%;
         color: white;
     }

    @media screen and (max-width: 1240px) {
        margin-left: 25px;
        display: block;
        float: none;
    }
`
export const NavItemIcons = styled.img`
    position: static;
    width: 16px;
    height: 16px;
    left: 0px;
    top: 1px;
    flex: none;
    order: 0;
    align-self: center;
    margin: 9px 0px;
`

export const BtnContainer = styled.div`
    display: flex;
    align-items:center;
    @media screen and (max-width: 1240px) {
        display: none;
    }
`

export const BackgroundMobileMenuWrapper = styled.div`
    display: none;
    @media screen and (max-width: 1240px) {
        position: fixed;
        left: 0;
        top: 0;
        z-index: 9999;
        background-color: rgb(229, 229, 229, 0.9);
        min-height: -webkit-fill-available;
        width: 100%;
        height: 100%;
        display: ${props => props.showMenu ? 'block' : 'none'};
    }
`

export const MobileMenu = styled.div`
    display: none;
    @media screen and (max-width: 1240px) {
        width: 23%;
        min-height: 100%;
        height: 100%;
        position: fixed;
        right: 0px;
        top: 0px;
        background-color: white;
        z-index: 10000;
        display: ${props => props.showMenu ? 'block' : 'none'};
    }
    @media screen and (max-width: 950px) {
        width: 30%;
    }
    @media screen and (max-width: 768px) {
        width: 40%;
    }
    @media screen and (max-width: 480px) {
        width: 60%;
    }
`

export const MobileMenuNavbarContainer = styled.div`
    margin-top: 40%
`

export const LectureWrapper = styled.div`
    background: radial-gradient(100% 52244.9% at 0.9% 0%, #0A08E5 0%, #FF0000 100%);

    ul {
        list-style: none;
    }

    ul li {
        font-style: normal;
        font-weight: bold;
        font-size: 16px;
        color: #FDFDFD;
        padding: 10px;
        text-align: center;
    }

    a {
        color: #FDFDFD;
    }
`
export const TalentMarketIcon = styled.button`
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 10px;
    margin: 0 20px;
    outline: none;
    border: none;
    background: #F9F9F9;
    
    &:focus {
        .elipse {
            display: none;
        }
        border: 1px solid #1F43E5;
        path {
            fill: #1F43E5;
        }
        border-radius: 50%;
    }

    @media only screen and (max-width: 1240px) {
        background: #FFF;
    }
`
export const TalentMarketBtn = styled.button`
    border: 1px solid #005EFF;
    background: #005EFF;
    height: 40px;
    color: white;
    font-weight: bold;
    padding: 0 15px;
    font-size: 0.7em;

    &:hover {
        border: 1px solid #1F43E5;
        background: #1F43E5;
    }

    @media only screen and (max-width: 910px) {
        display: none;
    }
`
