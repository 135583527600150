import styled from 'styled-components'

import Row from 'react-bootstrap/Row'
import Image from 'react-bootstrap/Image'


export const StyledSection = styled.section`
    margin-top: 50px;
    margin-bottom: 50px;
`

export const WebDetailsWrapper = styled(Row)`
    background: #FFFFFF;
    padding: 40px;
    font-weight: bold;
    font-size: 12px;
    line-height: 14px;
    color: #65676C;
    margin-bottom: 50px;
    border-radius: 20px;

    p {
        font-weight: bold;
        font-size: 12px;
        line-height: 14px;
        text-transform: uppercase;
        color: #232426;
    }

    input,select{
        border: 2px solid #EFEFF4;
        box-sizing: border-box;
        border-radius: 2px;
        height: 40px;
        width: 80% !important;
    }

    select{
        border: 2px solid #EFEFF4;
        box-sizing: border-box;
        border-radius: 2px;
    }

    button {
        height: 34px;
        background: ${props => props.isdisabled ? "#EFEFF4" : "#0A08E5"};
        font-family: Ubuntu;
        font-style: normal;
        font-weight: bold;
        font-size: 12px;
        line-height: 14px;
        color: ${props => props.isdisabled ? "#65676C" : "#FFFFFF"};
        outline: none;
        border: none;
        border-radius: 2px;
        padding: 10px 20px;
    }

    .form-group{
        padding-right: 15px !important;
        padding-left: 5px !important;
    }

    .col-sm-3, .col-sm-9, .col-sm-2{
        padding-right: 0px !important;
        padding-left: 0px !important;
    }

    form .col-sm-2{
        padding-right: 15px !important;
        padding-left: 5px !important;
    }

    @media (min-width: 320px) and (max-width: 480px) {
        padding: 10px;

        .ppWDFormWrapper{
            padding-left: 0px;
            padding-right: 0px;
        }

        .form-group{
            padding-right: 5px !important;
            padding-left: 5px !important;
        }

        input{
            width: 100% !important;
        }
    }
`

export const StyledImage = styled(Image)`
    width: 40px;
    height: 40px;
    margin-top: 22px;
    margin-right: 10px;
`

export const RowWrapper = styled.div`
    display: block;
    width: 50%;

    img {
        display: inline-block;
        margin-top: 0px;
        margin-right: 10px;
        padding: 10px;
    }

    .form-group {
        display: inline-block;
        width:80%
    }

    @media (min-width: 320px) and (max-width: 970px) {
        display: block;
        width: 100%;

        img {
            display: inline-block;
            width: 32px;
            height: 32px;
            margin-top: 0px;
            margin-right: 10px;
        }

        .form-group {
            display: inline-block;
            width:86%
        }
    }
`
