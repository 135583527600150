import React from 'react'
import Image from 'react-bootstrap/Image'
import styled from 'styled-components'

const BannerImage = styled.div`
    margin-right: 0px !important;
    margin-left: 0px !important;
    width: 100%;
`

export default function Banner() {
    return (
        <BannerImage>
            <Image style={{ maxWidth: '100%' }} src="/banner.png" />
        </BannerImage>
    )
}
