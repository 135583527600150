import * as types from '../actions/actionTypes'

export default function lectureReducer(state = {}, action){
    switch (action.type) {
        case types.ALL_UPCOMING_LECTURES:
           return {...state, lectures: {...state.lectures, ...action.lectures}}
        default:
            return state;
    }
}
