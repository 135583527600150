import * as types from '../actions/actionTypes'

export default function materialReducer(state = {}, action){
    switch (action.type) {
        case types.ALL_MATERIALS:
           return {...state, materials: {...state.materials, ...action.materials}}
        case types.UPDATE_RESOURCE_STATUS:
            return {...state, materials: {...state.materials, ...action.materials}}
        default:
            return state;
    }
}