import React, { useContext } from 'react';
import styled, { withTheme, ThemeContext } from 'styled-components';

//refaktoriraj gi svi ostali ikone u nizu
//ubaci gi ikone od Static folder u nizu


// Styled components
const IconContainer = styled.svg`
  position: relative;
  @media (pointer: fine) {
    &:hover {
      path {
        fill: ${props => props.hoverFill};
      }
    }
  }
`;

const Path = styled.path`
  &.stroke-${({ stroking }) => stroking}{
    stroke: #${({ stroking, stroke }) => stroke ? stroke : stroking};
  }
  &.fill-${({ filling }) => filling}{
    fill: #${({ filling, fill }) => fill ? fill : filling};
  }
`

const Circle = styled.circle`
  &.stroke-${({ stroking }) => stroking}{
    stroke: ${({ stroking }) => stroking};
  }
  &.fill-${({ filling }) => filling}{
    fill: ${({ filling }) => filling};
}
`

const Rect = styled.rect`
  &.stroke-${({ stroking }) => stroking}{
    stroke: ${({ stroking }) => stroking};
  }
  &.fill-${({ filling }) => filling}{
    fill: ${({ filling }) => filling};
  }
`

const Polygon = styled.polygon`
  &.stroke-${({ stroking }) => stroking}{
    stroke: ${({ stroking }) => stroking};
  }
  &.fill-${({ filling }) => filling}{
    fill: ${({ filling }) => filling};
  }
`

export const icons = {
    rocketChat: {
        paths: [
            {
                d: "M7.22822 1.89222C7.96788 2.30321 8.66667 2.82327 9.26423 3.40177C10.2268 3.22744 11.2185 3.13979 12.2258 3.13979C15.2389 3.13979 18.0964 3.93059 20.2696 5.3671C21.3956 6.11116 22.29 6.99448 22.9285 7.99176C23.6399 9.10298 24 10.298 24 11.5786C24 12.8252 23.6399 14.0202 22.9285 15.1314C22.29 16.1297 21.3946 17.012 20.2696 17.7561C18.0954 19.1926 15.2389 19.9834 12.2258 19.9834C11.2195 19.9834 10.2268 19.8957 9.26423 19.7214C8.66667 20.2989 7.96788 20.82 7.22822 21.231C3.27689 23.2119 0 21.2777 0 21.2777C0 21.2777 3.04623 18.6891 2.55085 16.4199C1.18735 15.0214 0.449635 13.3356 0.449635 11.5455C0.449635 9.78958 1.18832 8.10376 2.55085 6.70524C3.04623 4.43507 0.000973236 1.84742 0 1.84645C0.000973236 1.84547 3.27786 -0.0877188 7.22822 1.89222Z",
                fill: "#191919"
            },
            {
                d: "M4.86709 15.4986C3.51137 14.4273 2.69678 13.0571 2.69678 11.5631C2.69678 8.13498 6.9829 5.35547 12.2695 5.35547C17.5561 5.35547 21.8423 8.13498 21.8423 11.5631C21.8423 14.9912 17.5561 17.7707 12.2695 17.7707C10.9664 17.7707 9.7245 17.6023 8.59166 17.2965L7.76344 18.097C7.3138 18.5314 6.78631 18.9248 6.23741 19.2355C5.50943 19.5939 4.79021 19.7896 4.0778 19.8491C4.1177 19.776 4.15468 19.702 4.19459 19.629C5.02476 18.096 5.2486 16.7199 4.86709 15.4986Z",
                fill: "#FFFFFF"
            },
            {
                d: "M7.68952 12.9489C6.91093 12.9489 6.2793 12.3187 6.2793 11.5416C6.2793 10.7644 6.91093 10.1353 7.68952 10.1353C8.4681 10.1353 9.09973 10.7654 9.09973 11.5425C9.10071 12.3187 8.46908 12.9489 7.68952 12.9489ZM12.2316 12.9489C11.453 12.9489 10.8214 12.3187 10.8214 11.5416C10.8214 10.7644 11.453 10.1353 12.2316 10.1353C13.0102 10.1353 13.6418 10.7654 13.6418 11.5425C13.6418 12.3187 13.0102 12.9489 12.2316 12.9489ZM16.7727 12.9489C15.9932 12.9489 15.3625 12.3187 15.3625 11.5416C15.3625 10.7644 15.9941 10.1353 16.7727 10.1353C17.5513 10.1353 18.1829 10.7654 18.1829 11.5425C18.1829 12.3187 17.5513 12.9489 16.7727 12.9489Z",
                fill: "#191919"
            }
        ],
        width: 24,
        height: 24,
        viewBox: "0 0 24 24",
        fill: "none"
    },
    logout: {
        paths: [
            {
                d: "M5 5H11C11.55 5 12 4.55 12 4C12 3.45 11.55 3 11 3H5C3.9 3 3 3.9 3 5V19C3 20.1 3.9 21 5 21H11C11.55 21 12 20.55 12 20C12 19.45 11.55 19 11 19H5V5Z",
                fill: "#4D4D4D"
            },
            {
                d: "M20.65 11.65L17.86 8.86C17.54 8.54 17 8.76 17 9.21V11H10C9.45 11 9 11.45 9 12C9 12.55 9.45 13 10 13H17V14.79C17 15.24 17.54 15.46 17.85 15.14L20.64 12.35C20.84 12.16 20.84 11.84 20.65 11.65Z",
                fill: "#4D4D4D"
            }
        ],
        width: 24,
        height: 24,
        viewBox: "0 0 24 24",
        fill: "none"
    },
    leaderboard: {
        paths: [
            {
                d: "M6.5 21H3C2.45 21 2 20.55 2 20V10C2 9.45 2.45 9 3 9H6.5C7.05 9 7.5 9.45 7.5 10V20C7.5 20.55 7.05 21 6.5 21ZM13.75 3H10.25C9.7 3 9.25 3.45 9.25 4V20C9.25 20.55 9.7 21 10.25 21H13.75C14.3 21 14.75 20.55 14.75 20V4C14.75 3.45 14.3 3 13.75 3ZM21 11H17.5C16.95 11 16.5 11.45 16.5 12V20C16.5 20.55 16.95 21 17.5 21H21C21.55 21 22 20.55 22 20V12C22 11.45 21.55 11 21 11Z",
                fill: "#191919"
            }
        ],
        width: 24,
        height: 24,
        viewBox: "0 0 24 24",
        fill: "none"
    }
}

const Icon = React.forwardRef(
    ({ icon,
        width,
        height,
        fill,
        hoverFill,
        onClick,
        className,
        pathStrokeWidth,
        pathStrokeLineCap,
        pathStrokeLineJoin,
        pathClipRule,
        pathFillRule,
        pathFill,
        pathStroke,
        stroke,
        viewBox
    }, ref) => {
        const themeContext = useContext(ThemeContext)

        if (icon) {
            return (
                <IconContainer
                    className={`icon ${className}`}
                    ref={ref}
                    onClick={onClick}
                    width={width ? width : icons[icon].width}
                    height={height ? height : icons[icon].height}
                    viewBox={viewBox ? viewBox : icons[icon].viewBox}
                    stroke={stroke ? stroke : icons[icon].stroke}
                    hoverFill={hoverFill ? hoverFill : icons[icon].hoverFill}
                    fill={fill ? fill : icons[icon].fill}
                    style={icons[icon].style ? icons[icon.style] : {}}
                >
                    {
                        icons[icon].circles &&
                        icons[icon].circles.map((circle, index) => {
                            return (
                                <Circle
                                    themeContext={themeContext}
                                    className={`
                  ${pathStroke ? "" : circle.stroke && "stroke-" + circle.stroke.replace("#", "")} 
                  ${pathStroke ? "" : circle.fill && "fill-" + circle.fill.replace("#", "")} 
                  `}
                                    key={`circle-${index}`}
                                    cx={circle.cx ? circle.cx : icons[icon].circle && icons[icon].circle.cx}
                                    cy={circle.cy ? circle.cy : icons[icon].circle && icons[icon].circle.cy}
                                    r={circle.r ? circle.r : icons[icon].circle && icons[icon].circle.r}
                                    strokeWidth={circle.strokeWidth ? circle.strokeWidth : icons[icon].circle && icons[icon].circle.strokeWidth}
                                    stroke={pathStroke ? pathStroke : ""}
                                    fill={pathFill ? pathFill : ""}
                                    stroking={circle.stroke && circle.stroke.replace("#", "")}
                                    filling={circle.fill && circle.fill.replace("#", "")}
                                />

                            )
                        })
                    }
                    {icons[icon].paths && icons[icon].paths.map((path, index) => {
                        return (
                            < Path
                                themeContext={themeContext}
                                className={`
                  ${pathStroke ? "" : path.stroke && "stroke-" + path.stroke.replace("#", "")} 
                  ${pathStroke ? "" : path.fill && "fill-" + path.fill.replace("#", "")} 
                  haos
                  `}
                                stroke={pathStroke ? pathStroke : ""}
                                fill={pathFill ? pathFill : ""}
                                filling={path.fill && path.fill.replace("#", "")}
                                stroking={path.stroke && path.stroke.replace("#", "")}
                                key={`path-${index}`}
                                d={path.d}
                                strokeWidth={pathStrokeWidth ? pathStrokeWidth : path.strokeWidth}
                                strokeLineCap={pathStrokeLineCap ? pathStrokeLineCap : path.strokeLineCap}
                                strokeLineJoin={pathStrokeLineJoin ? pathStrokeLineJoin : path.strokeLineJoin}
                                clipRule={pathClipRule ? pathClipRule : path.clipRule}
                                fillRule={pathFillRule ? pathFillRule : path.fillRule}
                            />
                        )
                    })}
                    {icons[icon].rectangles && icons[icon].rectangles.map((rectangle, index) => (
                        <Rect
                            themeContext={themeContext}
                            className={`
              ${pathStroke ? "" : rectangle.stroke && "stroke-" + rectangle.stroke.replace("#", "")} 
              ${pathFill ? "" : rectangle.fill && "fill-" + rectangle.fill.replace("#", "")} 
              `}
                            key={`rect-${index}`}
                            x={rectangle.x && rectangle.x}
                            y={rectangle.y && rectangle.y}
                            width={rectangle.width && rectangle.width}
                            height={rectangle.height && rectangle.height}
                            stroke={pathStroke ? pathStroke : ""}
                            fill={pathFill ? pathFill : ""}
                            stroking={rectangle.stroke && rectangle.stroke.replace("#", "")}
                            filling={rectangle.fill && rectangle.fill.replace("#", "")}
                        />
                    ))}
                    {icons[icon].polygons && icons[icon].polygons.map((polygon, index) => (
                        <Polygon
                            themeContext={themeContext}
                            className={`
              ${pathStroke ? "" : polygon.stroke && "stroke-" + polygon.stroke.replace("#", "")} 
              ${pathStroke ? "" : polygon.fill && "fill-" + polygon.fill.replace("#", "")} 
              `}
                            key={`polygon-${index}`}
                            points={polygon.points}
                            stroke={pathStroke ? pathStroke : ""}
                            fill={pathFill ? pathFill : ""}
                            stroking={polygon.stroke && polygon.stroke.replace("#", "")}
                            filling={polygon.fill && polygon.fill.replace("#", "")}
                        />
                    ))}


                </IconContainer>
            )
        }
    }
);

export default withTheme(Icon);