import React, { useState, useEffect, useCallback } from 'react'
import styled from 'styled-components'

const ProfileInfoWrapper = styled.div`
    display: flex;
    align-items: center;
    @media only screen and (max-width: 600px) {
        display: block;
    }
`

export const ProfileImage = styled.div`
    display: flex;
    justify-content: center;
    align-items: center;
    width: ${props => props.header ? '40px' : '80px'};
    height: ${props => props.header ? '40px' : '80px'};
    background-image: url(${props => props.imageSrc});
    background-position: center;
    background-size: cover;
    background-repeat: no-repeat;
    border-radius: 200px;

    &.dropdown-profile-image-icon{
        width: 24px;
        height: 24px;
    }

    img {
        position: relative;
        border-radius: 30px;
        width: 20px;
        height: 20px;
        background-color: black;
        top: 25px;
        right: -33px;
        cursor: pointer;
    }

    @media only screen and (max-width: 600px) {
        float: left;
    }
`

export const ProfileImageAcronym = styled.div`
    display: flex;
    justify-content: center;
    align-items: center;
    width: ${props => props.header ? '40px' : '80px'};
    height: ${props => props.header ? '40px' : '80px'};
    background-color: white;
    border-radius: 200px;
    box-shadow: 0px 8px 16px rgba(0, 0, 0, 0.16);
    
    &.dropdown-profile-image-icon{
        width: 24px;
        height: 24px;
        box-shadow: 0px 2px 5px rgba(0, 0, 0, 0.05);
        margin-right: 16px;
    }

    span {
        font-family: Ubuntu;
        font-style: normal;
        font-weight: normal;
        font-size: ${props => props.header ? '13px' : '26px'};
        line-height: ${props => props.header ? '14px' : '28px'};
        background-image: radial-gradient(131.25% 39562.5% at 1.09% 0%, #00D762 15.51%, #005EFF 100%);
        background-size: 100%;
        background-repeat: repeat;
        -webkit-background-clip: text;
        -webkit-text-fill-color: transparent;
        -moz-background-clip: text;
        -moz-text-fill-color: transparent;
        margin-left: 0px;
    }

    img {
        position: relative;
        border-radius: 30px;
        width: 20px;
        height: 20px;
        background-color: black;
        top: 25px;
        right: -5px;
        cursor: pointer;
    }

    @media only screen and (max-width: 600px) {
        float: left;
    }
`

const ProfileInfoText = styled.p`
    display: inline-block;
    font-family: Ubuntu;
    font-style: normal;
    font-weight: bold;
    font-size: 12px;
    line-height: 14px;
    text-transform: uppercase;
    color: #4D4D4D;
    padding-right: 10px;
    border-right: ${props => props.showRightBorder ? '1px solid #4D4D4D' : 'none'};
    margin-left: 20px;
    margin-bottom: 0px;

    @media only screen and (max-width: 600px) {
        display: block;
        margin-left: 100px;
        border: none;
        margin-bottom: 8px;
    }
`


export default function StudentInfo({ user }) {
    const [acronym, setAcronym] = useState('')

    useEffect(() => {
        if (user) {
            let name = user.name
            let splittedName = name.split(' ').reverse()
            if (splittedName.length > 1) {
                setAcronym(splittedName[0].substr(0, 1) + splittedName[1].substr(0, 1))
            } else {
                setAcronym(splittedName[0].substr(0, 2))
            }
        }
    }, [user])

    return (
        <ProfileInfoWrapper>
            {
                user && user.profile ?
                    <ProfileImage imageSrc={user && user.profile ? (process.env.REACT_APP_FILES_URL + user.profile) : "profile-picture.png"} />
                    :
                    <ProfileImageAcronym>
                        <span>{acronym}</span>
                    </ProfileImageAcronym>
            }
            <ProfileInfoText showRightBorder>{user && user.name}</ProfileInfoText>
            <ProfileInfoText showRightBorder>{user && user.academy}</ProfileInfoText>
            <ProfileInfoText>{user && user.group}</ProfileInfoText>
        </ProfileInfoWrapper>
    )
}


