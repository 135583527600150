import React, { useState, useEffect } from 'react'
import { connect } from 'react-redux'
import ScrollToTop from "react-scroll-to-top"

import * as materialActions from "../redux/actions/materialActions"
import * as userActions from "../redux/actions/userActions"

import PageWrapper from '../Components/Wrappers/PageWrapper';
import Header from '../Components/Common/Header/Header';
import MaterialsContentWrapper from '../Components/Wrappers/MaterialsContentWrapper';
import Footer from '../Components/Common/Footer/Footer'


const Materials = ({ getUserDetails, materials, getMaterials, slug }) => {
    const [activeCategory, setActiveCategory] = useState({});

    useEffect(() => {
        getUserDetails()
        getMaterials()
    }, [getUserDetails, getMaterials]
    )

    useEffect(() => {
        if(materials){
            setActiveCategory(() => {
                return materials.categories.categories.find((category) => category.slug === slug)
            })
        }
    }, [materials, slug])

    return (
        <PageWrapper>
            <ScrollToTop smooth color="#005EFF" style={{backgroundColor: '#F5F5F5', right: 10, boxShadow: '0px 8px 30px rgb(0 0 0 / 8%)'}} />
            <Header />
            <MaterialsContentWrapper slug={slug} activeCategory={activeCategory} />
            <Footer/>
        </PageWrapper>
    )
}

function mapStateToProps(state){
    return {
        materials: state.materialReducer.materials
    }
}

const mapDispatchToProps = {
    getUserDetails: userActions.getUserDetails,
    getMaterials: materialActions.getMaterials
}

export default connect(mapStateToProps, mapDispatchToProps)(Materials)
