import React from 'react';
import { Router } from "@reach/router"
import './App.css';


import Spinner from './Components/Common/Spinner/Spinner';
import PublicRoute from './Components/Routes/PublicRoute';
import PrivateRoute from './Components/Routes/PrivateRoute';
import Login from './Pages/Auth/Login';
import ValidateEmail from './Pages/Auth/ValidateEmail';
import ChangePassword from './Pages/Auth/ChangePassword';
import Homepage from './Pages/Homepage';
import Profile from './Pages/Profile';
import Materials from './Pages/Materials';
import Survey from './Pages/Survey';
import Points from './Pages/Points';
import Offer from './Pages/Offer';

function App() {
    return (
    <div className="App">
      <Spinner />
      <Router primary={false}>
        <ScrollToTop path="/">
            <PublicRoute path="/login" component={Login} />
            <PublicRoute path="/validate-email" component={ValidateEmail} />
            <PublicRoute path="/change-password/:token" component={ChangePassword} />
            <PrivateRoute path="/" component={Homepage} />
            <PrivateRoute path="profile" component={Profile} />
            <PrivateRoute path="points/:userId" component={Points} />
            <PrivateRoute path="/materials/:slug" component={Materials} />
            <PrivateRoute path="/survey/:surveyId" component={Survey} />
            <PrivateRoute path="/offer" component={Offer} />
        </ScrollToTop>
      </Router>
    </div>
  );
}

const ScrollToTop = ({ children, location }) => {
  React.useEffect(() => window.scrollTo(0, 0), [location.pathname])
  return children
}

export default App;
