import React, { useState, useEffect } from 'react'

import Image from 'react-bootstrap/Image';
import Form from 'react-bootstrap/Form';
import Col from 'react-bootstrap/Col';
import styled from 'styled-components'
import { useLocation, navigate } from "@reach/router"
import { parse } from "query-string"

import { connect } from 'react-redux'
import * as userActions from "../../redux/actions/userActions"
import BackgroundImageWrapper from '../../Components/Wrappers/BackgroundImageWrapper';
import BackgroundColorWrapper from '../../Components/Wrappers/BackgroundColorWrapper';
import Loader from 'react-loader-spinner';
import { SuccessMessage } from '../../Components/Common/ValidationMessages/Sucess';
import { ErrorMessage } from '../../Components/Common/ValidationMessages/Error';

const SubmitBtn = styled.button`
    float: right;
    background: #0A08E5;
    color: white;
    outline: none;
    border: none;
    cursor: pointer;
    font-weight: bold;
    font-size: 12px;
    line-height: 14px;
    width: 100px;
    height: 40px;

    &:focus {
        outline: none;
    }

    @media only screen and (max-width: 600px) {
        width: 100%;
        height: 53px;
        background: #005EFF;
        border-radius: 100px;
        padding: 16px 40px;
    }
`

const FormLabels = styled.label`
    font-weight: bold;
    font-size: 12px;
    line-height: 14px;
    color: #0A08E5;
`


const FWrapper = styled.div`
    background: #FDFDFD;
    position absolute;
    right: 40px;
    top: 0px;
    height: 100vh;
    width: 40%;
    display: flex;
    flex-direction: row;
    align-items: center;
    padding: 3%;

    form {
        width: 100%;
    }

    @media only screen and (max-width: 600px) {
        position: relative;
        width: 100%;
        right: 0px;
        height: 55vh;
        bottom: 0px;
        border-radius: 60px 60px 0px 0px;
    }
`

const Logo = styled.img`
    position: relative;
    top: 40px;
    left: 60px;
    width: 160px;

    @media only screen and (max-width: 600px) {
        display: none;
    }
`

const MobileLogo = styled.img`
    display: none;
    width: 160px;
    height: 30px;
    margin: auto;
    margin-top: 20%;
    @media only screen and (max-width: 600px) {
        display: inline;

    }
`

const FormLogo = styled.img`
    width: 160px;
`

const HeaderWrapper = styled.div`
    width: 30%;
    height: 30vh;
    margin-top: 15%;
    margin-left: 60px;

    p {
        font-family: Ubuntu;
        font-style: normal;
        font-weight: bold;
        font-size: 54px;
        line-height: 62px;
        color: #FFFFFF;
        margin-bottom: 5px;
    }

    p.desc {
        font-family: Ubuntu;
        font-style: normal;
        font-weight: normal;
        font-size: 18px;
        line-height: 21px;
        color: #4D4D4D;
    }

    @media only screen and (max-width: 600px) {
        display: none;
    }
`

const FormHolder = styled.div`
    width: 100%;
    height: 70vh;

    p.title {
        font-family: Ubuntu;
        font-style: normal;
        font-weight: bold;
        font-size: 40px;
        line-height: 46px;
        color: #191919;
        margin-top: 15%;
    }

    p.desc {
        font-family: Ubuntu;
        font-style: normal;
        font-weight: bold;
        font-size: 18px;
        line-height: 21px;
        color: #65676C;
        margin-top: 1%;
    }

    @media only screen and (max-width: 600px) {
        height: 50vh;
        img {
            display: none;
        }

        p.title{
            font-style: normal;
            font-weight: bold;
            font-size: 24px;
            line-height: 28px;
            text-align: center;
            color: #191919;
        }

        p.desc {
            display: none;
        }

        label {
            padding-left: 20px;
            font-style: normal;
            font-weight: bold;
            font-size: 12px;
            line-height: 14px;
            color: #65676C;
        }

        input {
            height: 50px;
            background: #EFEFF4;
            border: 2px solid #005EFF;
            box-sizing: border-box;
            border-radius: 100px;
        }
    }
`

const ChangePassword = ({ generatePassword, token }) => {
    const [inputs, setInputs] = useState({password: '', password_confirmation: ''})
    const [loginBtnSpinner, setLoginBtnSpinner] = useState(false)
    const location = useLocation()

    const handleInputChange = (e) => {
        e.persist()
        setInputs(inputs => ({...inputs, [e.target.name]: e.target.value}))
    }

    const handleFormSubmit = (e) => {
        e.preventDefault()
        document.getElementById('successMessage').innerHTML = ""
        document.getElementById('errorMessage').innerHTML = ""
        setLoginBtnSpinner(true)
        const searchParams = parse(location.search)
        generatePassword({...inputs, token: token, ...searchParams})
        .then((data) => {
            setLoginBtnSpinner(false)
            document.getElementById('successMessage').innerHTML = data.success.message
            setTimeout(() => {
                 navigate('/login')
            }, 5000)
        })
        .catch((err) => {
            setLoginBtnSpinner(false)
            document.getElementById('errorMessage').innerHTML = err.data.error.message
        })
    }


    return (
         <BackgroundImageWrapper>
            <MobileLogo src="/brainster-learn-logo-white.svg" />
            <Logo src="/brainster-learn-logo-white.svg"></Logo>
            <HeaderWrapper>
                <p>Future Proof</p>
                <p>Your Career</p>
                <p className="desc">Transform your career by joining a community of 9,000+ alumni to get an advantage on the job market.</p>
            </HeaderWrapper>
            <FWrapper>
                <FormHolder>
                    <FormLogo src="/brainster-learn-logo.svg" alt="brainster logo" />
                    <p className="title">Reset password</p>
                    <p className="desc">This is a reset form only for accepted members of the Academies</p>
                    <Form onSubmit={handleFormSubmit}>
                        <SuccessMessage id="successMessage"></SuccessMessage>
                        <ErrorMessage id="errorMessage"></ErrorMessage>
                        <Form.Row>
                            <Form.Group as={Col} controlId="password">
                                <FormLabels>Password</FormLabels>
                                <Form.Control type="password" placeholder="Type your password here" name="password" onChange={handleInputChange}/>
                            </Form.Group>
                        </Form.Row>
                        <Form.Row>
                            <Form.Group as={Col} controlId="password_confirmation">
                                <FormLabels>Retype Password</FormLabels>
                                <Form.Control type="password" placeholder="Retype your password" name="password_confirmation"  onChange={handleInputChange}/>
                            </Form.Group>
                        </Form.Row>
                        <SubmitBtn>Reset</SubmitBtn>
                        <div style={{width: "100%"}}>
                            {
                                loginBtnSpinner &&
                                <Loader type="ThreeDots" color="#0A08E5" height={60} width={60} style={{float: "right"}}/>
                            }
                        </div>
                    </Form>
                </FormHolder>
            </FWrapper>
        </BackgroundImageWrapper>
    )
}


const mapDispatchToProps = {
    generatePassword: userActions.generatePassword
}


// {
//     <BackgroundImageWrapper>
//             <BackgroundColorWrapper>
//                 <FormWrapper>
                    // <Form onSubmit={handleFormSubmit}>
                    //     <FormHeader>This is login form only for accepted members of the Academies</FormHeader>
                    //     <SuccessMessage id="successMessage"></SuccessMessage>
                    //     <ErrorMessage id="errorMessage"></ErrorMessage>
                    //     <Form.Row>
                    //         <Form.Group as={Col} controlId="password">
                    //             <FormLabels>Password</FormLabels>
                    //             <Form.Control type="password" placeholder="Type your password here" name="password" onChange={handleInputChange}/>
                    //         </Form.Group>
                    //     </Form.Row>
                    //     <Form.Row>
                    //         <Form.Group as={Col} controlId="password_confirmation">
                    //             <FormLabels>Retype Password</FormLabels>
                    //             <Form.Control type="password" placeholder="Retype your password" name="password_confirmation"  onChange={handleInputChange}/>
                    //         </Form.Group>
                    //     </Form.Row>
                    //     <Form.Row>
                    //         <SubmitBtn>Sign up</SubmitBtn>
                    //         {
                    //             loginBtnSpinner &&
                    //             <Loader type="ThreeDots" color="#0A08E5" height={60} width={60} style={{margin: "auto"}}/>
                    //         }
                    //     </Form.Row>
                    // </Form>
//                 </FormWrapper>
//             </BackgroundColorWrapper>
//         </BackgroundImageWrapper>
// }
export default connect(null, mapDispatchToProps)(ChangePassword)
