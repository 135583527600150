import React from 'react'
import styled from 'styled-components';

const HamburgerIcon = styled.img`
    display: none;
    margin-right: 40px;
    @media screen and (max-width: 1240px) {
        display: unset;
    }
`

export default function HamburgerMenu({openMenu}) {
    return (
            <HamburgerIcon src="/hamburger-menu.png" onClick={openMenu}/>
    )
}
