import styled from 'styled-components';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';

export const MaterialsInfoContentWrapper = styled(Row)`
    padding: 0px 70px;
    margin-left: 0px !important;
    margin-right: 0px !important;
    margin-top: 50px;

    .row{
        margin-left: 0px !important;
        margin-right: 0px !important;
    }

    .col{
        padding-left: 0px !important;
        padding-right: 0px !important;
    }

    .col-lg-9 {
        padding-left: 0px !important;
        padding-right: 0px !important;
    }

    #totalProgressSpan {
        display: none;
    }
    @media (min-width: 701px) and (max-width: 950px) {
        padding: 0px 0px;

        .col{
            padding-right: 5px !important;
        }

        .col-md-9 {
            max-width: 100%;
            flex: none;
        }
    }

    @media (min-width: 481px) and (max-width: 700px) {
        padding: 0px 5px;

        .col-lg-9 {
            padding-left: 10px !important;
            padding-right: 10px !important;
        }
        .materialsInfoLeftContainer{
            display: none;
        }

        .materialsInfo{
            font-weight: bold;
            font-size: 15px;
            line-height: 16px;
            text-transform: uppercase;

            img {
                width: 16px;
                height: 16px;
            }
        }
    }

    @media (min-width: 320px) and (max-width: 480px) {
        padding: 0px 5px;

        .col-lg-9, .col {
            padding-left: 10px !important;
            padding-right: 10px !important;
        }
        .materialsInfoLeftContainer{
            display: none;
        }

        .materialsInfo{
            font-weight: bold;
            font-size: 10px;
            line-height: 11px;
            text-transform: uppercase;

            img {
                width: 11px;
                height: 11px;
            }
        }

        #totalProgressSpan {
            display: unset;
        }
    }
`

export const ProgressBarHolder = styled(Col)`
    padding-right: 0px !important;
    @media (min-width: 320px) and (max-width: 480px) {
        margin-left: 22px;
    }
`