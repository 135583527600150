import * as types from './actionTypes'
import * as surveyApi from "../../api/surveyApi"
import { logout } from './userActions';
import { navigate } from '@reach/router';


export function surveyDetails(survey) {
    return { type: types.SURVEY_DETAILS, survey }
}

export function surveyAnswers(result) {
    return { type: types.SURVEY_ANSWERS, result }
}

export function getSurveyDetails(surveyId) {
    return function (dispatch) {
        return surveyApi.getSurveyDetails(surveyId)
            .then((result) => {
                dispatch(surveyDetails(result))
            }).catch((err) => {
                if(err.status === 401 && err.statusText === "Unauthorized"){
                    localStorage.removeItem('userAT')
                    dispatch(logout())
                    navigate('/login')
                }
                throw err
            });
    }
}

export function saveSurveyAnswers(data) {
    return function (dispatch) {
        return surveyApi.saveSurveyAnswers(data)
            .then((result) => {
                dispatch(surveyAnswers(result))
            }).catch((err) => {
                if(err.status === 401 && err.statusText === "Unauthorized"){
                    localStorage.removeItem('userAT')
                    dispatch(logout())
                    navigate('/login')
                }
                throw err
            });
    }
}

