import React, { useState, useEffect, useCallback } from 'react'
import Cropper from 'react-easy-crop'
import styled from 'styled-components'
import { Slider } from 'antd'
import 'antd/dist/antd.css'
import './SliderCss.css'
import "react-loader-spinner/dist/loader/css/react-spinner-loader.css"
import { Modal } from 'antd';


const CropperWrapper = styled.div`
    position: relative;
    width: 100%;
    height: 400px;
    background: #333;
`

const ZoomWrapper = styled.div`
    margin-top: 20px;
    p {
        display: block !important;
        font-weight: normal;
        text-align: left;
        color: #0A08E5;
    }
`

export const ImageCropper = ({ showLoader, show, handleCloseTable, user, crop, setCrop, zoom, setZoom, onCropComplete, showCroppedImage }) => {

    const closeModal = () => handleCloseTable()

    return (
        <Modal visible={show} title="Image Cropper" okText="Crop & save" onCancel={closeModal} onOk={showCroppedImage}>
            <CropperWrapper>
                <Cropper
                    image={ process.env.REACT_APP_FILES_URL + user.profile }
                    crop={crop}
                    aspect={1}
                    zoom={zoom}
                    cropShape="round"
                    showGrid={false}
                    onCropChange={setCrop}
                    onCropComplete={onCropComplete}
                    onZoomChange={setZoom}
                />
            </CropperWrapper>
            <ZoomWrapper>
                <p>Set zoom</p>
                <Slider
                    value={zoom}
                    min={1}
                    max={3}
                    step={0.1}
                    onChange={(z) => setZoom(z)}
                />
            </ZoomWrapper>
        </Modal>
    )
}
