import React from 'react'
import styled from 'styled-components'
import ReactHtmlParser from 'react-html-parser'
import { Element } from 'react-scroll'
import { Collapse } from 'antd'
import Subunit from './Subunit'

const slugify = require('slugify')

const UnitWrapper = styled.div`
    padding: 30px;
    @media only screen and (max-width: 910px) {
        padding: 0px;
    }
`

const UnitHeader = styled.div`
    display: flex;
    justify-content: space-between;
    align-items: center;

    p {
        font-family: Ubuntu;
        font-style: normal;
        font-weight: bold;
        font-size: 24px;
        line-height: 28px;
        color: #232426;
    }

    div {
        display: flex;
        justify-content: space-between;
        align-items: center;

        img {
            margin-right: 5px;
        }
        @media only screen and (max-width: 910px) {
            margin-top: 10px;
            margin-bottom: 10px;
            justify-content: flex-start;
        }
    }

    @media only screen and (max-width: 910px) {
        flex-direction: column-reverse;
        justify-content: flex-start;
        align-items: flex-start;
    }

`
const UnitDescription = styled.div`
    width: 80%;
    font-family: Ubuntu;
    font-style: normal;
    font-weight: normal;
    font-size: 16px;
    line-height: 18px;
    color: #4D4D4D;
    text-align: justify;

    @media only screen and (max-width: 910px) {
        margin-top: 10px;
    }
`

function Unit({ unit, subunits, categoryCompleted}) {
    return (
        <Element name={slugify(unit.title)} id={unit.id}>
            <UnitWrapper>
                <UnitHeader>
                    <p>{unit.title}</p>
                    <div>
                        <img src='/time.svg' alt="some icon" />
                        <span>{unit.duration > 0 ? (unit.duration < 60 ? unit.duration + " minutes" : ( Math.floor(unit.duration / 60) + " Hours " + (unit.duration % 60) + " Minutes")) : ''}</span>
                    </div>
                </UnitHeader>
                <UnitDescription>
                    {unit.description.length > 2 ? ReactHtmlParser(unit.description) : ''}
                </UnitDescription>
                {
                    subunits.map(subunit =>  subunit.resources.length > 0 ? <Subunit subunit={subunit} categoryCompleted={categoryCompleted} /> : null)
                }
            </UnitWrapper>
        </Element>
    )
}

export default Unit
