import React, { useEffect, useState } from 'react'
import styled from 'styled-components'
import { navigate, Link } from '@reach/router';

import ProgressBarComponent from '../Common/ProgressBarComponent'

import { connect } from 'react-redux'

const TrackerWrappers = styled.div`
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
`

const ProgressTrackerWrapper = styled.div`
    display: flex;
    background: #FFFFFF;
    box-shadow: 0px 4px 16px rgba(0, 0, 0, 0.06);
    border-radius: 10px;
    width: 45%;
    height: 115px;
    margin-bottom: 2%;
    padding: 30px;
    cursor: pointer;

    img {
        max-width: 50px;
    }

    @media only screen and (max-width: 600px) {
        flex-direction: column;
        padding: 0px;
        padding-top: 10px;
        padding-left: 10px;
        justify-content: center;

        img {
            width: 48px;
            height: 48px;
        }
    }
`

export const ProgressTrackerWrapperSmaller = styled.div`
    display: flex;
    background: #FFFFFF;
    box-shadow: 0px 4px 16px rgba(0, 0, 0, 0.06);
    border-radius: 10px;
    width: 30%;
    height: 80px;
    margin-bottom: 2%;
    padding: 15px;
    cursor: pointer;
    @media (max-width: 991px) {
        width: 100%;
    }
`

function ProgressTracker({ categories, user }) {

    const [categoriesArray, setCategoriesArray] = useState([])

    useEffect(() => {
        setCategoriesArray(() => {
            if(categories){
                return Object.keys(categories).map((categoryId) => { return categories[categoryId] })
            }else{
                return []
            }
        })
    }, [categories])

    const handleOnClick = (e) => {
        if(e.target.getAttribute('data-slug')){
            navigate('/materials/' + e.target.getAttribute('data-slug'))
        }
    }

    return (
        <TrackerWrappers>
            <ProgressTrackerWrapper>
                <img src="/academy-progress.svg" alt="Academy Progress"/>
                <ProgressBarComponent title={user && user.group && user.group.academy.title} status={user && user.group && Math.round(user.group.groupCompleted)} />
            </ProgressTrackerWrapper>
            {
                categoriesArray.map((category) => {
                    return <ProgressTrackerWrapper data-slug={category.slug} key={category.id} onClick={handleOnClick}>
                                    <img data-slug={category.slug} src={category.totalCompletedResources === category.totalResources ? (process.env.REACT_APP_FILES_URL + category.completedIcon) : (process.env.REACT_APP_FILES_URL + category.icon)}  alt={category.title} onClick={handleOnClick}/>
                                    <ProgressBarComponent slug={category.slug} title={category.title} status={category.totalResources > 0 ? (category.totalCompletedResources / category.totalResources).toFixed(2) * 100 : 0}/>
                            </ProgressTrackerWrapper>
                })
            }
        </TrackerWrappers>
    )
}

function mapStateToProps(state){
    return {
        categories: state.categoryReducer.categories,
        user: state.userReducer.user
    }
}

export default connect(mapStateToProps, null)(ProgressTracker)
