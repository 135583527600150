import React, { useState, useEffect, useCallback } from 'react'
import styled from 'styled-components'
import { trackPromise } from 'react-promise-tracker'


import { ImageCropper } from '../Common/ImageCropper'
import { getCroppedImg } from '../Common/ImageCropperLib'

import { connect } from 'react-redux'
import * as userActions from '../../../redux/actions/userActions'
import { StyledImage } from '../../ProfilepageComponents/Components/UserDetailsComponents'

const ProfileInfoWrapper = styled.div`
    display: flex;
    align-items: center;
    @media only screen and (max-width: 600px) {
        display: block;
    }
    margin-top: 60px;
`

export const ProfileImage = styled.div`
    display: flex;
    justify-content: center;
    align-items: center;
    width: 80px;
    height: 80px;
    background-image: url(${props => props.imageSrc});
    background-position: center;
    background-size: cover;
    background-repeat: no-repeat;
    border-radius: 200px;

    img {
        position: relative;
        border-radius: 30px;
        width: 20px;
        height: 20px;
        background-color: black;
        top: 25px;
        right: -33px;
        cursor: pointer;
    }

    @media only screen and (max-width: 600px) {
        float: left;
    }
`

export const ProfileImageAcronym = styled.div`
    display: flex;
    justify-content: center;
    align-items: center;
    width: 80px;
    height: 80px;
    background-color: white;
    border-radius: 200px;
    box-shadow: 0px 8px 16px rgba(0, 0, 0, 0.16);

    span {
        font-family: Ubuntu;
        font-style: normal;
        font-weight: normal;
        font-size: 26px;
        line-height: 28px;
        background-image: radial-gradient(131.25% 39562.5% at 1.09% 0%, #00D762 15.51%, #005EFF 100%);
        background-size: 100%;
        background-repeat: repeat;
        -webkit-background-clip: text;
        -webkit-text-fill-color: transparent;
        -moz-background-clip: text;
        -moz-text-fill-color: transparent;
        margin-left: 20px;
    }

    img {
        position: relative;
        border-radius: 30px;
        width: 20px;
        height: 20px;
        background-color: black;
        top: 25px;
        right: -5px;
        cursor: pointer;
    }
    @media only screen and (max-width: 600px) {
        float: left;
    }
`

const ProfileInfoText = styled.p`
    display: inline-block;
    font-family: Ubuntu;
    font-style: normal;
    font-weight: bold;
    font-size: 12px;
    line-height: 14px;
    text-transform: uppercase;
    color: #4D4D4D;
    padding-right: 10px;
    border-right: ${props => props.showRightBorder ? '1px solid #4D4D4D' :  'none'};
    margin-left: 20px;
    margin-bottom: 0px;

    @media only screen and (max-width: 600px) {
        display: block;
        margin-left: 100px;
        border: none;
        margin-bottom: 8px;
    }
`

const browseImage = () => {
    document.getElementById('browsePhoto').click()
}

function ProfileInfo({ user, userUpload }) {
    const [showCropper, setShowCropper] = useState(false)
    const [crop, setCrop] = useState({ x: 0, y: 0 })
    const [croppedAreaPixels, setCroppedAreaPixels] = useState(null)
    const [croppedImage, setCroppedImage] = useState(null)
    const [zoom, setZoom] = useState(1)
    const [acronym, setAcronym] = useState('')

    useEffect(() => {
       if(user){
           let name = user.name
           let splittedName = name.split(' ').reverse()
           if(splittedName.length > 1){
               setAcronym(splittedName[0].substr(0,1) + splittedName[1].substr(0,1))
           }else{
               setAcronym(splittedName[0].substr(0,2))
           }
       }
    }, [user])

    const onCropComplete = useCallback((croppedArea, croppedAreaPixels) => {
        setCroppedAreaPixels(croppedAreaPixels)
    }, [])

    const handleCloseImageCropper = () => {
        setShowCropper(false)
    }

    const showCroppedImage = useCallback(async () => {
        try {
            const croppedPhoto = await getCroppedImg(process.env.REACT_APP_FILES_URL + user.profile, croppedAreaPixels)
            setCroppedImage(croppedPhoto)
            const data = new FormData()
            data.append('croppedPhoto', croppedPhoto)
            setShowCropper(false)
            trackPromise(userUpload(data))
            .then((resp) => {
                setShowCropper(false)
            })
        } catch (e) {
            console.error(e)
        }
    }, [croppedAreaPixels])

    const handlePhotoSelected = (e) => {
        const data = new FormData()
        data.append(e.target.name, e.target.files[0])
        trackPromise(userUpload(data))
        .then((resp) => {
            setTimeout(() => {
                setShowCropper(true)
            }, 1000)
        })
    }

    return (
        <ProfileInfoWrapper>
            {
                (showCropper && user) ? <ImageCropper show={showCropper} handleCloseTable={handleCloseImageCropper} user={user} crop={crop} setCrop={setCrop} zoom={zoom} setZoom={setZoom} onCropComplete={onCropComplete} showCroppedImage={showCroppedImage}/> : null
            }
            <input type="file" name="photo" id="browsePhoto" style={{display: "none"}} onChange={handlePhotoSelected}/>
            {
                user && user.profile ?
                <ProfileImage imageSrc={user && user.profile ? (process.env.REACT_APP_FILES_URL + user.profile) : "profile-picture.png"}>
                    <img src="/add-image.svg" alt="user" onClick={browseImage} />
                </ProfileImage>
                :
                <ProfileImageAcronym>
                    <span>{acronym}</span>
                    <img src="/add-image.svg" alt="user" onClick={browseImage} />
                </ProfileImageAcronym>
            }
            <ProfileInfoText showRightBorder>{ user && user.name}</ProfileInfoText>
            <ProfileInfoText showRightBorder>{ user && user.group && user.group.academy.title }</ProfileInfoText>
            <ProfileInfoText>{ user && user.group && user.group.title }</ProfileInfoText>
        </ProfileInfoWrapper>
    )
}

function mapStateToProps(state){
    return {
        user: state.userReducer.user
    }
}

const mapDispatchToProps = {
    userUpload: userActions.upload
}

export default connect(mapStateToProps, mapDispatchToProps)(ProfileInfo)
