import * as types from '../actions/actionTypes'

export default function surveyReducer(state = {}, action){
    switch (action.type) {
        case types.SURVEY_DETAILS:
           return {...state, survey: {...state.survey, ...action.survey}}
        case types.SURVEY_ANSWERS:
            return state;
        default:
            return state;
    }
}
