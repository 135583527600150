import React from 'react'
import { Redirect } from "@reach/router"

const PublicRoute = ({ component: Component, ...rest }) => {
    if(localStorage.getItem('userAT')){
        return <Redirect to="/" noThrow/>
    }
    return <Component {...rest} /> 
}

export default PublicRoute