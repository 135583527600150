import React, { useEffect, useState } from 'react'
import styled from 'styled-components'
import $ from "jquery"

import { connect } from 'react-redux'
import { navigate } from '@reach/router'

const LeaderBoardWrapper = styled.div`
    width: 32%;
    display: flex;
    flex-flow: column;

    p{
        font-family: Ubuntu;
        font-style: normal;
        font-weight: bold;
        font-size: 18px;
        line-height: 21px;
        color: #191919;
    }

    .LeaderBoardHeader{
        background: #005EFF;
        box-shadow: 0px 8px 30px rgba(0, 0, 0, 0.08);
        border-radius: 10px;
        display: flex;
        justify-content: space-between;
        align-items: center;
        padding: 5px 20px;
        height: 30px;
        margin-bottom: 15px;

        span {
            font-family: Ubuntu;
            font-style: normal;
            font-weight: bold;
            font-size: 12px;
            line-height: 14px;
            color: #FDFDFD;
        }
    }

    .hidden {
        display: none !important;
    }

    .LeaderBoardStudent{
        background: #FDFDFD;
        box-shadow: 0px 8px 30px rgba(0, 0, 0, 0.08);
        border-radius: 10px;
        display: flex;
        justify-content: space-between;
        align-items: center;
        padding: 5px;
        height: 56px;
        margin-bottom: 15px;
        cursor: pointer;

        .LeaderBoardStudentName {
            font-family: Ubuntu;
            font-style: normal;
            font-weight: bold;
            font-size: 14px;
            line-height: 16px;
            color: #232426;
            padding-left: 10px;
        }

        .LeaderBoardStudentPoints{
            font-family: Ubuntu;
            font-style: normal;
            font-weight: bold;
            font-size: 16px;
            line-height: 18px;
            color: #0A08E5;
            padding-right: 15px;

            img {
                margin-left: 30px;
            }
        }

        &:hover {
            border: 2px solid #005EFF;
        }
    }

    .LeaderBoardButton {
        background: #191919;
        box-shadow: 0px 8px 30px rgba(0, 0, 0, 0.08);
        border-radius: 10px;
        padding: 5px;
        height: 56px;
        margin-bottom: 15px;
        display: flex;
        justify-content: center;
        align-items: center;
        cursor: pointer;

        span{
            color: #FDFDFD;
        }
    }

    @media only screen and (max-width: 600px) {
        width: 100%;
        margin: auto;
    }
`

function Leaderboard({header, type, points}) {
    const [pointsArray, setPointsArray] = useState([])
    const [showMore, setShowMore] = useState(false)

    useEffect(() => {
        if(points){
            setPointsArray(() => {
                switch (type) {
                    case "total":
                        let totalArr =  Object.values(points.data).map((data) => {
                            return { id: data.id, name: data.name, points: data.totalChallenges + data.totalPresence }
                        })
                        return totalArr.sort((a, b) => (a.points > b.points) ? -1 : 1)
                    case "challenge":
                        let challengeArr = Object.values(points.data).map((data) => {
                            return { id: data.id, name: data.name, points: data.totalChallenges }
                        })
                        return challengeArr.sort((a, b) => (a.points > b.points) ? -1 : 1)
                    case "attendance":
                        let attendanceArr = Object.values(points.data).map((data) => {
                            return { id: data.id, name: data.name, points: data.totalPresence }
                        })
                        return attendanceArr.sort((a, b) => (a.points > b.points) ? -1 : 1)
                    default:
                        let arr = Object.values(points.data).map((data) => {
                            return { id: data.id, name: data.name, points: data.totalChallenges + data.totalPresence }
                        })
                        return arr.sort((a, b) => (a.points > b.points) ? -1 : 1)
                }
            })
        }
    }, [points, type])

    useEffect(() => {
        if(showMore){
            $('.' + type).filter('.hidden').addClass('toHide').removeClass('hidden')
            $('#' + type + 'btn').text('Hide 10 results')
        }else{
            $('.' + type).filter('.toHide').addClass('hidden').removeClass('toHide')
            $('#' + type + 'btn').text('View the Entire Leaderboard')
        }
    }, [showMore])

    const handleClickOnStudent = (id) => {
        return navigate('/points/' + id)
    }

    const handleShowMore = (e) => {
        setShowMore(!showMore)
    }

    const handleMouseOver = (e) => {
        $(e.target).find('img').attr('src', '/vert-more-active.svg')
    }
    const handleMouseLeave = (e) => {
        $(e.target).find('img').attr('src', '/vert-more.svg')
    }


    return (
        <LeaderBoardWrapper>
            <p>{header}</p>
            <div className="LeaderBoardHeader">
                <span>Student Name</span>
                <span>Points</span>
            </div>
            {
                pointsArray ?
                Object.values(pointsArray).map((data, index) => {
                    return <div className={index > 10 ? type + ' hidden LeaderBoardStudent' : type + ' LeaderBoardStudent'} onMouseEnter={handleMouseOver} onMouseLeave={handleMouseLeave} onClick={() => handleClickOnStudent(data.id)}>
                        <span className="LeaderBoardStudentName">{data.name}</span>
                        <span className="LeaderBoardStudentPoints">
                            {data.points}
                            <img src="/vert-more.svg" />
                        </span>
                    </div>
                })
                :
                ''
            }
            <div className="LeaderBoardButton" onClick={handleShowMore}>
                <span id={type + 'btn'}>View the Entire Leaderboard</span>
            </div>
        </LeaderBoardWrapper>
    )
}

function mapStateToProps(state){
    return {
        points: state.pointReducer.totalPoints
    }
}

export default connect(mapStateToProps, null)(Leaderboard)
