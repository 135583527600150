import styled from 'styled-components'
import InputMask from 'react-input-mask'
import Row from 'react-bootstrap/Row'
import Col from 'react-bootstrap/Col'
import Image from 'react-bootstrap/Image'

export const StyledInputMask = styled(InputMask)`
    font-weight: bold;
    font-size: 12px;
    line-height: 14px;
    color: #65676C;
    border: 2px solid #EFEFF4;
    box-sizing: border-box;
    border-radius: 2px;
    height: 40px;
    width: 100%;
    padding-left: 10px;
`

export const UserDetailsWrapper = styled(Row)`
    background: #FFFFFF;
    padding: 40px;
    padding-left: 0px !important;
    font-weight: bold;
    font-size: 12px;
    line-height: 14px;
    color: #65676C;
    margin-bottom: 50px;
    border-radius: 20px;

    p {
        display: none;
        font-weight: bold;
        font-size: 12px;
        line-height: 14px;
        text-transform: uppercase;
        color: #232426;
    }

    input,select{
        border: 2px solid #EFEFF4;
        box-sizing: border-box;
        border-radius: 2px;
        height: 40px;
    }

    select{
        border: 2px solid #EFEFF4;
        box-sizing: border-box;
        border-radius: 2px;
    }

    button {
        height: 34px;
        background: ${props => props.isdisabled ? "#EFEFF4" : "#0A08E5"};
        font-family: Ubuntu;
        font-style: normal;
        font-weight: bold;
        font-size: 12px;
        line-height: 14px;
        color: ${props => props.isdisabled ? "#65676C" : "#FFFFFF"};
        outline: none;
        border: none;
        border-radius: 2px;
        padding: 10px 20px;
    }

    .col-sm-3, .col-sm-9, .col-sm-2{
        padding-right: 0px !important;
        padding-left: 0px !important;
    }

    form .col-sm-2{
        padding-right: 15px !important;
        padding-left: 5px !important;
    }

    @media (min-width: 320px) and (max-width: 991px) {
        padding: 10px;
        p {
            display: block;
        }
    }
`
export const ImageSection = styled(Col)`
    display: flex;
    justify-content: center;
    text-align: center;
    border-right: 1px solid rgba(101, 103, 108, 0.2);
    padding-right: 0px !important;
    padding-left: 0px !important;
    @media (max-width: 991px){
        border-right: none;
    }
    @media (min-width: 320px) and (max-width: 480px) {
        justify-content: flex-start;
        margin-left: 25px;
    }
`

export const StyledImage = styled.div`
    width: 120px;
    height: 120px;
    background-image: url(${props => props.imageSrc});
    background-position: center;
    background-size: cover;
    background-repeat: no-repeat;
    border-radius: 50%;
    margin: 0 auto;
    @media (min-width: 320px) and (max-width: 480px) {
        width: 80px;
        height: 80px;
        position: relative;
        top: 20px;
        left: 15px;
    }
`

export const UploadImageDiv = styled.div`
    margin-top: 15px;
    color: #005EFF;
    @media (min-width: 320px) and (max-width: 480px) {
        position: relative;
        top: 20px;
        left: 24px;
    }
`

export const FormSection = styled(Col)`
    @media (min-width: 320px) and (max-width: 480px) {
        margin-left: 0px;
        .mobileDesignName {
            display: inline-block;
            width: 60%;
            float: right;
            margin-top: -100px;
        }

        .mobileDesignEmail {
            display: inline-block;
            width: 60%;
            float: right;
            margin-top: -35px;
        }

        .mobileDesignLocation {
            display: inline-block;
        }

        .mobileDesignCountry{
            display: inline-block;
            width: 60%;
        }

        .mobileDesignCity{
            display: inline-block;
            width: 40%;
        }
    }
`
