import React from 'react'
import styled from 'styled-components'

const HeaderDetails = styled.div`
    display: flex;
    justify-content: space-between;
    align-items: center;

    img {
        margin-right: 10px;
    }
`

function MaterialsHeaderDetails({ icon, title}){
    return (
        <HeaderDetails>
            <img src={icon} alt="some icon"/>
            <span>{title}</span>
        </HeaderDetails>
    )
}

export default MaterialsHeaderDetails
