import React, { useState, useEffect } from 'react'
import styled from 'styled-components'

import { navigate } from "@reach/router"

const BackgroundModalWrapper = styled.div`
    position: fixed;
    left: 0;
    top: 0;
    z-index: 10000;
    background-color: rgb(229, 229, 229, 0.9);
    min-height: -webkit-fill-available;
    width: 100%;
    height: 100%;
    display: ${props => props.show ? 'block' :  'none'};
`

const ModalWrapper = styled.div`
    text-align: center;
    padding: 40px;
    position: absolute;
    width: 480px;
    height: 220px;
    left: 50%;
    margin-left: -240px;
    top: 286px;
    margin-top: -110px;
    background-color: #FFFFFF;
    border-radius: 8px;
    z-index:5000;

    p {
        font-weight: bold;
        font-size: 24px;
        line-height: 28px;
        color: #000000;
    }

    p.desc {
        font-family: Ubuntu;
        font-style: normal;
        font-weight: normal;
        font-size: 16px;
        line-height: 18px;
        text-align: center;
        color: #4D4D4D;
        flex: none;
        order: 1;
        flex-grow: 0;
        margin: 10px 0px;
    }

    @media (min-width: 320px) and (max-width: 480px) {
        padding: 20px;
        width: 320px;
        height: 260px;
        margin-left: -160px;
        margin-top: -130px;
    }
`

const ModalButton = styled.button`
    background: ${props => props.backgroundColor || "#005EFF;"};
    color: ${props => props.color || "white"};
    outline: none;
    border: none;
    cursor: pointer;
    font-weight: bold;
    font-size: 12px;
    line-height: 14px;
    width: 151px;
    height: 40px;
    margin: 10px;
`

export default function NotifySurveyModal({ title, desc, show, surveyId }) {
    const [showModal, setShowModal] = useState(show)

    const handleHomepageBtnClicked = () => {
        navigate('/survey/' + surveyId)
    }

    const handleCloseBtnClicked = () => {
        setShowModal(false)
    }

    useEffect(() => {
        setShowModal(show)
    }, [show]
    )

    return (
        <BackgroundModalWrapper show={showModal} >
            <ModalWrapper>
                <p>{title}</p>
                <p className="desc">{desc}</p>
                <ModalButton onClick={handleHomepageBtnClicked}>Take the survey</ModalButton>
                <ModalButton backgroundColor="#E1E2E7" color="#191919" onClick={handleCloseBtnClicked}>Remind me later</ModalButton>
            </ModalWrapper>
        </BackgroundModalWrapper>
    )
}
