import React from 'react'
import styled from 'styled-components';

const SurveyQuestionText = styled.p`
    font-family: Ubuntu;
    font-style: normal;
    font-weight: bold;
    font-size: 20px;
    line-height: 28px;
    color: #232426;
    text-align: left;
`

const SurveyQuestionDescription = styled.small`
        font-family: Ubuntu;
        font-style: normal;
        font-size: 14px;
        line-height: 20px;
        color: #232426;
        text-align: left;
        display: inline-block;
        margin-bottom: 5px;
`


const InstructorSelect = styled.select`
    border-top: none;
    border-left:none;
    border-right: none;
    border-bottom: 1px solid black;

    &:focus {
        outline: none;
    }
`

export default function SurveyInstructorSelect({question, description, id, changeAction, instructors, instructorType}) {
    return (
        <>
        <SurveyQuestionText>
            {question.split('instructor_name')[0]}
            {
                question.split('instructor_name').length > 1 ?
                    <InstructorSelect name={"instructor" + id} onChange={changeAction} className={instructorType} required>
                        <option value="">Please select</option>
                        {
                            instructors.map((instructor) => {
                                return <option value={instructor.id} key={instructor.id}>{instructor.name}</option>
                            })
                        }
                    </InstructorSelect>
                :
                ""
            }
            { question.split('instructor_name').length > 1 ? question.split('instructor_name')[1] : ""}
        </SurveyQuestionText>
        <SurveyQuestionDescription className="desc">
            {description}
        </SurveyQuestionDescription>
        </>
    )
}
