import React, { useState, useEffect } from 'react'
import styled from 'styled-components'
import { navigate } from '@reach/router';

import { Collapse, Radio } from 'antd';

import 'antd/dist/antd.css';

import MaterialsHeaderDetails from './MaterialsHeaderDetails';
import Unit from './Unit';
import ProgressBarComponent from '../Common/ProgressBarComponent';

import { connect } from 'react-redux'
import CategoryCompleted from '../../Common/CategoryCompleted/CategoryCompleted';


const { Panel } = Collapse;
const ContentWrapper = styled.div`
    width: 70%;
    @media only screen and (max-width: 910px) {
        width: 100%
    }
`
const Materials = styled.div`
    width: 100%;
    margin: auto;
    min-height: 200px;
    height: auto;
    border-radius: 50px 50px 10px 10px;
    background: #FFFFFF;
    margin-bottom: 300px;
    @media only screen and (max-width: 910px) {
        background: unset;
        border-radius: 0px;
    }
`

const MaterialsHeader = styled.div`
    height: 50px;
    background: #F9F9F9;
    border-radius: 50px 50px 0px 0px;
    display: flex;
    justify-content: flex-start;

    @media only screen and (max-width: 910px) {
        display: none;
    }
`
const MaterialsHeaderSM = styled.div`
    display: none;

    @media only screen and (max-width: 910px) {
        display: flex;
        justify-content: space-between;
        margin-bottom: 10px;
    }
`
const MaterialsHeaderTitle = styled.div`
    border-radius: 50px 0px 50px 0px;
    background: #FFFFFF;
    width: 50%;
    height: 100%;
    display: flex;
    justify-content: space-around;
    align-items: center;
`
const MaterialsHeaderProgressBar = styled.div`
    width: 40%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
`

const MobileModuleFilter = styled.div`
    background: #FFFFFF;
    box-shadow: 0px 8px 30px rgba(0, 0, 0, 0.08);
    border-radius: 10px;
    margin-bottom: 20px;
    display: none;

    @media only screen and (max-width: 910px) {
        display: block;
    }
`
const MobileModuleFilterHeader = styled.div`
    display: flex;
    justify-content: space-between;
    align-items: center;

    p {
        font-weight: bold;
        font-size: 16px;
        line-height: 18px;
        color: #191919;
        margin-bottom: 0px;
    }
`

const MobileModuleWrapper = styled.div`
    border-top: 2px solid #E1E2E7;

    .buttonsWrapper {
        display: flex;
        justify-content: space-between;
        margin-top: 20px;

        .resetBtn {
            border: 2px solid #C5C5C5;
            box-sizing: border-box;
            border-radius: 9px;
            background: transparent;
            display: flex;
            align-items: center;
            justify-content: space-between;

            img {
                margin-left: 8px;
                width: 10px;
                height: 10px;
            }
        }

        .resetBtn:focus, .applyBtn:focus {
            outline: none;
        }

        .applyBtn {
            border: 2px solid #005EFF;
            box-sizing: border-box;
            border-radius: 8px;
            background: transparent;
            display: flex;
            align-items: center;
            justify-content: space-between;

            img {
                margin-left: 8px;
                width: 10px;
                height: 10px;
            }
        }
    }

    .modulesWrapper {
        margin-top:20px;
        .module {
            display: flex;
            justify-content: space-between;
            margin-bottom: 5px;
            span {
                font-style: normal;
                font-weight: bold;
                font-size: 20px;
                line-height: 28px;
                color: #232426;
            }
        }
    }
`


export const MaterialsBody = styled.div``

function MaterialsContainer({ materials, slug }) {

    const [totalResources, setTotalResources] = useState(0)
    const [totalCompleted, setTotalCompleted] = useState(0)
    const [totalProgress, setTotalProgress] = useState(0)
    const [totalDuration, setTotalDuration] = useState(0)
    const [showRewardPopup, setshowRewardPopup] = useState(false)
    const [units, setUnits] = useState([]);
    const [unitsModules, setUnitsModules] = useState([]);
    const [activeCategory, setActiveCategory] = useState({});
    const [activeFilters, setActiveFilters] = useState([]);
    const [open, setOpen] = useState(["0"]);

    useEffect(() => {
        if (materials) {
            let totalR = 0
            let totalD = 0
            let totalCompletedArr = []
            materials.units.forEach(unit => {
                if(unit.categorySlug === slug){
                    totalD += unit.duration
                    totalR += unit.totalResources
                    totalCompletedArr = [...totalCompletedArr, ...unit.resourceStatus.filter((resource) => resource.completed === true)]
                }
            });
            setActiveCategory(() => {
                return materials.categories.categories.find((category) => category.slug === slug)
            })
            setTotalResources(totalR)
            setTotalDuration(totalD)
            setTotalCompleted(totalCompletedArr.length)
            setUnits(() => {
                return materials.units.filter((unit) => unit.categorySlug === slug)
            })
            setUnitsModules(() => {
                return materials.units.filter((unit) => unit.categorySlug === slug)
            })
        }
    }, [materials, slug])

    useEffect(() => {
        setTotalProgress((totalCompleted / totalResources).toFixed(2) * 100)
    }, [totalResources, totalCompleted])

    const categoryCompleted = () => {
        if (totalResources - totalCompleted === 1) {
            setshowRewardPopup(true)
        }
    }

    const handleClaimBtnClicked = () => {
        setshowRewardPopup(false)
        navigate('/')
    }

    const handleRadioModuleChanged = (e) => {
       setActiveFilters((activeFilters) => {
           return [...activeFilters, e.target.value]
       })
    }

    const handleApplyFilters = () => {
        let totalR = 0
        let totalD = 0
        let totalCompletedArr = []
        materials.units.forEach(unit => {
            if((unit.categorySlug === slug) && (activeFilters.includes(unit.id))){
                totalD += unit.duration
                totalR += unit.totalResources
                totalCompletedArr = [...totalCompletedArr, ...unit.resourceStatus.filter((resource) => resource.completed === true)]
            }
        });
        setUnits(() => {
            return materials.units.filter((unit) => (unit.categorySlug === slug) && (activeFilters.includes(unit.id)))
        })
        setTotalResources(totalR)
        setTotalDuration(totalD)
        setTotalCompleted(totalCompletedArr.length)
        setOpen([]);
    }

    const handleResetFilter = () => {
        let totalR = 0
        let totalD = 0
        let totalCompletedArr = []
        materials.units.forEach(unit => {
            if(unit.categorySlug === slug) {
                totalD += unit.duration
                totalR += unit.totalResources
                totalCompletedArr = [...totalCompletedArr, ...unit.resourceStatus.filter((resource) => resource.completed === true)]
            }
        });
        setTotalResources(totalR)
        setTotalDuration(totalD)
        setTotalCompleted(totalCompletedArr.length)
        setOpen([]);
        setActiveFilters([])
        setUnits(() => {
            return materials.units.filter((unit) => unit.categorySlug === slug)
        })
    }


    return (
        <ContentWrapper>
            <MobileModuleFilter>
                <Collapse accordion bordered={false} ghost={true} activeKey={open} onChange={() => setOpen(prev => open.includes('1') ? ['0'] : ['1'])}>
                    <Panel onChange={() => setOpen(prev => [1])} header={
                        <MobileModuleFilterHeader>
                            <p>Modules</p>
                            <img src="/filter.svg"  alt="filter"/>
                        </MobileModuleFilterHeader>
                    }
                    key="1"
                    showArrow={false}>
                        <MobileModuleWrapper>
                            <div className="buttonsWrapper">
                                <button disabled={activeFilters.length ? false : true} className="resetBtn" onClick={handleResetFilter}><span>Reset Filters</span> <img src="/close-gray.svg"  alt="x"/> </button>
                                <button disabled={activeFilters.length ? false : true} className="applyBtn" onClick={handleApplyFilters}><span>Apply Filters</span> <img src="/checked-blue.svg" alt="check"/></button>
                            </div>
                            <div className="modulesWrapper">
                                {
                                    unitsModules && unitsModules.map((unit, index) => <div className="module"><span>{index + 1 + '.' + unit.title}</span> <Radio name="module-radio" onChange={handleRadioModuleChanged} value={unit.id} checked={activeFilters.includes(unit.id)}></Radio></div>)
                                }
                            </div>
                        </MobileModuleWrapper>
                    </Panel>
                </Collapse>
            </MobileModuleFilter>

            <MaterialsHeaderSM>
                <MaterialsHeaderDetails icon="/resources.svg" title={totalResources + " Resources"} />
                <MaterialsHeaderDetails icon="/time.svg" title={totalDuration < 60 ? totalDuration + " Minutes" : ( Math.floor(totalDuration / 60) + " Hours " )} />
            </MaterialsHeaderSM>
            <Materials>
                <MaterialsHeader>
                    <MaterialsHeaderTitle>
                        <MaterialsHeaderDetails icon="/resources.svg" title={totalResources + " Resources"} />
                        <MaterialsHeaderDetails icon="/time.svg" title={totalDuration < 60 ? totalDuration + " Minutes" : ( Math.floor(totalDuration / 60) + " Hours " )} />
                    </MaterialsHeaderTitle>
                    <MaterialsHeaderProgressBar>
                        <ProgressBarComponent status={totalProgress || 0} title="Category" hideTitle={true} />
                    </MaterialsHeaderProgressBar>
                </MaterialsHeader>
                <MaterialsBody>
                    {
                        units && units.map(unit => <Unit unit={unit} subunits={unit.subunits} categoryCompleted={categoryCompleted} />)
                    }
                </MaterialsBody>
            </Materials>
            <CategoryCompleted show={showRewardPopup} activeCategory={activeCategory} handleClaimBtnClicked={handleClaimBtnClicked}/>
        </ContentWrapper>
    )
}


function mapStateToProps(state) {
    return {
        materials: state.materialReducer.materials
    }
}

export default connect(mapStateToProps, null)(MaterialsContainer)
