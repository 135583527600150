import React from 'react'
import styled from 'styled-components'
import { connect } from 'react-redux'

import * as userActions from "../../redux/actions/userActions"
import { navigate } from "@reach/router"

const BackgroundLogoutWrapper = styled.div`
    position: fixed;
    left: 0;
    top: 0;
    z-index: 10000;
    background-color: rgb(229, 229, 229, 0.9);
    min-height: -webkit-fill-available;
    width: 100%;
    height: 100%;
    display: ${props => props.show ? 'block' :  'none'};
`

const LogoutWrapper = styled.div`
    text-align: center;
    padding: 40px;
    position: absolute;
    width: 480px;
    height: 240px;
    left: 50%;
    margin-left: -240px;
    top: 286px;
    margin-top: -120px;
    background-color: #FFFFFF;
    border-radius: 8px;
    z-index:5000;

    p {
        font-weight: bold;
        font-size: 24px;
        line-height: 28px;
        color: #000000;
    }

    @media (min-width: 320px) and (max-width: 480px) {
        padding: 20px;
        width: 320px;
        height: 300px;
        margin-left: -160px;
        margin-top: -150px;
    }
`

const CancelButton = styled.button`
    background: #005EFF;
    border-radius: 100px;
    border: none;
    padding: 10px 40px;
    font-style: normal;
    font-weight: bold;
    font-size: 16px;
    line-height: 18px;
    color: #FFFFFF;
    width: 80%;
    height: 40px;
    outline: none;
`

const LogoutButton = styled.button`
    border: 2px solid #65676C;
    box-sizing: border-box;
    border-radius: 100px;
    padding: 10px 40px;
    width: 80%;
    height: 40px;
    font-style: normal;
    font-weight: bold;
    font-size: 16px;
    line-height: 18px;
    color: #65676C;
    margin-bottom: 20px;
`

function Logout({ show, handleCancelButtonClicked, logout }) {
    const hangleLogoutBtnClicked = () => {
        logout()
        .then(() => {
           navigate('/login')
        })
    }
    return (
        <BackgroundLogoutWrapper show={show}>
            <LogoutWrapper>
                <p>Are you sure you want to Log Out?</p>
                <LogoutButton backgroundColor="transparent" color="#65676C" onClick={hangleLogoutBtnClicked}>Logout</LogoutButton>
                <CancelButton onClick={handleCancelButtonClicked}>Cancel</CancelButton>
            </LogoutWrapper>
        </BackgroundLogoutWrapper>
    )
}

const mapDispatchToProps = {
    logout: userActions.userLogout
}

export default connect(null, mapDispatchToProps)(Logout)
