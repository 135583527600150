import React, { useState, useEffect } from 'react'
import { setOfferStatus } from '../../api/jobOfferApi';

import styled from 'styled-components'
import Col from 'react-bootstrap/Col'
import ScrollspyComponent from 'react-scrollspy'
import { Link } from 'react-scroll'
import { navigate } from "@reach/router"
import AcceptTalentMarket from './Components/AcceptTalentMarket'
import DeclineTalentMarket from './Components/DeclineTalentMarket'

const OfferContainer = styled(Col)`
    font-weight: bold;
    font-size: 18px;
    line-height: 21px;
    color: #FFFFFF;
    background: gray;
    opacity: 0.8;
    height: 100px;
    display: flex;
    flex-direction: row;
    justify-content: baseline;
    align-items: center;
    position: fixed;
    bottom: 0;
    p {
        color: #FFF;
        padding-left: 20px;
        width: 70%;
    }

`
const CancelButton = styled.button`
    border: none;
    background: #FFF;
    padding: 0 20px;
    font-style: normal;
    font-weight: bold;
    font-size: 12px;
    line-height: 18px;
    color: red;
    height: 40px;
    outline: none;
    margin-right: 10px;
`

const TalentMarketButton = styled.button`
    background: #005EFF;
    border: none;
    outline: none;
    padding: 0 20px;
    height: 40px;
    font-style: normal;
    font-weight: bold;
    font-size: 12px;
    line-height: 18px;
    color: #FFFFFF; 
   
`

export default function Offer({ offers }) {

    const [showAcceptTalentMarketPopup, setshowAcceptTalentMarketPopup] = useState(false);
    const [showDeclineTalentMarketPopup, setshowDeclineTalentMarketPopup] = useState(false);

    const handleCancelButtonClicked = () => {
        setshowDeclineTalentMarketPopup(false)
        setshowAcceptTalentMarketPopup(false)
    }

    const handleTalentMarketAcceptClicked = (e) => {
        e.preventDefault()
        if (offers) {
            const dataToUpdate = {
                offerId: offers.id,
                status: true
            }

            setOfferStatus(dataToUpdate)
                .then(() => {
                    setshowAcceptTalentMarketPopup(true)
                })
        }
    }
    const handleTalentMarketDeclineClicked = () => {
        setshowDeclineTalentMarketPopup(true)
    }
    return (
        <OfferContainer xs={12} sm={12} md={12}>
            <p>{offers ? offers.title : null}</p>
            <CancelButton onClick={handleTalentMarketDeclineClicked}>Decline offer</CancelButton>
            <TalentMarketButton backgroundColor="transparent" color="#65676C" onClick={handleTalentMarketAcceptClicked}>Accept offer</TalentMarketButton>
            <AcceptTalentMarket show={showAcceptTalentMarketPopup} handleCancelButtonClicked={handleCancelButtonClicked} />
            <DeclineTalentMarket offers={offers} show={showDeclineTalentMarketPopup} handleCancelButtonClicked={handleCancelButtonClicked} />
        </OfferContainer>
    )
}
