import React, { useState, useEffect } from 'react'
import { trackPromise } from 'react-promise-tracker'
import { connect } from 'react-redux'

import PageWrapper from '../Components/Wrappers/PageWrapper'
import Header from '../Components/Common/Header/Header'
import OfferPageContentWrapper from '../Components/Wrappers/OfferPageContentWrapper'
import * as jobOfferActions from '../redux/actions/jobOfferActions'

function Offer({ offers, getJobOffers }) {
    useEffect(() => {
        trackPromise(getJobOffers())
    }, [getJobOffers]
    )

    return (
        <PageWrapper>
            <Header />
            <OfferPageContentWrapper offers={offers} />
        </PageWrapper>
    )
}

function mapStateToProps(state) {
    return {
        offers: state.jobOfferReducer.offers,
        user: state.userReducer.user
    }
}

const mapDispatchToProps = {
    getJobOffers: jobOfferActions.getJobOffers
}

export default connect(mapStateToProps, mapDispatchToProps)(Offer)
