import React from 'react'
import { Link } from 'react-scroll'
import { connect } from 'react-redux'

import styled from 'styled-components'
import './ActiveLink.css'

const Wrapper = styled.div`
    width: 15%;
    @media screen and (max-width: 1240px) {
        width: 0%;
        display: none;
    }
`

const ScrollSpyMenu = styled.div`
    display: flex;
    align-items: center;
    position: -webkit-sticky;
    position: sticky;
    height: 100vh;
    top: 30px;
`

const LinksWrapper = styled.div`

`
const StyledLink = styled(Link)`
    display: block;
    font-family: Ubuntu;
    font-style: normal;
    font-weight: bold;
    font-size: 14px;
    line-height: 16px;
    margin-bottom: 6%;
    color: #000000;
    cursor: pointer;
`

const BulletPoint = styled.div`
    display: inline-block;
    width: 8px;
    height: 8px;
    border-radius: 100px;
    background-color: transparent;
    margin-left: 5px;
    cursor: auto;
`

function ScrollSpyWrapper({ user }) {
    return (
        <Wrapper>
            <ScrollSpyMenu>
                <LinksWrapper>
                    <StyledLink activeClass="activeLink" to="progressTracker" spy={true} smooth={true} duration={50}>
                        Progress Tracker
                        <BulletPoint />
                    </StyledLink>
                    <StyledLink activeClass="activeLink" to="achievements" spy={true} smooth={true} duration={50}>
                        Achievement
                        <BulletPoint />
                    </StyledLink>
                    {
                        user && user.group && user.group.status != 'PREPARATIONAL PROGRAMME' ?
                            <StyledLink activeClass="activeLink" to="leaderboards" spy={true} smooth={true} duration={50}>
                                Leaderboards
                                <BulletPoint />
                            </StyledLink>
                            :
                            ""
                    }
                </LinksWrapper>
            </ScrollSpyMenu>
        </Wrapper>
    )
}

function mapStateToProps(state) {
    return {
        user: state.userReducer.user
    }
}

export default connect(mapStateToProps, null)(ScrollSpyWrapper)
