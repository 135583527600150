import React from 'react'
import PageWrapper from '../Components/Wrappers/PageWrapper'
import Header from '../Components/Common/Header/Header'
import ProfilePageContentWrapper from '../Components/Wrappers/ProfilePageContentWrapper'
import Footer from '../Components/Common/Footer/Footer'

function Profile({ user, getUserDetails }) {
    return (
        <PageWrapper>
            <Header />
            <ProfilePageContentWrapper />
            <Footer/>
        </PageWrapper> 
    )
}

export default Profile
