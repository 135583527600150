import React, { useState, useEffect } from 'react'
import { SurveyContainer, SurveyContent } from './Components/SurveyComponents'
import SurveyHeader from '../SurveyComponents/Components/SurveyHeader'
import SurveyQuestion from '../SurveyComponents/Components/SurveyQuestion'
import Form from 'react-bootstrap/Form'
import styled from 'styled-components'
import $ from "jquery"

import { trackPromise } from 'react-promise-tracker'
import { connect } from 'react-redux'

import * as surveyActions from '../../redux/actions/surveyActions'
import { Modal } from '../Common/Modal/Modal'

const SurveyQWrapper = styled.div`
    width: 90%;
    margin: auto;
    margin-top: 30px;
    margin-bottom: 30px;

    p {
        font-family: Ubuntu;
        font-style: normal;
        font-weight: bold;
        font-size: 20px;
        line-height: 28px;
        color: #232426;
        text-align: left;
    }
`

const SurveySubmitBtn = styled.button`
        height: 34px;
        width: 200px;
        background: #0A08E5;
        font-family: Ubuntu;
        font-style: normal;
        font-weight: bold;
        font-size: 12px;
        line-height: 14px;
        color: #FFFFFF;
        outline: none;
        border: none;
        border-radius: 2px;
        padding: 10px 20px;
    `

function SurveyContentWrapper({ survey, getSurveyDetails, saveSurveyAnswers, surveyId }) {
    const [questionsArray, setQuestionsArray] = useState([])
    const [instructorsArray, setInstructorsArray] = useState([])
    const [dateFrom, setDateFrom] = useState("")
    const [dateTo, setDateTo] = useState("")
    const [showSurvey, setShowSurvey] = useState(null)
    const [showModal, setShowModal] = useState(false)
    const [showHomepageBtn, setShowHomepageBtn] = useState(true)
    const [showCloseBtn, setShowCloseBtn] = useState(false)
    const [modalTitle, setModalTitle] = useState("Thank you for answering the survey.")
    const [anonimousSurvey, setAnonimousSurvey] = useState("0")

    useEffect(() => {
        trackPromise(getSurveyDetails(surveyId))
            .then(() => {
                setShowSurvey(true)
            })
            .catch((err) => {
                setShowSurvey(false)
                setModalTitle(err.data.error.message)
                setShowCloseBtn(false)
                setShowHomepageBtn(true)
                setShowModal(true)
            })
    }, [getSurveyDetails]
    )

    const handleCloseBtn = () => {
        setShowModal(false)
    }

    useEffect(() => {
        setQuestionsArray(() => {
            if (survey && survey.survey && survey.survey.questions) {
                return survey.survey.questions.data
            } else {
                return []
            }
        })
        setInstructorsArray(() => {
            if (survey && survey.survey && survey.survey.instructors) {
                return survey.survey.instructors.data
            } else {
                return []
            }
        })

        setDateFrom(() => {
            if (survey && survey.survey && survey.survey.survey) {
                return survey.survey.survey.week_start_date
            }
            else {
                return ""
            }
        })

        setDateTo(() => {
            if (survey && survey.survey && survey.survey.survey) {
                return survey.survey.survey.week_end_date
            }
            else {
                return ""
            }
        })
    }, [survey])

    const [ratings, setRatings] = useState({})
    const [inputs, setInputs] = useState({})
    const [questionStatus, setQuestionStatus] = useState({})

    const handleRadioChange = (e) => {
        setAnonimousSurvey(e.target.value)
    }
    const handleRatingChange = (newRating, name) => {
        setRatings(ratings => ({ ...ratings, [name]: newRating }))
    }

    const handleQuestionStatusChange = (name, val) => {
        setQuestionStatus(questionStatus => ({ ...questionStatus, [name]: val }))
    }

    const handleInputChange = (e) => {
        e.persist()
        setInputs(inputs => ({ ...inputs, [e.target.name]: e.target.value }))
    }

    const handleFormSubmit = (e) => {
        e.preventDefault()
        const answers = { ...ratings, ...inputs }
        let answer = {}
        let listOfAnswers = []
        let dataToSubmit = { surveyId }
        // let instructorIds = [];
        //If the same instructor was both on class and on workshop, the id isn't added and validation below fails.
        //That's why I will count instructors here and compare the number of star ratings to the number of instructors
        // let instructorsCount = 0;

        Object.keys(answers).forEach((key) => {
            if (key.includes('question')) {
                let id = key.substring(8, key.length)
                answer.questionId = id
                answer.instructorId = Number(answers['instructor' + id]) || null

                //some answers are only ratings, some are only texts and some are both
                //try to find either rating or comment in any of the arrays that form the answers array
                answer.rating = ratings[key] || null;
                answer.comment = inputs[key] || null;

                if (questionStatus['instructor' + id] !== false) {
                    // if (answer.instructorId) {
                    // if (instructorIds.indexOf(answer.instructorId) === -1) {
                    //     instructorIds.push(answer.instructorId)
                    // }
                    // if (answer.rating) {
                    //     instructorsCount++;
                    // }
                    // }
                    listOfAnswers.push(answer)
                }
                answer = {}
            }
        })

        dataToSubmit.answers = listOfAnswers
        dataToSubmit.anonimous = anonimousSurvey === "1" ? true : false

        let validateRatings = dataToSubmit.answers.filter((answer) => {
            return answer.rating
        })
        // console.log(validateRatings, 'vr')

        //Earlier we had ANSWER questions and RATING questions.
        //Now each questions has type NULL since each questions is a rating combined with a comment field
        let numberOfRatingQuestions = 0;
        questionsArray.map(question => {
            if (question.type !== 'ANSWER') {
                numberOfRatingQuestions++;
            }
        })
        // console.log(questionsArray, numberOfRatingQuestions);

        if (validateRatings.length !== numberOfRatingQuestions) {
            setModalTitle("All rating answers are required! Please choose 1-5 stars.")
            setShowCloseBtn(true)
            setShowHomepageBtn(false)
            setShowModal(true)
            return false
        }

        //if any questions is answered with grade 3 or lower, make the comment mandatory
        let lowRatingsWithoutComment = 0;
        dataToSubmit.answers.filter((answer) => {
            if (answer.rating <= 3 && answer.comment == null) {
                lowRatingsWithoutComment++;
            }
        });
        if (lowRatingsWithoutComment > 0) {
            setModalTitle("All ratings lower than 3 must be accompanied with a comment!");
            setShowCloseBtn(true)
            setShowHomepageBtn(false)
            setShowModal(true)
            return false
        }

        // if(listOfAnswers.length !== questionsArray.length){
        //     setModalTitle("All answers are required!")
        //     setShowCloseBtn(true)
        //     setShowHomepageBtn(false)
        //     setShowModal(true)
        //     return false
        // }

        trackPromise(saveSurveyAnswers(dataToSubmit))
            .then(() => {
                setModalTitle("Thank you for answering the survey.")
                setShowCloseBtn(false)
                setShowHomepageBtn(true)
                setShowModal(true)
            })
            .catch((err) => {
                setShowCloseBtn(false)
                setShowHomepageBtn(true)
                setModalTitle("Something went wrong. Please try again.")
                setShowModal(true)
            })
    }

    return (
        <SurveyContainer>
            <Modal show={showModal} title={modalTitle} showHomepageBtn={showHomepageBtn} showCloseBtn={showCloseBtn} handleCloseBtn={handleCloseBtn} />
            {showSurvey ?
                <SurveyContent>
                    <SurveyHeader dateFrom={dateFrom} dateTo={dateTo} />
                    <Form onSubmit={handleFormSubmit}>
                        <SurveyQWrapper>
                            <p>Questions</p>
                            <hr />
                            {
                                questionsArray.map((question) => {
                                    return <SurveyQuestion id={question.id} name={"question" + question.id} comment={inputs["question" + question.id] || null} rating={ratings["question" + question.id] || 0} ratingChangeAction={handleRatingChange} inputChangeAction={handleInputChange} statusChange={handleQuestionStatusChange} key={question.id} type={question.type} stars={question.stars} question={question.question} description={question.description} instructors={instructorsArray} instructorType={question.instructorType} />
                                })
                            }
                        </SurveyQWrapper>
                        {
                            survey.survey.survey.survey_type !== 'NPS' ?
                                (<SurveyQWrapper style={{ display: 'none' }}>
                                    <p>Anonymity</p>
                                    <hr />
                                    <p>Submit this form as an anonymous user?
                                        <input type="radio" name="anonimous" value="1" checked={anonimousSurvey === "1" ? 'checked' : ''} onChange={handleRadioChange} /> YES
                                        <input type="radio" name="anonimous" value="0" checked={anonimousSurvey === "0" ? 'checked' : ''} onChange={handleRadioChange} /> NO
                                    </p>
                                </SurveyQWrapper>)
                                : ''
                        }
                        <SurveyQWrapper>
                            <SurveySubmitBtn>Submit</SurveySubmitBtn>
                        </SurveyQWrapper>
                    </Form>
                </SurveyContent>
                :
                <SurveyContent></SurveyContent>
            }
        </SurveyContainer>
    )
}

function mapStateToProps(state) {
    return {
        survey: state.surveyReducer
    }
}

const mapDispatchToProps = {
    getSurveyDetails: surveyActions.getSurveyDetails,
    saveSurveyAnswers: surveyActions.saveSurveyAnswers
}

export default connect(mapStateToProps, mapDispatchToProps)(SurveyContentWrapper)
