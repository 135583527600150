import * as types from './actionTypes'
import * as countryApi from "../../api/countryApi"
import { logout } from './userActions';
import { navigate } from '@reach/router';


export function allCountries(countries) {
    return { type: types.ALL_COUNTRIES, countries }
}

export function getAllCountries() {
    return function (dispatch) {
        return countryApi.allCountries()
            .then((result) => {
                dispatch(allCountries(result.countries))
            }).catch((err) => {
                if(err.status === 401 && err.statusText === "Unauthorized"){
                    localStorage.removeItem('userAT')
                    dispatch(logout())
                    navigate('/login')
                }    
                throw err            
            });
    }
}
