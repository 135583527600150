import styled from 'styled-components';

const PageWrapper = styled.div`
    position: relative;
    background: #F9F9F9;
    min-width: 100%;
    max-width: 100%;
    min-height: 100vh;
`;

export default PageWrapper
