import React from 'react'
import ScrollToTop from "react-scroll-to-top"

import PageWrapper from '../Components/Wrappers/PageWrapper'
import Header from '../Components/Common/Header/Header'
import Footer from '../Components/Common/Footer/Footer'
import PointsContentWrapper from '../Components/Wrappers/PointsContentWrapper'

function Points({ userId }) {
    return (
        <PageWrapper>
            <ScrollToTop smooth color="#005EFF" style={{backgroundColor: '#F5F5F5', right: 10, boxShadow: '0px 8px 30px rgb(0 0 0 / 8%)'}} />
            <Header />
            <PointsContentWrapper userId={userId} />
            <Footer />
        </PageWrapper>
    )
}

export default Points
