import React from 'react'
import styled from 'styled-components'

import { navigate } from "@reach/router"
import { setOfferStatus } from '../../../redux/actions/jobOfferActions'

const BackgroundTalentMarketWrapper = styled.div`
    position: fixed;
    left: 0;
    top: 0;
    z-index: 10000;
    background-color: rgb(229, 229, 229, 0.9);
    min-height: -webkit-fill-available;
    width: 100%;
    height: 100%;
    display: ${props => props.show ? 'block' : 'none'};
`

const TalentMarketWrapper = styled.div`
    text-align: center;
    padding: 40px;
    position: absolute;
    width: 480px;
    left: 50%;
    margin-left: -240px;
    top: 286px;
    margin-top: -120px;
    background-color: #FFFFFF;
    border: 1px solid #005EFF; 
    border-radius: 10px;
    z-index:5000;
    color: black;


    p {
        margin: auto;
        width: 70%;
        font-weight: bold;
        font-size: 24px;
        line-height: 22px;
        padding-bottom: 10px;
        color: black!important;

    }
    span {
        display: block;
        padding-bottom: 30px;
        font-size: 12px;
    }

    @media (min-width: 320px) and (max-width: 480px) {
        padding: 20px;
        width: 320px;
        height: 300px;
        margin-left: -160px;
        margin-top: -150px;
    }
`

const BackButton = styled.button`
    background: #005EFF;
    border: none;
    outline: none;
    padding: 0 20px;
    height: 40px;
    font-style: normal;
    font-weight: bold;
    font-size: 12px;
    line-height: 18px;
    color: #FFFFFF;
    margin: auto;
`

function AcceptTalentMarket({ show }) {
    const handleBackBtnClicked = () => {
        window.location.href = "/";
    }
    return (
        <BackgroundTalentMarketWrapper show={show}>
            <TalentMarketWrapper>
                <p>You have successfully enrolled in our hiring program!</p>
                <span>We wish you a lot of luck in the enrollement process.</span>
                <BackButton onClick={handleBackBtnClicked}>Back to homepage</BackButton>
            </TalentMarketWrapper>
        </BackgroundTalentMarketWrapper>
    )
}

export default AcceptTalentMarket;
