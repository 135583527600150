import React, { useEffect, useState } from "react";
import styled, { keyframes } from "styled-components";
import ReactHtmlParser from "react-html-parser";
import { fadeInUp, fadeInLeft } from "react-animations";
import ShowMoreText from "react-show-more-text";

import ProfileInfo from "../Homepage/ProfileInfo";
import { ProgressTrackerWrapperSmaller } from "../Homepage/ProgressTracker";
import ProgressBarComponent from "../Common/ProgressBarComponent";

import { connect } from "react-redux";
import StudentInfo from "./StudentInfo";
import HomeworkPopUp from "./HomeworkPopUp";

const fadeInAnimation = keyframes`${fadeInUp}`;
const fadeInLeftAnimation = keyframes`${fadeInLeft}`;

const Wrapper = styled.div`
  width: 80%;
`;
const ProgressWrapper = styled.div`
  animation: 1s ${fadeInLeftAnimation};
  margin-top: 8%;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  width: 100%;
  @media (max-width: 991px) {
    width: 100%;
    flex-direction: column;
    margin-top: 50px;
  }
`;

const PointsTitle = styled.p`
  font-family: Ubuntu;
  font-style: normal;
  font-weight: bold;
  font-size: 24px;
  line-height: 28px;
  color: #191919;
  margin-top: 6%;
`;

const ChallengePointsWrapper = styled.div`
  animation: 1s ${fadeInAnimation};
`;
const Challenge = styled.div`
  animation: 1s ${fadeInAnimation};
  display: flex;
  justify-content: space-between;
  padding: 15px;
  background: #ffffff;
  box-shadow: 0px 4px 16px rgba(0, 0, 0, 0.06);
  border-radius: 10px;
  margin-top: 2%;
  margin-bottom: 2%;
  min-height: 100px;
  transition: height 0.2s ease;
  width: 100%;
  margin-right: 24px;
`;
const ChallengeHeader = styled.p`
  font-style: normal;
  font-weight: bold;
  font-size: 12px;
  line-height: 14px;
  color: #4d4d4d;
`;
const ChallengeTitle = styled.div`
  display: flex;
  flex-direction: column;
  width: 25%;
  align-items: flex-start;

  p.title {
    font-style: normal;
    font-weight: bold;
    font-size: 18px;
    line-height: 21px;
    color: #191919;
  }

  @media only screen and (max-width: 600px) {
    p.title {
      font-size: 12px;
      line-height: 12px;
    }
  }
`;

const ChallengeDescription = styled.div`
  display: flex;
  flex-direction: column;
  width: 53%;
  align-items: flex-start;

  .desc {
    font-style: normal;
    font-weight: normal;
    font-size: 14px;
    line-height: 16px;
    color: #4d4d4d;
  }

  @media only screen and (max-width: 600px) {
    // width: 24%;
    .desc {
      padding: 0px 5px;
      font-size: 11px;
      line-height: 12px;
    }
  }
`;

const ChallengePoints = styled.div`
  display: flex;
  flex-direction: column;
  width: 15%;
  align-items: flex-end;

  span.points {
    font-style: normal;
    font-weight: bold;
    font-size: 24px;
    line-height: 28px;
    color: #005eff;
  }

  p.project-points {
    display: flex;
    align-items: center;
  }

  span.total {
    font-style: normal;
    font-weight: bold;
    font-size: 24px;
    line-height: 28px;
    color: #4d4d4d;
  }

  span.extra {
    padding: 2px 8px;
    border-radius: 12px;
    background-color: #e6e7eb;
    font-size: 12px;
    align-self: flex-start;
    margin-left: 4px;
    font-weight: 600;
  }

  @media only screen and (max-width: 600px) {
    width: 20%;

    p.project-points {
      flex-direction: column;
      align-items: flex-end;
    }
    span.extra {
      align-self: flex-end;
    }
    span {
      font-size: 20px;
      line-height: 22px;
    }
  }
`;

const AttendanceWrapper = styled.div`
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  animation: 1s ${fadeInAnimation};
  overflow: hidden;
`;

const Attendance = styled.div`
  animation: 1s ${fadeInAnimation};
  float: left;
  width: 23%;
  box-sizing: border-box;
  margin-bottom: 2%;
  background: #ffffff;
  box-shadow: 0px 4px 16px rgba(0, 0, 0, 0.06);
  border-radius: 10px;
  border-left: 8px solid
    ${(props) => (props.hasAttended ? "#005eff" : "#E1E2E7")};
  padding: ${(props) => (props.show ? "15px;" : "0px")};
  text-align: left;
  height: ${(props) => (props.show ? "150px" : "0px")};

  p.header {
    font-family: "Ubuntu";
    font-style: normal;
    font-weight: bold;
    font-size: 18px;
    line-height: 21px;
    color: #191919;
    overflow: hidden;
    text-overflow: ellipsis;
    display: -webkit-box;
    -webkit-line-clamp: 1;
    /* number of lines to show */
    line-clamp: 1;
    -webkit-box-orient: vertical;
  }

  p.header:hover {
    display: block;
  }

  p.date {
    font-style: normal;
    font-weight: 700;
    font-size: 12px;
    line-height: 14px;
    color: #c5c5c5;
  }

  p.status {
    font-family: "Ubuntu";
    font-style: normal;
    font-weight: 700;
    font-size: 12px;
    line-height: 14px;
    color: #191919;
    display: inline-block;
    text-transform: uppercase;
    margin-top: 30px;
    margin-bottom: 15px;
  }

  span.attendance-img {
    width: 32px;
    height: 32px;
    border-radius: 50%;
    background-color: #efeff4;
    display: inline-flex;
    justify-content: center;
    align-items: center;
    margin-left: 8px;
  }

  @media only screen and (max-width: 990px) {
    width: 48%;
    p.header {
      font-size: 14px;
      line-height: 14px;
    }
  }

  @media only screen and (max-width: 600px) {
    width: 48%;
    p.header {
      font-size: 14px;
      line-height: 14px;
    }
  }
`;

const ShowMoreWrapper = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  height: 25px;
  margin-bottom: 20px;

  p.showMore {
    font-style: normal;
    font-weight: bold;
    font-size: 18px;
    line-height: 21px;
    color: #007bff;
    cursor: pointer;
    margin: 0px;
  }
`;
const StyledAssignmentBtn = styled.button`
  animation: 1s ${fadeInAnimation};
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  background-color: #ffffff;
  box-shadow: 0px 4px 16px rgba(0, 0, 0, 0.06);
  border: none;
  border-radius: 10px;
  margin-top: 2%;
  margin-bottom: 2%;
  min-height: 100px;
  transition: height 0.2s ease;
  font-size: 11px;
  font-family: "Ubuntu";
  font-style: normal;
  font-weight: 400;
  line-height: 16px;
  padding: ${(props) => !props.uploadedLink && "15px 0 0 0"};
  cursor: ${(props) =>
    !props.uploadedLink ? "default" : "pointer"} !important;

  color: ${(props) => (props.uploadedLink ? "#191919" : "#C5C5C5")};
  span {
    margin-bottom: 8px;
  }
  &:active,
  &:focus,
  &:hover {
    outline: none;
    box-shadow: ${(props) =>
    props.uploadedLink
      ? "0px 8px 16px rgba(0, 0, 0, 0.16)"
      : "0px 7px 6px rgba(0, 0, 0, 0.16)"};
    color: ${(props) => props.uploadedLink && "#005EFF"};
  }

  @media only screen and (max-width: 600px) {
    min-height: auto;
    width: 100%;
    margin-bottom: 4%;
    padding-top: 3px;
    padding-bottom: 3px;
  }
`;
const StyledCardWrap = styled.div`
  display: flex;

  @media only screen and (max-width: 600px) {
    display: block;
  }
`;
const StyledEdit = styled.p`
  color: #191919;
  display: flex;
  justify-content: center;
  align-items: center;
  padding-top: 10px;
  padding-bottom: 10px;
  border-top: 1px solid #e6e7eb;
  width: 80%;
  margin: 0 auto;
  cursor: pointer !important;

  span {
    margin-bottom: 0;
    margin-right: 8px;
  }
  img,
  span {
    align-self: center;
  }
`;

function PointsWrapper({ user, studentPoints }) {
  const [shownChallenges, setShownChallenges] = useState(5);
  const [shownProjects, setShownProjects] = useState(3);
  const [shownAttendance, setShownAttendance] = useState(12);
  const [shownHomeworkPopUp, setShownHomeworkPopUp] = useState(false);

  const [activitiesArray, setActivitiesArray] = useState([]);
  const [attendanceArr, setAttendance] = useState([]);

  const [currentActivityId, setCurrentActivityId] = useState(null);
  const [hasHomeworkLink, setHasHomeworkLink] = useState(false);

  // const handleShowMoreChallenges = () => {
  //   setShownChallenges(shownChallenges + 5);
  // };

  // const handleShowLessChallenges = () => {
  //   setShownChallenges(5);
  // };

  // const handleShowMoreAttendance = () => {
  //   setShownAttendance(shownAttendance + 8);
  // };

  // const handleShowLessAttendance = () => {
  //   setShownAttendance(12);
  // };
  const handleCloseHomeworkPopUp = () => {
    setShownHomeworkPopUp(false);
  };

  const handleSubmitedLink = (solution) => {
    if (solution.submission_url !== null) {
      activitiesArray.map((eachSolution) => {
        if (eachSolution.id === solution.id) {
          setHasHomeworkLink(true);
        }
      });
    } else {
      setHasHomeworkLink(false);
    }
  };


  useEffect(() => {
    if (studentPoints && studentPoints.data) {
      setActivitiesArray(studentPoints?.data?.activities);

      let totalProjects = studentPoints?.data?.activities.filter(activity => activity.type == 'Project').length;
      let totalChallenges = studentPoints?.data?.activities.filter(activity => activity.type == 'Challenge').length;
      if (totalProjects < shownProjects) {
        setShownProjects(totalProjects)
      }
      if (totalChallenges < shownChallenges) {
        setShownChallenges(totalChallenges)
      }
      setAttendance(() => {
        if (studentPoints.data.presence.length > shownAttendance) {
          return studentPoints.data.presence.slice(0, shownAttendance);
        } else {
          let tmpArr = studentPoints.data.presence;
          let remainder = studentPoints.data.presence.length % 4;
          if (remainder !== 0) {
            for (let i = 0; i < 4 - remainder; i++) {
              tmpArr = [...tmpArr, { title: "", date: "", points: 0 }];
            }
            return tmpArr;
          }
          return studentPoints.data.presence;
        }
      });
    }
  }, [studentPoints, user, shownAttendance]);

  useEffect(() => {
    console.log('changed', shownProjects)
  }, [shownProjects]);
  return (
    <Wrapper>
      {studentPoints && studentPoints.data && user ? (
        user.id === studentPoints.data.id ? (
          <ProfileInfo />
        ) : (
          <StudentInfo user={studentPoints.data} />
        )
      ) : (
        ""
      )}

      <ProgressWrapper>
        <ProgressTrackerWrapperSmaller>
          <img src="/total_points.svg" alt="Academy Progress" />
          <ProgressBarComponent
            title="Total Points"
            status={
              studentPoints && studentPoints.data
                ? Math.floor(studentPoints.data.totalStatus)
                : 0
            }
          />
        </ProgressTrackerWrapperSmaller>
        <ProgressTrackerWrapperSmaller>
          <img src="/challenges_points.svg" alt="Academy Progress" />
          <ProgressBarComponent
            title="Challenge Points"
            status={
              studentPoints && studentPoints.data
                ? Math.floor(
                  (studentPoints.data.studentTotalChallenges /
                    studentPoints.data.totalChallenges) *
                  100
                )
                : 0
            }
            points={
              studentPoints && studentPoints.data
                ? studentPoints.data.studentTotalChallenges
                : 0
            }
            totalPoints={
              studentPoints && studentPoints.data
                ? studentPoints.data.totalChallenges
                : 0
            }
          />
        </ProgressTrackerWrapperSmaller>
        <ProgressTrackerWrapperSmaller>
          <img src="/attendance_points.svg" alt="Academy Progress" />
          <ProgressBarComponent
            title="Attendance Points"
            status={
              studentPoints && studentPoints.data
                ? Math.floor(
                  (studentPoints.data.studentTotalPresence /
                    studentPoints.data.totalPresence) *
                  100
                )
                : 0
            }
            points={
              studentPoints && studentPoints.data
                ? studentPoints.data.studentTotalPresence
                : 0
            }
            totalPoints={
              studentPoints && studentPoints.data
                ? studentPoints.data.totalPresence
                : 0
            }
          />
        </ProgressTrackerWrapperSmaller>
      </ProgressWrapper>
      <PointsTitle>Project Points</PointsTitle>
      <ChallengePointsWrapper>
        {activitiesArray && activitiesArray?.filter(activity => activity.type == 'Project').map((activity, index) => {
          if (index < shownProjects)
            return (
              <StyledCardWrap>
                <Challenge>
                  <ChallengeTitle>
                    <ChallengeHeader>
                      {activity.deadline === null ? 'Deadline /' : `Deadline ${activity.deadline}`}
                    </ChallengeHeader>
                    <p className="title">{activity.title}</p>
                  </ChallengeTitle>
                  <ChallengeDescription>
                    <ChallengeHeader>Comment</ChallengeHeader>
                    <ShowMoreText
                      lines={2}
                      more="Read more"
                      less="Read less"
                      className="desc"
                    >
                      {ReactHtmlParser(activity.comment)}
                    </ShowMoreText>
                  </ChallengeDescription>
                  <ChallengePoints>
                    <ChallengeHeader>Points</ChallengeHeader>
                    <p className="project-points">
                      <span className="points">
                        {isNaN(parseFloat(activity.points))
                          ? 0
                          : parseFloat(activity.points)}
                      </span>
                      {activity.extraPoints > 0 && (
                        <span className="extra">+{activity.extraPoints}</span>
                      )}
                      <span className="total">/{activity.maxPoints}</span>
                    </p>
                  </ChallengePoints>
                </Challenge>
                {user?.id === studentPoints?.data?.id && (
                  <StyledAssignmentBtn
                    uploadedLink={activity.submission_url === null}
                    onClick={() => {
                      if (activity.submission_url === null) {
                        setShownHomeworkPopUp(true);
                        setCurrentActivityId(activity.id);
                        handleSubmitedLink(activity);
                        // setIsChallenge(false)
                      }
                    }}
                  >
                    <span>
                      <img
                        src={
                          activity.submission_url === null
                            ? "/hyperlink.svg"
                            : "/hyperlinkGrey.svg"
                        }
                      />
                    </span>

                    <span>
                      {activity.submission_url === null
                        ? "Link your Solution"
                        : "Successfully Uploaded"}
                    </span>

                    {activity.submission_url !== null && (
                      <StyledEdit
                        onClick={() => {
                          setShownHomeworkPopUp(true);
                          setCurrentActivityId(activity.id);
                          handleSubmitedLink(activity);
                        }}
                      >
                        <span>Edit</span>
                        <img src="/editIcon.svg" />
                      </StyledEdit>
                    )}
                  </StyledAssignmentBtn>
                )}
              </StyledCardWrap>
            )
        })}
      </ChallengePointsWrapper>
      {shownProjects > activitiesArray?.filter(activity => activity.type == 'Project').length ? (
        <ShowMoreWrapper>
          <p className="showMore" onClick={() => setShownProjects(3)}>
            Show Less
          </p>
        </ShowMoreWrapper>
      ) : (shownProjects < activitiesArray?.filter(activity => activity.type == 'Project').length) ? (
        <ShowMoreWrapper>
          <p className="showMore" onClick={() => setShownProjects(shownProjects + 3)}>
            Show More
          </p>
        </ShowMoreWrapper>
      ) : ''}

      <PointsTitle>Challenge Points</PointsTitle>
      <ChallengePointsWrapper>
        {activitiesArray &&
          activitiesArray?.filter(activity => activity.type == 'Challenge').map((activity, index) => {
            if (index < shownChallenges)
              return (
                <StyledCardWrap>
                  <Challenge>
                    <ChallengeTitle>
                      <ChallengeHeader>

                        {activity.deadline === null ? 'Deadline /' : `Deadline ${activity.deadline}`}

                      </ChallengeHeader>
                      <p className="title">{activity.title}</p>
                    </ChallengeTitle>
                    <ChallengeDescription>
                      <ChallengeHeader>Comment</ChallengeHeader>
                      <ShowMoreText
                        lines={2}
                        more="Read more"
                        less="Read less"
                        className="desc"
                      >
                        {ReactHtmlParser(activity.comment)}
                      </ShowMoreText>
                    </ChallengeDescription>
                    <ChallengePoints>
                      <ChallengeHeader>Points</ChallengeHeader>
                      <p>
                        <span className="points">
                          {isNaN(parseFloat(activity.points))
                            ? 0
                            : parseFloat(activity.points)}
                        </span>
                        <span className="total">/{activity.maxPoints}</span>
                      </p>
                    </ChallengePoints>
                  </Challenge>
                  {user?.id === studentPoints.data?.id && (
                    <StyledAssignmentBtn
                      uploadedLink={activity.submission_url === null}
                      onClick={() => {
                        if (activity.submission_url === null) {
                          setShownHomeworkPopUp(true);
                          setCurrentActivityId(activity.id);
                          handleSubmitedLink(activity);
                        }
                      }}
                    >
                      <span>
                        <img
                          src={
                            activity.submission_url === null
                              ? "/hyperlink.svg"
                              : "/hyperlinkGrey.svg"
                          }
                        />
                      </span>

                      <span>
                        {activity.submission_url === null
                          ? "Link your Solution"
                          : "Successfully Uploaded"}
                      </span>

                      {activity.submission_url !== null && (
                        <StyledEdit
                          onClick={() => {
                            setShownHomeworkPopUp(true);
                            setCurrentActivityId(activity.id);
                            handleSubmitedLink(activity);
                          }}
                        >
                          <span>Edit</span>
                          <img src="/editIcon.svg" />
                        </StyledEdit>
                      )}
                    </StyledAssignmentBtn>
                  )}
                  <HomeworkPopUp
                    show={shownHomeworkPopUp}
                    setShownHomeworkPopUp={setShownHomeworkPopUp}
                    handleCloseHomeworkPopUp={handleCloseHomeworkPopUp}
                    activityId={currentActivityId}
                    activitiesArray={activitiesArray}
                    setActivitiesArray={setActivitiesArray}
                    hasHomeworkLink={hasHomeworkLink}
                  />
                </StyledCardWrap>
              );
          })}
      </ChallengePointsWrapper>
      {shownChallenges > activitiesArray?.filter(activity => activity.type == 'Challenge').length ? (
        <ShowMoreWrapper>
          <p className="showMore" onClick={() => setShownChallenges(5)}>
            Show Less
          </p>
        </ShowMoreWrapper>
      ) : (shownChallenges < activitiesArray?.filter(activity => activity.type == 'Challenge').length) ? (
        <ShowMoreWrapper>
          <p className="showMore" onClick={() => setShownChallenges(shownChallenges + 5)}>
            Show More
          </p>
        </ShowMoreWrapper>
      ) : ''}
      <PointsTitle>Attendance Points</PointsTitle>
      <AttendanceWrapper>
        {attendanceArr &&
          attendanceArr.map((attendance, index) => {
            return (
              <Attendance
                show={attendance.title.length > 2}
                hasAttended={attendance.points}
              >
                <p className="date">{attendance.date}</p>
                <p className="header">{attendance.title}</p>
                {attendance.title.length > 2 ? (
                  <>
                    <p className="status">
                      {parseInt(attendance.points) ? "Present" : "Absent"}
                    </p>
                    <span className="attendance-img">
                      <img
                        src={
                          parseInt(attendance.points)
                            ? "/prisuten.svg"
                            : "/otsuten.svg"
                        }
                        alt="Attended"
                      />
                    </span>
                  </>
                ) : (
                  ""
                )}
              </Attendance>
            );
          })}
      </AttendanceWrapper>
      {shownAttendance > attendanceArr.length ? (
        <ShowMoreWrapper>
          <p className="showMore" onClick={() => setShownAttendance(12)}>
            Show Less
          </p>
        </ShowMoreWrapper>
      ) : (
        <ShowMoreWrapper>
          <p className="showMore" onClick={() => setShownAttendance(shownAttendance + 8)}>
            Show More
          </p>
        </ShowMoreWrapper>
      )}
    </Wrapper>
  );
}

function mapStateToProps(state) {
  return {
    studentPoints: state.pointReducer.studentPoints,

    user: state.userReducer.user,
  };
}

export default connect(mapStateToProps, null)(PointsWrapper);
