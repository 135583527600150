import React from 'react'

import styled, {keyframes} from 'styled-components'

import ScrollSpyMenu from '../RedesignComponents/Materials/ScrollSpyMenu';
import MaterialsContainer from '../RedesignComponents/Materials/MaterialsContainer';

import { fadeInUp } from 'react-animations';

const fadeInAnimation = keyframes`${fadeInUp}`;

export const Wrapper  = styled.div`
    animation: 1s ${fadeInAnimation};
    display: flex;
    justify-content: space-between;
    width: 94%;
    margin: auto;
    margin-top: 60px;
`

export default function MaterialsWrapper({ slug }) {
    return (
        <Wrapper>
            <ScrollSpyMenu slug={slug} />
            <MaterialsContainer slug={slug} />
        </Wrapper>
    )
}
