import React from 'react'
import Image from 'react-bootstrap/Image'
import styled from 'styled-components'
import { navigate } from "@reach/router"


const ContentContainer = styled.div`
    margin: auto;
    padding-top: 40px;
    width: 80%;
        h6 {
            color: blue;
        },
        h2 {
            font-weignt: bold;
        }
`

export default function OfferContent({ offers }) {
    function createMarkup(html) {
        return { __html: html };
    }

    return (
        <ContentContainer>
            <h6>Job position</h6>
            <h2>{offers ? offers.title : null}</h2><br />
            <h4>Company name</h4>
            <p>{offers ? offers.name : null}</p><br />
            <h4>Description</h4>
            <p dangerouslySetInnerHTML={createMarkup(offers ? offers.description : null)}></p><br />
            <h4>URL</h4>
            <p>{offers ? <a target='_blank' href={offers.url}>{offers.url}</a> : null}</p><br />
        </ContentContainer>
    )
}
