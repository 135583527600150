import styled, {keyframes} from 'styled-components'
import Container from 'react-bootstrap/Container'
import Row from 'react-bootstrap/Row'
import { fadeInUp } from 'react-animations';

const fadeInAnimation = keyframes`${fadeInUp}`;

export const ProfilePageContainer = styled(Container)`
    animation: 1s ${fadeInAnimation};
    margin-top: 50px;
    @media (min-width: 320px) and (max-width: 480px) {
        margin-top: 20px;
    }
`
export const ProfilePageWrapper = styled(Row)`
    margin-left: 0px !important;
    margin-right: 0px !important;

    @media (min-width: 320px) and (max-width: 480px) {
        padding: 0px 10px;
    }
`
