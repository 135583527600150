import * as types from '../actions/actionTypes'

export default function pointReducer(state = {}, action){
    switch (action.type) {
        case types.SET_ATTENDANCE_POINTS:
            return {...state, attendancePoints: {...state.attendancePoints, ...action.points}}
        case types.GET_POINTS:
            return {...state, points: {...state.points, ...action.points}}
        case types.GET_TOTAL_POINTS:
            return {...state, totalPoints: {...state.points, ...action.totalPoints}}
        case types.GET_STUDENT_POINTS:
            return {...state, studentPoints: {...state.points, ...action.studentPoints}}
        default:
            return state;
    }
}
