import React, { useState, useEffect } from 'react'
import styled from 'styled-components'

import { navigate } from "@reach/router"

const BackgroundModalWrapper = styled.div`
    position: fixed;
    left: 0;
    top: 0;
    z-index: 10000;
    background-color: rgb(229, 229, 229, 0.9);
    min-height: -webkit-fill-available;
    width: 100%;
    height: 100%;
    display: ${props => props.show ? 'block' :  'none'};
`

const ModalWrapper = styled.div`
    text-align: center;
    padding: 40px;
    position: absolute;
    width: 480px;
    height: 180px;
    left: 50%;
    margin-left: -240px;
    top: 286px;
    margin-top: -90px;
    background-color: #FFFFFF;
    border-radius: 8px;
    z-index:5000;

    p {
        font-weight: bold;
        font-size: 24px;
        line-height: 28px;
        color: #000000;
    }

    @media (min-width: 320px) and (max-width: 480px) {
        padding: 20px;
        width: 320px;
        height: 300px;
        margin-left: -160px;
        margin-top: -150px;
    }
`

const ModalButton = styled.button`
    background: ${props => props.backgroundColor || "#0A08E5;"};
    color: ${props => props.color || "white"};
    outline: none;
    border: none;
    cursor: pointer;
    font-weight: bold;
    font-size: 12px;
    line-height: 14px;
    width: 100px;
    height: 40px;
    margin: 10px;
`

export function Modal({ title, show, showHomepageBtn, showCloseBtn, handleCloseBtn }) {
    const [showModal, setShowModal] = useState(show)

    const hangleHomepageBtnClicked = () => {
        navigate('/')
    }

    const handleCloseBtnClicked = () => {
        setShowModal(false)
        handleCloseBtn()
    }

    useEffect(() => {
        setShowModal(show)
    }, [show]
    )

    return (
        <BackgroundModalWrapper show={showModal} >
            <ModalWrapper>
                <p>{title}</p>
                {
                showHomepageBtn === true && <ModalButton onClick={hangleHomepageBtnClicked}>Home Page</ModalButton>
                }
                {
                showCloseBtn === true && <ModalButton onClick={handleCloseBtnClicked}>Close</ModalButton>
                }
            </ModalWrapper>
        </BackgroundModalWrapper>
    )
}
