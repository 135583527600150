import styled from 'styled-components';

export const PBWrapper = styled.div`
    float: ${props => props.float || ""};
    margin-top: -32px;
`

export const PBTitle = styled.p`
    height: 18px;
    font-family: Ubuntu;
    font-style: normal;
    font-weight: bold;
    font-size: 16px;
    line-height: 18px;
    color: ${props => props.color || "#191919"}

    @media (min-width: 320px) and (max-width: 480px) {
        display: none;
    }
`
export const ProgressBarContent = styled.div`
    width: 240px;
    height: 24px;

    @media (min-width: 481px) and (max-width: 768px) {
        width: 160px;
        height: 24px;
    }

    @media (min-width: 320px) and (max-width: 480px) {
        width: 80px;
        height: 11px;
    }
`

export const PBFull = styled.div`
    width: 240px;
    height: 24px;
    background: #FFFFFF;

    @media (min-width: 481px) and (max-width: 768px) {
        width: 160px;
        height: 24px;
    }

    @media (min-width: 320px) and (max-width: 480px) {
        width: 80px;
        height: 11px;
    }
`

export const PBStatus = styled.div`
    width: ${props => props.percentage || 0}%;
    height: 24px;
    background: #0A08E5;

    @media (min-width: 481px) and (max-width: 768px) {
        height: 24px;
    }
    @media (min-width: 320px) and (max-width: 480px) {
        height: 11px;
    }
`
export const PBPercentage = styled.span`
    color: ${props => props.percentage < 12 ? "#191919" : "#FFFFFF"};
    padding-left: 10%;
    @media (min-width: 481px) and (max-width: 768px) {
        font-size: 14px;
    }
    @media (min-width: 320px) and (max-width: 480px) {
        font-size: 11px;
        font-weight: bolder;
        display: none;
    }
`