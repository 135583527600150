import React from 'react'
import styled from 'styled-components';
import { Link } from '@reach/router';

const HeaderLogo = styled.img`
    width: 150px;
    height: 65.05px;
    margin-left: 40px;
`

export default function Logo({ image }) {
    return (
        <Link to="/">
            {
                image ? <HeaderLogo src={image} /> : <HeaderLogo src='/brainster-learn-logo.svg' />
            }
        </Link>
    )
}
