import styled from 'styled-components'

const BackgroundColorWrapper = styled.div`
    position: relative;
    height: 100vh;
    max-width: 100%;
    background: rgba(35,36,38, 0.8)
`

export default BackgroundColorWrapper
