import * as types from './actionTypes'
import * as skillApi from "../../api/skillApi"
import { logout } from './userActions';
import { navigate } from '@reach/router';


export function allSkills(skills) {
    return { type: types.ALL_SKILLS, skills }
}

export function getAllSkills() {
    return function (dispatch) {
        return skillApi.allSkills()
            .then((result) => {
                dispatch(allSkills(result))
            }).catch((err) => {
                if(err.status === 401 && err.statusText === "Unauthorized"){
                    localStorage.removeItem('userAT')
                    dispatch(logout())
                    navigate('/login')
                }    
                throw err            
            });
    }
}
