import * as types from './actionTypes'
import * as categoryApi from "../../api/categoryApi"
import { logout } from './userActions';
import { navigate } from '@reach/router';


export function allCategories(categories) {
    return { type: types.ALL_CATEGORIES, categories }
}

export function getCategories() {
    return function (dispatch) {
        return categoryApi.allCategories()
            .then((result) => {
                dispatch(allCategories(result.categories))
            }).catch((err) => {
                if(err.status === 401 && err.statusText === "Unauthorized"){
                    localStorage.removeItem('userAT')
                    dispatch(logout())
                    navigate('/login')
                }     
                throw err      
            });
    }
}
