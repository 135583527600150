import React from "react";
import styled from "styled-components";
import Col from "react-bootstrap/Col";
import Form from "react-bootstrap/Form";
import ShareCertificate from "../Certificate/ShareCertificate";
import { AcademyDetailsWrapper } from "./Components/AcademyDetailsComponents";

const axios = require("axios");
const baseUrl = process.env.REACT_APP_API_URL || "http://localhost:8887/api";

// styled-components
const StyledButton = styled.button`
  background-color: #005eff !important;
  color: #fff !important;
  padding: 25px 30px !important;
  font-size: 20px !important;
  height: auto !important;

  :hover {
    background-color: #1f43e5 !important;
  }
`;

export default function AcademyDetails({ user }) {
  // on click on Download Certificate button
  const onDownloadCertificate = () => {
    const AuthStr = "Bearer " + (localStorage.getItem("userAT") || "");
    return axios
      .get(
        baseUrl + `/certificates/${user?.certificate?.id}/download`,

        { headers: { Authorization: AuthStr }, responseType: "blob" }
      )

      .then(function (response) {
        if (response.status === 200) {
          const url = window.URL.createObjectURL(
            new Blob([response?.data], { type: "application/pdf" })
          );
          const link = document.createElement("a");
          link.href = url;
          link.setAttribute("download", "certificate.pdf");
          document.body.appendChild(link);
          link.click();
          link.remove();
        } else {
          throw new Error("Bad Request");
        }
      })
      .catch(function (error) {
        console.log("Api failed", error);
        throw error.response;
      });
  };

  return (
    <section id="section-2" className="section2">
      <AcademyDetailsWrapper className="ppAcademyInfo">
        <Col sm={12} className="ppAIFormWrapper">
          <Form>
            <p>Academy Info</p>
            <Form.Row>
              <Form.Group as={Col} sm={4} controlId="formAcademy">
                <Form.Label>Academy</Form.Label>
                <Form.Control
                  type="text"
                  name="academy"
                  value={(user && user.group && user.group.title) || ""}
                  disabled
                />
              </Form.Group>
              <Form.Group as={Col} sm={4} controlId="formGroup">
                <Form.Label>Group</Form.Label>
                <Form.Control
                  type="text"
                  name="group"
                  value={(user && user.group && user.group.academy.title) || ""}
                  disabled
                />
              </Form.Group>
            </Form.Row>
          </Form>
          {user && user?.certificate?.is_confirmed === 1 && (
            <>
              <p>Certificate</p>
              <StyledButton
                type="button"
                onClick={() => onDownloadCertificate()}
              >
                Download Certificate
              </StyledButton>
              <ShareCertificate
                show={user?.certificate?.is_confirmed === 1}
                user={user}
              />
            </>
          )}
        </Col>
      </AcademyDetailsWrapper>
    </section>
  );
}
