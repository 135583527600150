import React from 'react'
import styled from 'styled-components'
import {Element} from 'react-scroll'
import Resource from './Resource'

const slugify = require('slugify')

const SubunitWrapper = styled.div`
    padding-top: 30px;

    hr {
        border: 2px solid #EFEFF4;
    }
`
const SubunitHeader = styled.div`
    display: flex;
    justify-content: space-between;
    align-items: center;

    p.title {
        font-family: Ubuntu;
        font-style: normal;
        font-weight: bold;
        font-size: 12px;
        line-height: 14px;
        text-transform: uppercase;
        color: #FF003A;
    }

    p.duration {
        font-family: Ubuntu;
        font-style: normal;
        font-weight: bold;
        font-size: 12px;
        line-height: 14px;
        text-align: right;
        text-transform: uppercase;
        color: #65676C;
    }
`

const SubunitResources = styled.div``

function Subunit({ subunit, categoryCompleted }) {
    return (
        <Element name={slugify(subunit.id + subunit.title)} >
            <SubunitWrapper>
                <SubunitHeader>
                    <p className="title">{subunit.title}</p>
                    <p className="duration">{subunit.duration > 0 ? (subunit.duration < 60 ? subunit.duration + " minutes" : ( Math.floor(subunit.duration / 60) + " Hours " + (subunit.duration % 60) + " Minutes")) : ''}</p>
                </SubunitHeader>
                <hr />
                <SubunitResources>
                    {
                        subunit.resources.map(resource => <Resource subunit={subunit} resource={resource} categoryCompleted={categoryCompleted} />)
                    }
                </SubunitResources>
            </SubunitWrapper>
        </Element>
    )
}

export default Subunit
