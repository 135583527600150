import { combineReducers } from 'redux'
import userReducer from './userReducer'
import countryReducer from './countryReducer'
import skillReducer from './skillReducer'
import materialReducer from './materialReducer'
import categoryReducer from './categoryReducer'
import messageReducer from './messageReducer'
import lectureReducer from './lectureReducer'
import surveyReducer from './surveyReducer'
import pointReducer from './pointReducer'
import jobOfferReducer from './jobOfferReducer'
import * as types from '../actions/actionTypes'

const appReducer = combineReducers({
    userReducer,
    countryReducer,
    skillReducer,
    materialReducer,
    categoryReducer,
    messageReducer,
    lectureReducer,
    surveyReducer,
    pointReducer,
    jobOfferReducer
})

const rootReducer = (state, action) => {
  if (action.type === types.LOGOUT_SUCCESS) {
    state = undefined
  }
  return appReducer(state, action)
}

export default rootReducer
