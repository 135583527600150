import React, { useEffect, useRef, useState } from "react";
import styled from "styled-components";
import { editHomeworkLink, setHomeworkLink } from "../../../api/homeWorkApi";
import { ErrorMessage } from "../../Common/ValidationMessages/Error";

const StyledBackgroundHomeworkPopUpWrapper = styled.div`
  position: fixed;
  left: 0;
  top: 0;
  bottom: 0;
  right: 0;
  z-index: 10000;
  min-height: -webkit-fill-available;
  display: ${(props) => (props.show ? "block" : "none")};
  backdrop-filter: blur(3px);
`;
const StyledHomeworkInnerWrapper = styled.div`
  text-align: center;
  padding: 40px 80px;
  position: absolute;
  width: 480px;
  left: 50%;
  margin-left: -240px;
  top: 286px;
  margin-top: -120px;
  background-color: #ffffff;
  border: 1px solid #005eff;
  border-radius: 10px;
  z-index: 5000;

  @media (min-width: 320px) and (max-width: 480px) {
    padding: 30px 20px;
    width: 320px;
    margin-left: -160px;
    margin-top: -150px;
  }
`;
const StyledTitle = styled.h2`
  width: 100%;
  font-size: 24px;
  font-style: normal;
  font-weight: 700;
  line-height: 28px;
  margin-bottom: 0;
  color: #191919;
`;

const StyledForm = styled.form`
  margin-top: 50px;
  text-align: left;

  label {
    display: block;
    font-family: "Ubuntu";
    font-style: normal;
    font-weight: 700;
    font-size: 12px;
    line-height: 14px;
    color: #a7a7a7;
  }
  input {
    width: 100%;
    opacity: 0.5;
    border-bottom: 2px solid #191919;
    border-top: none;
    border-left: none;
    border-right: none;
    outline: none;
    color: #191919;
  }
  input:focus {
    opacity: 1;
  }
  input::placeholder {
    font-size: 12px;
  }
`;
const StyledButtonWrap = styled.div`
  display: flex;
  justify-content: center;
  margin-top: 50px;
`;
const StyledActionBtn = styled.button`
  background-color: ${(props) =>
    props.cancel ? "#E1E2E7" : props.disabled ? "#86a7bf" : "#005EFF"};
  color: ${(props) => (props.cancel ? "##191919" : "#fff")};
  border: none;
  height: 40px;
  padding: 13px 30px;
  font-weight: 700;
  font-size: 12px;
  line-height: 14px;
  transition: box-shadow 0.3s;
  cursor: ${(props) => (props.disabled ? "not-allowed" : "pointer")};
  :not(:last-child) {
    margin-right: 16px;
  }
  :focus {
    outline: none;
  }

`;

function HomeworkPopUp({
  show,
  handleCloseHomeworkPopUp,
  activityId,
  hasHomeworkLink,
  activitiesArray,
  setActivitiesArray
}) {
  const [inputValue, setInputValue] = useState("");
  const [hasDeadlinePassed, sethasDeadlinePassed] = useState(false)
  const [errors, setErrors] = useState("");
  // reference
  const ref = useRef();

  // if user has submited link, show the link prefilled on click Edit button
  useEffect(() => {
    if (!hasHomeworkLink) {
      setInputValue("");
    } else {
      activitiesArray.map((activity) => {
        if (activity.id === activityId) {
          return setInputValue(activity.submission_url), sethasDeadlinePassed(activity.deadline_passed);
        }
      });
    }
  }, [activityId, hasHomeworkLink]);

  // on form submit
  const onHomeworkSubmit = (e) => {
    e.preventDefault();

    if (inputValue === "") {
      setErrors("Please link your solution!");
      return;
    } else {
      // submit link
      const data = {
        activityId: activityId,
        submissionUrl: inputValue,
      };

      if (!hasHomeworkLink) {
        // validate the input and submit new input
        setHomeworkLink(data)
          .then(() => {
            let updatedArr = activitiesArray.map((activity) => {
              if (activity.id === activityId) {
                return { ...activity, submission_url: inputValue };
              } else {
                return activity;
              }
            });

            setActivitiesArray(updatedArr)
            setInputValue("");
            handleCloseHomeworkPopUp()

          })
          .catch((msg) => setErrors(msg));

      } else {
        editHomeworkLink(data)
          .then(() => {
            let updatedArr = activitiesArray.map((activity) => {
              if (activity.id === activityId) {
                return { ...activity, submission_url: inputValue };
              } else {
                return activity;
              }
            });
            setActivitiesArray(updatedArr);
            handleCloseHomeworkPopUp()

          })
          .catch((msg) => console.log(msg));

      }

    }
  };

  const onCloseHomeWorkPopUp = () => {
    handleCloseHomeworkPopUp();
    setErrors("");
    if (hasHomeworkLink) {
      activitiesArray.map((activity) => {
        if (activity.id === activityId) {
          return setInputValue(activity.submission_url);
        }
      })
    } else {
      setInputValue("")
    }
  };

  return (
    <StyledBackgroundHomeworkPopUpWrapper
      show={show}
      onClick={onCloseHomeWorkPopUp}
    >
      <StyledHomeworkInnerWrapper onClick={(e) => e.stopPropagation()}>
        <StyledTitle>
          Upload your solution link
        </StyledTitle>
        <StyledForm onSubmit={onHomeworkSubmit}>
          <label>Drop your link here</label>
          <input
            type="text"
            placeholder="https://...."
            ref={ref}
            value={inputValue}
            onChange={() => setInputValue(ref.current.value)}
          />
        </StyledForm>
        {errors && <ErrorMessage>{errors}</ErrorMessage>}
        {hasDeadlinePassed && hasHomeworkLink && <ErrorMessage>Deadline passed!</ErrorMessage>}
        <StyledButtonWrap>
          <StyledActionBtn cancel={true} onClick={onCloseHomeWorkPopUp}>
            Cancel
          </StyledActionBtn>
          <StyledActionBtn
            type="submit"
            onClick={onHomeworkSubmit}
            disabled={hasDeadlinePassed && hasHomeworkLink}
          >
            Upload
          </StyledActionBtn>
        </StyledButtonWrap>
      </StyledHomeworkInnerWrapper>
    </StyledBackgroundHomeworkPopUpWrapper>
  );
}

export default HomeworkPopUp;
