import React, { useState, useEffect } from 'react'


import Col from 'react-bootstrap/Col';
import Form from 'react-bootstrap/Form';
import Loader from "react-loader-spinner";
import "react-loader-spinner/dist/loader/css/react-spinner-loader.css"

import { connect } from 'react-redux'
import * as userActions from '../../redux/actions/userActions'
import DropzoneCV from '../Common/Dropzone/Dropzone'
import { StyledSection, WebDetailsWrapper, RowWrapper, StyledImage } from './Components/WebDetailsComponents';
import RemoveFile from '../Common/Dropzone/RemoveFile';



function WebDetails({ user, userUpdate }) {
    const [saveBtnDisabled, setSaveBtnDisabled] = useState(true)
    const [saveBtnSpinner, setSaveBtnSpinner] = useState(false)
    const [inputs, setInputs] = useState({})

    const handleInputChange = (e) => {
        e.persist()
        setInputs(inputs => ({ ...inputs, socialProfiles: { ...inputs.socialProfiles, [e.target.name]: e.target.value } }))
    }

    const handleFormSubmit = (e) => {
        setSaveBtnSpinner(true)
        e.preventDefault()
        const dataToUpdate = {
            socialProfiles: inputs.socialProfiles
        }
        userUpdate(dataToUpdate)
            .then(() => {
                setSaveBtnSpinner(false)
                setSaveBtnDisabled(true)
            })
    }

    useEffect(() => {
        setInputs(() => ({ ...inputs, ...user }))
    },
        [user]
    )

    useEffect(() => {
        if (user) {
            if (JSON.stringify(inputs).replace(/null/gi, '""') !== JSON.stringify(user).replace(/null/gi, '""')) {
                setSaveBtnDisabled(false)
            } else {
                setSaveBtnDisabled(true)
            }
        }
    }, [inputs])

    return (
        <StyledSection id="section-3">
            <WebDetailsWrapper isdisabled={saveBtnDisabled}>
                <Col className="ppWDFormWrapper" sm={12}>
                    <p>On The Web</p>
                    <Form onSubmit={handleFormSubmit}>
                        <Form.Row>
                            <RowWrapper>
                                <Form.Group as={Col} controlId="formFacebook">
                                    <StyledImage src="/facebook.png" />
                                    <Form.Label>Facebook</Form.Label>
                                    <Form.Control type="text" name="facebook" onChange={handleInputChange} value={inputs.socialProfiles?.facebook || ''} />
                                </Form.Group>
                            </RowWrapper>
                            <RowWrapper>
                                <Form.Group as={Col} controlId="formTwitter">
                                    <StyledImage src="/twitter.png" />
                                    <Form.Label>Twitter</Form.Label>
                                    <Form.Control type="text" name="twitter" onChange={handleInputChange} value={inputs.socialProfiles?.twitter || ''} />
                                </Form.Group>
                            </RowWrapper>
                        </Form.Row>
                        <Form.Row>
                            <RowWrapper>
                                <Form.Group as={Col} controlId="formLinkedin">
                                    <StyledImage src="/linkedin.png" />
                                    <Form.Label>LinkedIn</Form.Label>
                                    <Form.Control type="text" name="linkedin" onChange={handleInputChange} value={inputs.socialProfiles?.linkedin || ''} />
                                </Form.Group>
                            </RowWrapper>
                            <RowWrapper>
                                <Form.Group as={Col} controlId="formBehance">
                                    <StyledImage src="/behance.png" />
                                    <Form.Label>Behance</Form.Label>
                                    <Form.Control type="text" name="behance" onChange={handleInputChange} value={inputs.socialProfiles?.behance || ''} />
                                </Form.Group>
                            </RowWrapper>
                        </Form.Row>
                        <Form.Row>
                            <RowWrapper>
                                <Form.Group as={Col} controlId="formDribble">
                                    <StyledImage src="/dribble.png" />
                                    <Form.Label>Dribble</Form.Label>
                                    <Form.Control type="text" name="dribble" onChange={handleInputChange} value={inputs.socialProfiles?.dribble || ''} />
                                </Form.Group>
                            </RowWrapper>
                            <RowWrapper>
                                <Form.Group as={Col} controlId="formGitHub">
                                    <StyledImage src="/github.png" />
                                    <Form.Label>GitHub</Form.Label>
                                    <Form.Control type="text" name="github" onChange={handleInputChange} value={inputs.socialProfiles?.github || ''} />
                                </Form.Group>
                            </RowWrapper>
                        </Form.Row>
                        <Form.Row>
                            <RowWrapper>
                                <Form.Group as={Col} controlId="formWebSite">
                                    <StyledImage src="/web.png" />
                                    <Form.Label>Website</Form.Label>
                                    <Form.Control type="text" name="website" onChange={handleInputChange} value={inputs.socialProfiles?.website || ''} />
                                </Form.Group>
                            </RowWrapper>
                            <RowWrapper>
                                <Form.Group as={Col} controlId="formUpwork">
                                    <StyledImage src="/upwork.png" />
                                    <Form.Label>Upwork</Form.Label>
                                    <Form.Control type="text" name="upwork" onChange={handleInputChange} value={inputs.socialProfiles?.upwork || ''} />
                                </Form.Group>
                            </RowWrapper>
                        </Form.Row>
                        <p style={{ marginTop: "20px" }}>CV</p>
                        {
                            user && user.cv ? <RemoveFile /> : <DropzoneCV />
                        }
                        <button disabled={saveBtnDisabled}>Save Changes</button>
                        {
                            saveBtnSpinner &&
                            <Loader type="ThreeDots" color="#0A08E5" height={60} width={60} style={{ display: "inline-block", "padding-left": "40px" }} />
                        }
                    </Form>
                </Col>
            </WebDetailsWrapper>
        </StyledSection>
    )
}

const mapDispatchToProps = {
    userUpdate: userActions.update,
}

export default connect(null, mapDispatchToProps)(WebDetails)
