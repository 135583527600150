import React from 'react'
import styled from 'styled-components'
import { navigate } from '@reach/router'

const ProgressBarWrapper = styled.div`
    display: inline-block;
    margin-left: 5%;
    width: 90%;
    @media only screen and (max-width: 600px) {
        margin-left: 0%;
    }
`

const ProgressBarTitle = styled.p`
    font-family: Ubuntu;
    font-style: normal;
    font-weight: bold;
    font-size: 14px;
    line-height: 16px;
    color: #000000;
    display: ${props => props.hideTitle  ? 'none' : 'block'};
    @media only screen and (max-width: 600px) {
        margin-bottom: 3px;
    }
`

const ProgressBar = styled.div`
    width: 100%;
    filter: drop-shadow(0px 8px 30px rgba(0, 0, 0, 0.08));
    background: #FFFFFF;
    box-shadow: 0px 8px 16px rgba(0, 0, 0, 0.16);
    border-radius: 100px;
    height: 16px;
    position: relative;
`

const ProgressBarStatus = styled.div`
    width: ${props => props.status ? props.status + '%' :  '0%'};
    background: ${props => props.status == "100" ? 'radial-gradient(131.25% 39562.5% at 1.09% 0%, #00D762 15.51%, #005EFF 100%)' :  '#005EFF'};
    border-radius: 100px;
    height: 16px;
    padding: 0px;
`

const ProgressBarPercentage = styled.div`
    display: flex;
    justify-content: center;
    align-items: center;
    position: absolute;
    width: 18%;
    height: 24px;
    border-radius: 100px;
    background: #E6E7EB;
    box-shadow: 0px 8px 30px rgba(0, 0, 0, 0.08);
    margin-top: -4px;
    font-family: Ubuntu;
    font-style: normal;
    font-weight: bold;
    font-size: 12px;
    line-height: 14px;
    color: #232426;
    left: ${props => (props.status &&  parseInt(props.status) > 17) ? (props.status - 18) + '%' :  '-1%'};

    @media only screen and (max-width: 600px) {
        width: 30%;
        left: ${props => (props.status &&  parseInt(props.status) > 17) ? (props.status - 30) + '%' :  '-1%'};
    }
`

function ProgressBarComponent({ title, status, hideTitle, slug, points, totalPoints}) {
    const handleOnClick = (e) => {
        if(e.target.getAttribute('data-slug')){
            navigate('/materials/' + e.target.getAttribute('data-slug'))
        }
    }
    return (
        <ProgressBarWrapper data-slug={slug} onClick={handleOnClick}>
            <ProgressBarTitle data-slug={slug} onClick={handleOnClick} hideTitle={hideTitle}>{title}</ProgressBarTitle>
            <ProgressBar>
                <ProgressBarStatus data-slug={slug} onClick={handleOnClick} status={status > 100 ? 100 : Math.round(status)}>
                    {
                        points ?
                        <ProgressBarPercentage status={status > 100 ? 100 : Math.round(status)}>{points}/{totalPoints}</ProgressBarPercentage> :
                        <ProgressBarPercentage data-slug={slug} onClick={handleOnClick} status={status > 100 ? 100 : Math.round(status)}>{status > 100 ? 100 : Math.round(status)}%</ProgressBarPercentage>
                    }
                </ProgressBarStatus>
            </ProgressBar>
        </ProgressBarWrapper>
    )
}

export default ProgressBarComponent
