const axios = require('axios');
const baseUrl = process.env.REACT_APP_API_URL || 'http://localhost:8887/api'

export function getSurveyDetails(surveyId) {
    const AuthStr = 'Bearer ' + (localStorage.getItem('userAT') || '')
    return axios.get(baseUrl + '/survey/' + surveyId, { headers: { Authorization: AuthStr } })
        .then(function (response) {
            if (response.status === 200) {
                return response.data;
            } else {
                throw new Error("Bad Request");
            }
        })
        .catch(function (error) {
            console.log("Api failed", error);
            throw error.response
        });
}

export function saveSurveyAnswers(data) {
    const AuthStr = 'Bearer ' + (localStorage.getItem('userAT') || '')
    return axios.post(baseUrl + '/survey', data, { headers: { Authorization: AuthStr } })
        .then(function (response) {
            if (response.status === 200) {
                return response.data;
            } else {
                throw new Error("Bad Request");
            }
        })
        .catch(function (error) {
            throw error.response
        });
}
