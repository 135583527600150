import styled from 'styled-components'

const BackgroundImageWrapper = styled.div`
    height: 100vh;
    max-width: 100%;
    background-image: url('/auth-back.svg');
    background-repeat: no-repeat;
    background-attachment: fixed;
    background-size: cover;

    @media only screen and (max-width: 600px) {
        position: relative;
        display: flex;
        flex-direction: column;
        justify-content: flex-end;
    }
`

export default BackgroundImageWrapper
