import * as types from './actionTypes'
import * as userApi from "../../api/userApi"
import { navigate } from "@reach/router"


export function loginSuccess(user) {
    return { type: types.LOGIN_SUCCESS, user }
}

export function logout() {
    return { type: types.LOGOUT_SUCCESS }
}

export function loginFailed() {
    return { type: types.LOGIN_FAILED }
}

export function userProgress(progressInfo) {
    return { type: types.USER_PROGRESS, progressInfo }
}

export function userDetails(user) {
    return { type: types.USER_DETAILS, user }
}

export function userUpdate(user) {
    return { type: types.USER_UPDATE, user }
}

export function userUpload(user) {
    return { type: types.USER_UPLOAD_FILE, user }
}

export function userDownloadCV() {
    return { type: types.USER_DOWNLOAD_CV }
}

export function userRemoveCV(user) {
    return { type: types.USER_REMOVE_CV, user }
}

export function userPasswordChange() {
    return { type: types.USER_PASSWORD_CHANGE }
}

export function userValidateEmail() {
    return { type: types.USER_VALIDATE_EMAIL }
}

export function userGeneratePassword() {
    return { type: types.USER_GENERATE_PASSWORD }
}


export function attemptLogin(credentials) {
    return function (dispatch) {
        return userApi.attemptLogin(credentials)
            .then((result) => {
                dispatch(loginSuccess(result.user))
            }).catch((err) => {
                dispatch(loginFailed)
                throw err
            });
    }
}

export function userLogout() {
    return function (dispatch) {
        return userApi.userLogout()
            .then((result) => {
                dispatch(logout())
            }).catch((err) => {
                if(err?.status === 401 && err.statusText === "Unauthorized"){
                    dispatch(logout())
                }
                throw err
            });
    }
}

export function getUserProgress() {
    return function (dispatch) {
        return userApi.userProgress()
            .then((result) => {
                dispatch(userProgress(result))
            }).catch((err) => {
                if(err.status === 401 && err.statusText === "Unauthorized"){
                    localStorage.removeItem('userAT')
                    dispatch(logout())
                    navigate('/login')
                }
                throw err
            });
    }
}

export function getUserDetails() {
    return function (dispatch) {
        return userApi.userDetails()
            .then((result) => {
                dispatch(userDetails(result))
            }).catch((err) => {
                dispatch(loginFailed)
                if(err.status === 401 && err.statusText === "Unauthorized"){
                    localStorage.removeItem('userAT')
                    dispatch(logout())
                    navigate('/login')
                }
                throw err
            });
    }
}

export function update(data) {
    return function (dispatch) {
        return userApi.userUpdate(data)
            .then((result) => {
                dispatch(userUpdate(result))
            }).catch((err) => {
                if(err.status === 401 && err.statusText === "Unauthorized"){
                    localStorage.removeItem('userAT')
                    dispatch(logout())
                    navigate('/login')
                }
                throw err
            });
    }
}

export function upload(data) {
    return function (dispatch) {
        return userApi.userUploadFile(data)
            .then((result) => {
                dispatch(userUpload(result))
            }).catch((err) => {
                if(err.status === 401 && err.statusText === "Unauthorized"){
                    localStorage.removeItem('userAT')
                    dispatch(logout())
                    navigate('/login')
                }
                throw err
            });
    }
}

export function passwordChange(data) {
    return function (dispatch) {
        return userApi.userChangePassword(data)
            .then((result) => {
                dispatch(userPasswordChange())
            }).catch((err) => {
                if(err.status === 401 && err.statusText === "Unauthorized"){
                    localStorage.removeItem('userAT')
                    dispatch(logout())
                    navigate('/login')
                }
                throw err
            });
    }
}

export function validateEmail(data) {
    return function (dispatch) {
        return userApi.userValidateEmail(data)
            .then((result) => {
                dispatch(userValidateEmail())
                return result
            }).catch((err) => {
                dispatch(loginFailed)
                throw err
            });
    }
}

export function generatePassword(data) {
    return function (dispatch) {
        return userApi.userGeneratePassword(data)
            .then((result) => {
                dispatch(userGeneratePassword())
                return result
            }).catch((err) => {
                dispatch(loginFailed)
                throw err
            });
    }
}

export function downloadCV() {
    return function (dispatch) {
        return userApi.userDownloadCV()
            .then((result) => {
                dispatch(userDownloadCV())
                return result
            }).catch((err) => {
                dispatch(loginFailed)
                throw err
            });
    }
}

export function removeCV() {
    return function (dispatch) {
        return userApi.userRemoveCV()
            .then((result) => {
                dispatch(userRemoveCV(result))
                return result
            }).catch((err) => {
                dispatch(loginFailed)
                throw err
            });
    }
}
