import React, { useState, useCallback } from 'react'
import { useDropzone } from 'react-dropzone'
import Image from 'react-bootstrap/Image'
import Loader from "react-loader-spinner";

import styled from 'styled-components'
import { connect } from 'react-redux'
import * as userActions from '../../../redux/actions/userActions'

const StyledDiv = styled.div`
  background-image: url('/dropCV.png');
  width: 120px;
  height: 120px;
  margin-top:20px;
  margin-bottom: 90px;
`

const StyledP = styled.p`
    font-weight: normal;
    font-size: 12px;
    line-height: 14px;
    text-align: center;
    color: #65676C !important;
    padding-top: 130px;
    text-transform: none !important;
`

function DropzoneCV({ userUpload }) {
    const [uploadCVSpinner, setUploadCVSpinner] = useState(false)
    const onDrop = useCallback(acceptedFiles => {
        setUploadCVSpinner(true)
        const data = new FormData()
        data.append('cv', acceptedFiles[0])
        userUpload(data)
            .then(() => {
                setUploadCVSpinner(false)
            })
    }, [])
    const { getRootProps, getInputProps, isDragActive } = useDropzone({ onDrop })

    return (
        <StyledDiv {...getRootProps()}>
            <input {...getInputProps()} />
            <StyledP>Drag & Drop Here or </StyledP>
            {
                uploadCVSpinner ?
                <Loader type="ThreeDots" color="#0A08E5" height={30} width={30}/>
                :
                <StyledP style={{ cursor: "pointer", paddingTop: "0px" }}><Image src="/upload.png" /> Browse</StyledP>
            }
        </StyledDiv>
    )
}

const mapDispatchToProps = {
    userUpload: userActions.upload
}

export default connect(null, mapDispatchToProps)(DropzoneCV)