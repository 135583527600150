import React, { useEffect, useState } from 'react'
import styled from 'styled-components'
import { connect } from 'react-redux'

import Achievement from './Achievement'

const Wrapper = styled.div`
    overflow: hidden;
`

function AchievementsWrapper({ categories }) {
    const [categoriesArray, setCategoriesArray] = useState([])
    const [highestAchiever, setHighestAchiever] = useState(true)

    useEffect(() => {
        setCategoriesArray(() => {
            if(categories){
                return Object.keys(categories).map((categoryId) => { return categories[categoryId] })
            }else{
                return []
            }
        })
    }, [categories])

    useEffect(() => {
        setHighestAchiever(() => {
            if(categoriesArray){
                for(let i = 0; i < categoriesArray.length; i++){
                    if(categoriesArray[i].totalCompletedResources !== categoriesArray[i].totalResources){
                        return false
                    }
                }
            }
        })
    }, [categoriesArray])

    return (
        <Wrapper>
            {
                categoriesArray.map((category) => {
                    return <Achievement
                        key={category.id}
                        blured={!(category.totalCompletedResources >= category.totalResources)}
                        image={category.totalCompletedResources >= category.totalResources ? (process.env.REACT_APP_FILES_URL + category.completedAchievmentIcon) : (process.env.REACT_APP_FILES_URL + category.achievmentIcon)}
                        header={category.achievmentTitle}
                        text={category.achievmentDescription} />
                })
            }
            <Achievement blured={!highestAchiever} image={highestAchiever ? "/highest-achiever-succ.png" : "highest-achiever.png" } header="Highest Achiever" text="Successfully Completing the Entire Programme" />
        </Wrapper>
    )
}

function mapStateToProps(state){
    return {
        categories: state.categoryReducer.categories
    }
}

export default connect(mapStateToProps, null)(AchievementsWrapper)
