import React, { useState, useCallback } from 'react'
import { useDropzone } from 'react-dropzone'
import Image from 'react-bootstrap/Image'
import Loader from "react-loader-spinner";

import styled from 'styled-components'
import { connect } from 'react-redux'
import * as userActions from '../../../redux/actions/userActions'

const StyledDiv = styled.div`
  background-image: url('/attachCV.svg');
  width: 120px;
  height: 120px;
  margin-top:20px;
  margin-bottom: 10px;
  cursor: pointer;
`

const StyledP = styled.p`
    font-weight: normal;
    font-size: 12px;
    line-height: 14px;
    text-align: center;
    color: #65676C !important;
    padding-top: 130px;
    text-transform: none !important;
`


function RemoveFile({ userDownloadCV, userRemoveCV }) {
    const [showSpinner, setShowSpinner] = useState(false)

    const handleDownloadFile = () => {
        setShowSpinner(true)
        userDownloadCV()
        .then(() => {
            setShowSpinner(false)
        })
    }

    const handleRemoveFile = () => {
        setShowSpinner(true)
        userRemoveCV()
        .then(() => {
            setShowSpinner(false)
        })
    }

    return (
        <div>
        <StyledDiv onClick={handleDownloadFile}>
            <StyledP>Click to view or </StyledP>
        </StyledDiv>
        <StyledP style={{ cursor: "pointer", textAlign: "left", marginTop: "30px", "paddingTop": "5px", marginLeft: "20px" }} onClick={handleRemoveFile}>
            <Image src="/closeIcon.svg" /> Remove
        </StyledP>
        { showSpinner ? <Loader type="ThreeDots" color="#0A08E5" height={30} width={30} style={{marginLeft: "25px"}}/> : ""}
        </div>
    )
}

const mapDispatchToProps = {
    userDownloadCV: userActions.downloadCV,
    userRemoveCV: userActions.removeCV
}

export default connect(null, mapDispatchToProps)(RemoveFile)
