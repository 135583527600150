import React, {useState, useEffect} from 'react'
import styled from 'styled-components'
import { trackPromise } from 'react-promise-tracker'

import { connect } from 'react-redux'

import * as pointsActions from "../../redux/actions/pointActions"
import * as userActions from "../../redux/actions/userActions"

import PointsWrapper from '../RedesignComponents/Points/PointsWrapper'

export const PointsPageWrapper  = styled.div`
    display: flex;
    justify-content: center;
    width: 100%;
    margin: auto;
`

function PointsContentWrapper({ getUserDetails, getStudentPoints, userId }) {

    useEffect(() => {
        trackPromise(getStudentPoints(userId))
        trackPromise(getUserDetails())
    }, [getStudentPoints, getUserDetails, userId]
    )

    return (
        <PointsPageWrapper>
            <PointsWrapper />
        </PointsPageWrapper>
    )
}

const mapDispatchToProps = {
    getStudentPoints: pointsActions.getStudentPoints,
    getUserDetails: userActions.getUserDetails,
}

export default connect(null, mapDispatchToProps)(PointsContentWrapper)
