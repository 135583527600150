import React, { useState, useEffect } from 'react'
import styled from 'styled-components'
import { Element } from 'react-scroll'
import VideoPopup from './VideoPopup'
import Loader from 'react-loader-spinner';
import ReactHtmlParser from 'react-html-parser'
import { trackPromise } from 'react-promise-tracker'
import { connect } from 'react-redux'
import Tooltip from 'rc-tooltip'


import * as materialActions from '../../../redux/actions/materialActions'

const slugify = require('slugify')

const Wrapper = styled.div`
    display: flex;
    justify-content: space-between;
    align-items: center;
    border-bottom: 1px solid #EFEFF4;
    margin-top: 5px;
    padding-bottom: 10px;
`

const ResourceIconWrapper = styled.div`
    width: 15%;
    display: flex;
    justify-content: flex-start;

    svg {
        cursor: pointer;
        margin-left: 10px;
        flex-direction: flex-row;
    }

    svg:hover {
        background-color: #C8C8C8;
        border-radius: 50%;
        padding: 2px;
    }

    .icon:hover path {
        fill: black;
    }

    @media only screen and (max-width: 910px) {
        width: 80%;
    }
`

const ResourceWrapper = styled.div`
    width: 60%;

    display: flex;
    flex-direction: column;

    p.title {
        font-family: Ubuntu;
        font-style: normal;
        font-weight: bold;
        font-size: 18px;
        line-height: 21px;
        color: #232426;
        margin-bottom: 5px;
    }

    p.description {
        font-family: Ubuntu;
        font-style: normal;
        font-weight: normal;
        font-size: 14px;
        line-height: 16px;
        color: #4D4D4D;
    }

    @media only screen and (max-width: 910px) {
        width: 80%;
    }
`
const ResourceActionsWrapper = styled.div`
    width: 25%;
    display: flex;
    flex-direction: row;
    justify-content: flex-end;
    align-items: center;

    span {
        font-family: Ubuntu;
        font-style: normal;
        font-weight: bold;
        font-size: 12px;
        line-height: 14px;
        text-transform: uppercase;
        color: #65676C;
        margin-right: 10px;
    }

    @media only screen and (max-width: 910px) {
        display: none;
    }
`

const MobileActionsWrapper = styled.div`
    display: flex;
    justify-content: space-between;
    flex-direction: row;
`

const ResourceBtn = styled.button`
    color: white;
    font-family: Ubuntu;
    font-style: normal;
    font-weight: bold;
    font-size: 12px;
    line-height: 14px;
    background: ${props => props.completed ? '#005EFF' : '#191919'};
    box-shadow: 0px 8px 30px rgba(0, 0, 0, 0.08);
    padding: 10px 20px;
    outline: none;
    border: none;
`

const DownloadBtn = styled.button`
    border: 1px solid #005EFF;
    border-radius: 8px;
    background: transparent;
    display: none;
    margin-top: 5px;

    &:focus {
        outline: none;
    }

    @media only screen and (max-width: 910px) {
        width: 48%;
        height: 55px;
        display: inline;
    }
`
const CompletedBtnMobile = styled.button`
    display: none;
    color: white;
    border: 1px solid ${props => props.completed ? '#005EFF' : '#191919'};
    border-radius: 8px;
    background: ${props => props.completed ? '#005EFF' : '#191919'};
    margin-top: 5px;

    &:focus {
        outline: none;
    }

    @media only screen and (max-width: 910px) {
        width: 48%;
        height: 55px;
        display: inline;
    }
`

function Resource({ subunit, resource, updateResource, categoryCompleted, downloadMaterials }) {
    const [openVideo, setOpenVideo] = useState(false)
    const [similarResources, setSimilarResources] = useState([])
    const [downloadMaterialSpinner, setdownloadMaterialSpinner] = useState(false)
    const [activeResource, setActiveResource] = useState({})
    const [disableDownloadBtn, setDisableDownloadBtn] = useState(false)

    useEffect(() => {
        if(openVideo) {
            document.body.classList.add('overflow-hidden');
        } else {
            document.body.classList.remove('overflow-hidden');
        }
    }, [openVideo])

    const handleClickResource = (res, resource) => {
        setActiveResource({ ...res, completed: resource.completed })
        setOpenVideo(true)
        // if (res.type === 'video') {
        //     setActiveResource({ ...res, completed: resource.completed })
        //     setOpenVideo(true)
        // } else if (res.type === 'material') {
        //     setdownloadMaterialSpinner(true)
        //     downloadMaterials(res.id, res.service)
        //         .then(() => {
        //             setdownloadMaterialSpinner(false)
        //         })
        //         .catch((err) => {
        //             setdownloadMaterialSpinner(false)
        //         })
        // } else if(res.type === 'text') {
        //     setActiveResource({ ...res, title: resource.title, description: resource.description, completed: resource.completed })
        //     setOpenVideo(true)
        // } else {
        //     if (res.data) {
        //         window.open(res.data)
        //     }
        // }
        // setSimilarResources(subunit.resources.filter((res) => (res.resourceData.filter((resData, index) => resData && (resData.type == 'video' || resData.type == 'text'))).length > 0))
        setSimilarResources(subunit.resources)
    }

    const handleDownload = (res) => {
        if (res.type === 'material') {
            setdownloadMaterialSpinner(true)
            setDisableDownloadBtn(true)
            downloadMaterials(res.id, res.service)
                .then(() => {
                    setdownloadMaterialSpinner(false)
                    setDisableDownloadBtn(false)
                })
                .catch((err) => {
                    setdownloadMaterialSpinner(false)
                    setDisableDownloadBtn(false)
                })
        }
    }

    const handleCloseResource = () => {
        setActiveResource({})
        setOpenVideo(false)
    }

    const handleCompletedResource = (e) => {
        const dataToUpdate = { resourceId: e.target.id }
        trackPromise(updateResource(dataToUpdate))
            .then(() => {
                categoryCompleted()
                setActiveResource({ ...activeResource, completed: true })
            })
    }

    return (
        <Element name={slugify(resource.id + resource.title)} >
            <Wrapper>
                <VideoPopup downloadMaterials={downloadMaterials} disableDownloadBtn={disableDownloadBtn} setActiveResource={setActiveResource} handleDownload={handleDownload} handleCompletedResource={handleCompletedResource} handleClickResource={handleClickResource} activeResource={activeResource} similarResources={similarResources} openVideo={openVideo} handleCloseResource={handleCloseResource} />
                <ResourceWrapper>
                    {
                        <p className="title" style={resource.description.length < 2 ? { marginBottom: "0px" } : {}}>{resource.title}</p>
                    }
                    {
                        resource.description.length > 3 ?
                            <p className="description">{ReactHtmlParser(resource.description)}</p> : ''
                    }
                    <MobileActionsWrapper>
                        {
                            resource.resourceData.map((resData, key) => resData.type === 'material' ? <DownloadBtn onClick={() => handleDownload(resData)}>Download Materials</DownloadBtn> : '')
                        }
                        <CompletedBtnMobile disabled={resource.completed ? true : false} key={resource.id} completed={resource.completed} onClick={handleCompletedResource} complete={resource.completed} id={resource.id}>
                            {resource.completed ? 'Completed' : 'Mark as Complete'}
                        </CompletedBtnMobile>
                    </MobileActionsWrapper>
                </ResourceWrapper>
                <ResourceIconWrapper>
                    {
                        resource.resourceData &&  
                        <Tooltip placement="bottom" trigger={['hover']} overlay={<span>Open material</span>}>
                            <svg class="icon" width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg"  onClick={() => handleClickResource(resource.resourceData[0], resource)}>
                        //                             <path d="M16 0H1.99C0.89 0 0 0.9 0 2L0.00999999 16C0.00999999 17.1 0.9 18 2 18H12L18 12V2C18 0.9 17.1 0 16 0ZM5 5H13C13.55 5 14 5.45 14 6C14 6.55 13.55 7 13 7H5C4.45 7 4 6.55 4 6C4 5.45 4.45 5 5 5ZM8 11H5C4.45 11 4 10.55 4 10C4 9.45 4.45 9 5 9H8C8.55 9 9 9.45 9 10C9 10.55 8.55 11 8 11ZM11 16.5V12C11 11.45 11.45 11 12 11H16.5L11 16.5Z" fill="#005EFF"/>
                        //                         </svg>
                        </Tooltip>
                        // resource.resourceData.map((item, key) =>
                        //     item.data && item.type === 'video' ?
                        //         <Tooltip placement="bottom" trigger={['hover']} overlay={<span>Play video</span>}>
                        //             <svg class="icon" xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" onClick={() => handleClickResource(item, resource)}>
                        //                 <path d="M12 2C6.48 2 2 6.48 2 12C2 17.52 6.48 22 12 22C17.52 22 22 17.52 22 12C22 6.48 17.52 2 12 2ZM10 15.5V8.5C10 8.09 10.47 7.85 10.8 8.1L15.47 11.6C15.74 11.8 15.74 12.2 15.47 12.4L10.8 15.9C10.47 16.15 10 15.91 10 15.5Z" fill="#005EFF" />
                        //             </svg>
                        //         </Tooltip>
                        //         : item.data && item.type === 'material' ?
                        //             <Tooltip placement="bottom" trigger={['hover']} overlay={<span>Download presentation</span>}>
                        //                 <svg class="icon" xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" onClick={() => handleClickResource(item, resource)}>
                        //                     <path d="M18 15V18H6V15H4V18C4 19.1 4.9 20 6 20H18C19.1 20 20 19.1 20 18V15H18ZM17 11L15.59 9.59L13 12.17V4H11V12.17L8.41 9.59L7 11L12 16L17 11Z" fill="#005EFF" />
                        //                 </svg>
                        //             </Tooltip>
                        //             : item.data && item.type === 'text-url' ?
                        //                 <Tooltip placement="bottom" trigger={['hover']} overlay={<span>View material</span>}>
                        //                     <svg class="icon" xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" onClick={() => handleClickResource(item, resource)} >
                        //                         <path d="M12 5.12499C11.54 5.12499 11.1666 4.75166 11.1666 4.29166V2.83333C11.1666 2.37333 11.54 2 12 2C12.46 2 12.8333 2.37333 12.8333 2.83333V4.29166C12.8333 4.75166 12.46 5.12499 12 5.12499Z" fill="#005EFF" />
                        //                         <path d="M12 22C11.54 22 11.1666 21.6267 11.1666 21.1667V16.5833C11.1666 16.1233 11.54 15.75 12 15.75C12.46 15.75 12.8333 16.1233 12.8333 16.5833V21.1667C12.8333 21.6267 12.46 22 12 22Z" fill="#005EFF" />
                        //                         <path d="M15.7491 22C15.6008 22 15.4508 21.9608 15.315 21.8783L11.565 19.5866C11.1725 19.3466 11.0483 18.8333 11.2883 18.4408C11.5283 18.0475 12.0416 17.925 12.4341 18.1641L16.1841 20.4558C16.5766 20.6958 16.7008 21.2091 16.4608 21.6016C16.3041 21.8583 16.03 22 15.7491 22Z" fill="#005EFF" />
                        //                         <path d="M8.25083 21.9998C7.97 21.9998 7.69584 21.8581 7.53917 21.6006C7.29917 21.2081 7.42334 20.6948 7.81584 20.4548L11.5658 18.1631C11.9583 17.924 12.4717 18.0465 12.7117 18.4398C12.9517 18.8323 12.8275 19.3456 12.435 19.5856L8.685 21.8773C8.54917 21.9606 8.39917 21.9998 8.25083 21.9998V21.9998Z" fill="#005EFF" />
                        //                         <path d="M20.5416 3.6665H3.45833C2.65416 3.6665 2 4.32067 2 5.12483V15.5415C2 16.3456 2.65416 16.9998 3.45833 16.9998H20.5416C21.3458 16.9998 21.9999 16.3456 21.9999 15.5415V5.12483C21.9999 4.32067 21.3458 3.6665 20.5416 3.6665ZM16.1666 10.5415C16.1666 10.794 16.0141 11.0223 15.7808 11.119C15.7033 11.1506 15.6225 11.1665 15.5416 11.1665C15.3791 11.1665 15.2191 11.1031 15.1 10.9831L14.5 10.384L13.0666 11.8173C12.8566 12.029 12.5266 12.0606 12.2783 11.8956L11.4225 11.3248L8.86581 13.5165C8.74748 13.6165 8.60248 13.6665 8.45831 13.6665C8.28248 13.6665 8.10748 13.5923 7.98415 13.4481C7.75915 13.1865 7.78998 12.7915 8.05165 12.5665L10.9683 10.0665C11.1816 9.88482 11.4883 9.86565 11.7216 10.0207L12.545 10.5698L13.6158 9.49982L13.0158 8.89982C12.8366 8.72149 12.7841 8.45232 12.88 8.21899C12.9775 7.98566 13.205 7.83316 13.4583 7.83316H15.5416C15.8866 7.83316 16.1666 8.11316 16.1666 8.45816V10.5415Z" fill="#005EFF" />
                        //                     </svg>
                        //                 </Tooltip>
                        //                 : item.data && item.type === 'text' ?
                        //                     <Tooltip placement="bottom" trigger={['hover']} overlay={<span>Reading materials</span>}>
                        //                         <svg class="icon" width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg"  onClick={() => handleClickResource(item, resource)}>
                        //                             <path d="M16 0H1.99C0.89 0 0 0.9 0 2L0.00999999 16C0.00999999 17.1 0.9 18 2 18H12L18 12V2C18 0.9 17.1 0 16 0ZM5 5H13C13.55 5 14 5.45 14 6C14 6.55 13.55 7 13 7H5C4.45 7 4 6.55 4 6C4 5.45 4.45 5 5 5ZM8 11H5C4.45 11 4 10.55 4 10C4 9.45 4.45 9 5 9H8C8.55 9 9 9.45 9 10C9 10.55 8.55 11 8 11ZM11 16.5V12C11 11.45 11.45 11 12 11H16.5L11 16.5Z" fill="#005EFF"/>
                        //                         </svg>
                        //                     </Tooltip>
                        //                     :
                        //                     <Tooltip placement="bottom" trigger={['hover']} overlay={<span>Follow link</span>}>
                        //                         <svg class="icon" xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" onClick={() => handleClickResource(item, resource)} >
                        //                             <path d="M17 7H14C13.45 7 13 7.45 13 8C13 8.55 13.45 9 14 9H17C18.65 9 20 10.35 20 12C20 13.65 18.65 15 17 15H14C13.45 15 13 15.45 13 16C13 16.55 13.45 17 14 17H17C19.76 17 22 14.76 22 12C22 9.24 19.76 7 17 7ZM8 12C8 12.55 8.45 13 9 13H15C15.55 13 16 12.55 16 12C16 11.45 15.55 11 15 11H9C8.45 11 8 11.45 8 12ZM10 15H7C5.35 15 4 13.65 4 12C4 10.35 5.35 9 7 9H10C10.55 9 11 8.55 11 8C11 7.45 10.55 7 10 7H7C4.24 7 2 9.24 2 12C2 14.76 4.24 17 7 17H10C10.55 17 11 16.55 11 16C11 15.45 10.55 15 10 15Z" fill="#005EFF" />
                        //                         </svg>
                        //                     </Tooltip>
                        // )}
                    // {
                    //     downloadMaterialSpinner ? <Loader type="ThreeDots" color="#0A08E5" height={30} width={30} /> : ''
                    // }
                }
                </ResourceIconWrapper>
                <ResourceActionsWrapper>
                    <span>
                        {
                            resource.duration > 0 ?
                                (resource.duration < 60 ? resource.duration + " Minutes" : (Math.floor(resource.duration / 60) + " Hours "))
                                : ''
                        }
                        {
                            resource.duration > 60 ? ((resource.duration % 60) > 0 ? (resource.duration % 60) + " Minutes" : '') : ''
                        }
                    </span>
                    <ResourceBtn disabled={resource.completed ? true : false} key={resource.id} completed={resource.completed} onClick={handleCompletedResource} id={resource.id} complete={resource.completed}>{resource.completed ? 'Completed' : 'Mark as Complete'}</ResourceBtn>
                </ResourceActionsWrapper>
            </Wrapper>
        </Element>
    )
}


const mapDispatchToProps = {
    updateResource: materialActions.updateResource,
    downloadMaterials: materialActions.downloadMaterials
}

export default connect(null, mapDispatchToProps)(Resource)
