import styled from 'styled-components'

export const SurveyContainer = styled.div`
    width: 80%;
    margin: auto;
    background-color: white;
`

export const SurveyContent = styled.div`
    padding: 40px;
    min-height: 500px;
`
