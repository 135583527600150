import React from 'react'
import Scrollspy from '../Common/Scrollspy/Scrollspy';

import FormsContainer from '../ProfilepageComponents/FormsContainer';
import { ProfilePageWrapper, ProfilePageContainer } from './Components/ProfilePageComponents';


export default function ProfilePageContentWrapper() {
    return (
        <ProfilePageContainer fluid>
            <ProfilePageWrapper>
                <Scrollspy />
                <FormsContainer />
            </ProfilePageWrapper>
        </ProfilePageContainer>
    )
}
