import React, { useState, useEffect } from 'react'

import Col from 'react-bootstrap/Col';
import Form from 'react-bootstrap/Form';
import Loader from "react-loader-spinner";
import "react-loader-spinner/dist/loader/css/react-spinner-loader.css"
import Multiselect from 'multiselect-react-dropdown';

import { connect } from 'react-redux'
import * as userActions from '../../redux/actions/userActions'
import { UserSkillsWrapper } from './Components/UserSkillsComponents';

function UserSkills({ user, skills, userUpdate }) {
    const [inputs, setInputs] = useState({});
    const [saveBtnDisabled, setSaveBtnDisabled] = useState(true)
    const [saveBtnSpinner, setSaveBtnSpinner] = useState(false)
    const [skillsArray, setSkillsArray] = useState([])
    const [selectedValues, setSelectedValues] = useState([])
    const [skillsList, setSkillsList] = useState([])

    const handleInputChange = (e) => {
        e.persist()
        setInputs(inputs => ({ ...inputs, [e.target.name]: e.target.value }))
    }

    const handleFormSubmit = (e) => {
        setSaveBtnSpinner(true)
        e.preventDefault()
        const dataToUpdate = {
            skills: skillsList,
            biography: inputs.bio,
            biography_en: inputs.bio_en
        }

        userUpdate(dataToUpdate)
            .then(() => {
                setSaveBtnSpinner(false)
                setSaveBtnDisabled(true)
            })
    }

    useEffect(() => {
        setInputs(() => ({ ...inputs, ...user }))
    }, [user])

    useEffect(() => {
        if (user) {
            if (JSON.stringify(inputs).replace(/null/gi, '""') !== JSON.stringify(user).replace(/null/gi, '""')) {
                setSaveBtnDisabled(false)
            } else {
                setSaveBtnDisabled(true)
            }
        }
    }, [inputs])

    useEffect(() => {
        setSkillsArray((skillsArray) => {
            if (skills) {
                return Object.keys(skills).map((id) => { return skills[id] })
            } else {
                return []
            }
        })
    }, [skills]);

    useEffect(() => {
        setSelectedValues(() => ({ ...inputs.skills }))
    },
        [inputs.skills]);

    const onSelect = (selectedList, selectedItem) => {
        setSkillsList(() => ({ ...selectedList }))
        setSaveBtnDisabled(false);
    }

    const onRemove = (selectedList, removedItem) => {
        setSkillsList(() => ({ ...selectedList }))
        setSaveBtnDisabled();
    }

    return (
        <section id="section-5">
            <UserSkillsWrapper isdisabled={saveBtnDisabled}>
                <Col sm={12}>
                    <Form onSubmit={handleFormSubmit}>
                        <p>Bio</p>
                        <Form.Row>
                            <Form.Group className="formUserSkills" as={Col} controlId="formSkills">
                                <Form.Label>Skills</Form.Label>
                                <Multiselect
                                    displayValue="name"
                                    groupBy="type"
                                    selectionLimit="10"
                                    selectedValues={selectedValues}
                                    onRemove={onRemove}
                                    onSelect={onSelect}
                                    options={skillsArray}
                                />
                                <small>Select up to 10 skills</small>
                            </Form.Group>
                        </Form.Row>
                        <Form.Row>
                            <Form.Group className="formUserBio" sm={12} lg={6} as={Col} controlId="formSkills">
                                <Form.Label>Biography Mk</Form.Label>
                                <Form.Control as="textarea" value={inputs.bio || ''} row={6} name="bio" onChange={handleInputChange} />
                            </Form.Group>
                            <Form.Group className="formUserBioEn" sm={12} lg={6} as={Col} controlId="formSkills">
                                <Form.Label>Biography En</Form.Label>
                                <Form.Control as="textarea" value={inputs.bio_en || ''} row={6} name="bio_en" onChange={handleInputChange} />
                            </Form.Group>
                        </Form.Row>
                        <button type="submit" disabled={saveBtnDisabled}>Save Changes</button>
                        {
                            saveBtnSpinner &&
                            <Loader type="ThreeDots" color="#0A08E5" height={60} width={60} style={{ display: "inline-block", "paddingLeft": "40px" }} />
                        }
                    </Form>
                </Col>
            </UserSkillsWrapper>
        </section>
    )
}

const mapDispatchToProps = {
    userUpdate: userActions.update
}

export default connect(null, mapDispatchToProps)(UserSkills)
