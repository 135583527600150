import React, { useState, useEffect, useRef } from 'react'
import { findDOMNode } from 'react-dom'
import styled from 'styled-components'
import { Collapse } from 'antd';
import ReactHtmlParser from 'react-html-parser'
import Loader from 'react-loader-spinner';
import ReactPlayer from 'react-player/lazy'

import { sendVideoStatsData } from '../../../api/materialsApi';

import VideoHeaderDetails from './VideoHeaderDetails'

import 'antd/dist/antd.css'
import './MenuItem.css'


const { Panel } = Collapse;
const VideoPopupWrapper = styled.div`
    width: 100%;
    height: 115%;
    z-index: 1001;
    background-color: black;
    display: ${props => props.show ? 'flex' : 'none'};
    justify-content: space-between;
    position: fixed;
    top: 0;
    left: 0;
    padding: 80px;
    overflow-y: auto;

    @media only screen and (max-width: 600px) {
        overflow-y: scroll;
    }
    @media only screen and (max-width: 910px) {
        padding: 30px;
        height: 100vh;
        overflow-y: scroll;
    }
`

const ResourceWrapper = styled.div`
    width: 65%;
    height: 80vh;

    @media only screen and (max-width: 600px) {
        width: 100%;
    }

    @media only screen and (min-width: 600px) and (max-width: 910px) {
        width: 100%;
        height: 100vh;
    }
`

const VideoMenuWrapper = styled.div`
    position: fixed;
    width: 25%;
    margin: auto;
    min-height: 200px;
    height: auto;
    filter: drop-shadow(0px 8px 30px rgba(0, 0, 0, 0.08));
    border-radius: 50px 50px 10px 10px;
    background: #232426;
`

const VideoBarHeader = styled.div`
    height: 50px;
    background: #2C2E30;
    border-radius: 50px 50px 0px 0px;
`

const VideoBarHeaderTitle = styled.div`
    border-radius: 50px 0px 50px 0px;
    background: #232426;
    width: 60%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;

    p {
        font-family: Ubuntu;
        font-style: normal;
        font-weight: bold;
        font-size: 12px;
        line-height: 14px;
        text-transform: uppercase;
        color: #65676C;
        margin-bottom: 0px;
    }
`
const VideoBarBody = styled.div`
    padding: 32px 24px;
    background: #232426;
    border-radius: 0px 0px 10px 10px;
    max-height: 500px;
    overflow-y: auto;

    p {
        font-family: Ubuntu;
        font-style: normal;
        font-weight: bold;
        font-size: 16px;
        line-height: 18px;
        color: #EFEFF4;
        cursor: pointer;
    }

    .resourceData {
        margin-left: 20px;

        path {
            fill: #EFEFF4;
        }
    }
`
const VideoWrapper = styled.div`
    width: 100%;
    margin: auto;
    height: auto;
    border-radius: 50px 50px 10px 10px;
    background: #232426;
    margin-bottom: 50px;

    p.description {
        font-size: 12px;
        line-height: 12px;
        color: #EFEFF4;
        padding: 10px;
    }

    @media only screen and (max-width: 910px) {
        min-height: unset;
        background: transparent;
        p.description {
            display: none;
        }
    }
`

const VideoHeader = styled.div`
    height: 50px;
    background: #2C2E30;
    border-radius: 50px 50px 0px 0px;
    display: flex;
    justify-content: flex-start;

    @media only screen and (max-width: 910px) {
        display: none;
    }

`
const VideoHeaderTitle = styled.div`
    border-radius: 50px 0px 50px 0px;
    background: #232426;
    width: 40%;
    height: 100%;
    display: flex;
    justify-content: space-around;
    align-items: center;
`

const VideoHeaderProgressBar = styled.div`
    width: 60%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
`

const VideoBody = styled.div`
    height: 60vh;
    padding: 15px;

    @media only screen and (max-width: 600px) {
        padding: 0px;
        height: 70vh;
    }
    @media only screen and (min-width: 600px) {
        padding: 0px;
        height: 50vh;
    }

    @media only screen and (min-width: 768px) {
        padding: 0px;
        height: 50vh;
    }

    & .videoContainer {
        text-align: center;
        height: 100%;
    }

    & iframe {
        position: relative;
        top:0;
        left:0;
        width:100%;
        height:100%;

        @media only screen and (max-width: 600px) {
            height:50%;
        }

        @media only screen and (min-width: 600px) and (max-width: 910px) {
            height:50%;
        }
    }
`

const VideoActions = styled.div`
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-top: 1%;
    padding: 10px;

    span {
        font-family: Ubuntu;
        font-style: normal;
        font-weight: bold;
        font-size: 24px;
        line-height: 28px;
        color: #EFEFF4;
        @media only screen and (max-width: 910px) {
            padding: 8px;
        }
    }

    @media only screen and (max-width: 910px) {
        display: none;
    }
`

const ResourceBtn = styled.button`
    background: ${props => props.completed ? '#0A08E5' : '#191919'};
    border: none;
    padding: 10px 20px;
    outline: none;
    color: #FDFDFD;
    font-family: Ubuntu;
    font-style: normal;
    font-weight: bold;
    font-size: 12px;
    line-height: 14px;

    &:focus {
        outline: none;
    }

    @media only screen and (max-width: 910px) {
       display: none;
    }
`

const CompletedActionBtn = styled.button`
    background: ${props => props.completed ? '#0A08E5' : '#191919'};
    border: none;
    padding: 10px 20px;
    outline: none;
    color: #FDFDFD;
    font-family: Ubuntu;
    font-style: normal;
    font-weight: bold;
    font-size: 12px;
    line-height: 14px;
    float: right;

    &:focus {
        outline: none;
    }
`

const VideoControlBtn = styled.button`
    &:focus {
        outline: none;
    }

    border: 1px solid #005EFF;
    background: #005EFF;
    height: 40px;
    color: #EFEFF4;
    font-weight: bold;
    padding: 0 15px;
    font-size: 0.7em;
`

const ResourceDataActionBtn = styled.button`
    background: #0A08E5;
    border: 1px solid #005EFF;
    background: #005EFF;
    height: 40px;
    color: #EFEFF4;
    font-weight: bold;
    padding: 0 15px;
    font-size: 0.7em;
    font-family: Ubuntu;
    font-style: normal;
    line-height: 14px;
    margin: 0 auto;

    &:focus {
        outline: none;
    }

    // @media only screen and (max-width: 910px) {
    //    display: none;
    // }
`

const CompletedBtn = styled.button`
    background: ${props => props.completed ? '#005EFF;' : '#191919'};
    border: none;
    border-radius: 100px;
    padding: 10px 20px;
    outline: none;
    color: white;
    font-family: Ubuntu;
    font-style: normal;
    font-weight: bold;
    font-size: 18px;
    line-height: 21px;
    display: none;
    width: 100%;

    &:focus {
        outline: none;
    }

    // @media only screen and (max-width: 910px) {
    //     display: block;
    // }
`
const PlayNextBtn = styled.button`
    border: 2px solid #65676C;
    box-sizing: border-box;
    border-radius: 100px;
    padding: 10px 20px;
    outline: none;
    color: #65676C;
    font-style: normal;
    font-weight: bold;
    font-size: 18px;
    line-height: 21px;
    display: none;
    width: 100%;
    background: transparent;
    margin-top: 10px;

    &:focus {
        outline: none;
    }

    @media only screen and (max-width: 910px) {
        display: block;
    }
`

const CloseVideoPopup = styled.img`
    position: absolute;
    right: 20px;
    top: 15px;
    cursor: pointer;
`

const LeftSidebarWrapper = styled.div`
    width: 25%;
    display: block;

    @media only screen and (max-width: 910px) {
        display: none;
    }
`

const MobileVideoHeader = styled.div`
    display: none;
    margin-bottom: 15px;
    margin-top: 15px;

    p.mobileDescription {
        font-size: 12px;
        line-height: 12px;
        color: #EFEFF4;
    }

    span {
        font-style: normal;
        font-weight: bold;
        font-size: 20px;
        line-height: 22px;
        color: #EFEFF4;
    }

    @media only screen and (max-width: 910px) {
        display: block;
    }

    
`

const MobileVideosFilter = styled.div`
    background: #232426;
    box-shadow: 0px 8px 30px rgba(0, 0, 0, 0.08);
    border-radius: 10px;
    margin-bottom: 20px;
    display: none;
    margin-top: 5%;

    @media only screen and (max-width: 910px) {
        display: block;
    }
`

const MobileVideosFilterHeader = styled.div`
    display: flex;
    justify-content: space-between;
    align-items: center;

    p {
        font-weight: bold;
        font-size: 16px;
        line-height: 18px;
        color: #EFEFF4;
        margin-bottom: 0px;
    }
`

const MobileVideosWrapper = styled.div`
    border-top: 2px solid #E1E2E7;

    .modulesWrapper {
        margin-top:20px;


        p.title {
            font-style: normal;
            font-weight: bold;
            font-size: 18px;
            line-height: 21px;
            color: #C5C5C5;
            cursor: pointer;
        }

        // p.completed {
        //     font-style: normal;
        //     font-weight: bold;
        //     font-size: 18px;
        //     line-height: 21px;
        //     color: #65676C;
        //     cursor: pointer;
        // }

        p.active {
            font-family: Ubuntu;
            font-style: normal;
            font-weight: bold;
            font-size: 24px;
            line-height: 28px;
            color: #FDFDFD;
            cursor: pointer;
        }

        // p.uncompleted {
        //     font-style: normal;
        //     font-weight: bold;
        //     font-size: 18px;
        //     line-height: 21px;
        //     color: #C5C5C5;
        //     cursor: pointer;
        // }

        .resourceData {
            margin-left: 20px;
            display: flex;
            align-items: center;
        }
    }
`

const StyledLoader = styled.div`
    display: inline-block;
    margin-left: 10px;
    margin-right: 10px;
`

const VideoControlsWrapper = styled.div`
    display: flex;

    & .video-controls {
        display: flex;
        justify-content: center;
        width: 100%;
        color: #EFEFF4;
        flex-wrap: wrap;

        & .control {
            margin: 10px;
            display: flex;
            align-items: center;

            & input, & button {
                accent-color: #005EFF;
            }

            & span {
                margin-right: 10px;
            }
        }
    }
`

const DurationBox = styled.div`
    display: flex;
    width: 100%;
    justify-content: center;
    color: #EFEFF4;    
`

const GeneralWrapper = styled.div`
    display: block;
    padding: 20px;
    padding-bottom: 100px;
    color: #EFEFF4;
    // width: 65%;
    height: 80vh;
    overflow: auto;

    @media only screen and (max-width: 600px) {
        width: 100%;
    }

    @media only screen and (min-width: 600px) and (max-width: 910px) {
        width: 100%;
        height: 100vh;
    }
`

const defaultState = {
    url: null,
    pip: false,
    playing: false,
    controls: true,
    light: false,
    volume: 0.8,
    muted: false,
    played: 0,
    playedSeconds: 0,
    loaded: 0,
    duration: 0,
    playbackRate: 1.0,
    loop: false,
    player: null
  }

function VideoPopup({ setActiveResource, handleClickResource, similarResources, openVideo, handleCloseResource, activeResource, handleCompletedResource, downloadMaterials }) {
    const [selectedResource, setSelectedResource] = useState(activeResource)
    const [totalResources, setTotalResources] = useState(similarResources.length)
    const [totalDuration, setTotalDuration] = useState(0)
    const [videoUrl, setVideoUrl] = useState('')
    const [open, setOpen] = useState(["0"]);
    const [menuResources, setMenuResources] = useState(similarResources)
    const [videoStatsData, setVideoStatsData] = useState({ start: 0, end: 0 })
    const [downloadMaterialSpinner, setdownloadMaterialSpinner] = useState(false)
    const ref = (p) => {player = p;}
    let player = null;
    // new player info:
    const [videoPlayerSettings, setVideoPlayerSettings] = useState(defaultState);

    const loadVideo = url => {
        setVideoPlayerSettings({...defaultState, 
          url,
          played: 0,
          loaded: 0,
          pip: true
        })
      }
    
      const handlePlayPause = () => {
        setVideoPlayerSettings({ ...videoPlayerSettings, playing: !videoPlayerSettings.playing })
      }
    
    
      const handleVolumeChange = e => {
        setVideoPlayerSettings({...videoPlayerSettings, volume: parseFloat(e.target.value) })
      }
    
      const handleToggleMuted = () => {
        setVideoPlayerSettings({...videoPlayerSettings, muted: !videoPlayerSettings.muted })
      }
    
      const handleSetPlaybackRate = e => {
        setVideoPlayerSettings({...videoPlayerSettings, playbackRate: parseFloat(e.target.value) })
      }
    
      const handleOnPlaybackRateChange = (speed) => {
        setVideoPlayerSettings({...videoPlayerSettings, playbackRate: parseFloat(speed) })
      }
    
      const handlePlay = () => {
        setVideoPlayerSettings({...videoPlayerSettings, playing: true })

        let videoStatsDataNew = videoStatsData;
        videoStatsDataNew.start = parseInt(videoPlayerSettings.playedSeconds);
        setVideoStatsData(videoStatsDataNew)

      }
    
      const handleEnablePIP = () => {
        setVideoPlayerSettings({...videoPlayerSettings, pip: true })
      }
    
      const handleDisablePIP = () => {
        setVideoPlayerSettings({...videoPlayerSettings, pip: false })
      }
    
      const handlePause = () => {
        setVideoPlayerSettings({...videoPlayerSettings, playing: false })

        let videoStatsDataNew = videoStatsData;
        videoStatsDataNew.end = parseInt(videoPlayerSettings.playedSeconds);
        setVideoStatsData(videoStatsDataNew)
        //We will track only if at least 5 seconds were watched
        if (videoStatsDataNew.end - videoStatsDataNew.start > 5) {
            sendVideoStatsData({ resource_data_id: activeResource.id, start: videoStatsDataNew.start, end: videoStatsDataNew.end })
        }
      }
    
      const handleSeekMouseDown = e => {
        setVideoPlayerSettings({...videoPlayerSettings, seeking: true })
      }
    
      const handleSeekChange = e => {
        setVideoPlayerSettings({...videoPlayerSettings, played: parseFloat(e.target.value) })
      }
    
      const handleSeekMouseUp = e => {
        setVideoPlayerSettings({...videoPlayerSettings, seeking: false })
        player.seekTo(parseFloat(e.target.value))
      }
    
     const handleProgress = state => {
        //We only want to update time slider if we are not currently seeking
        if (!videoPlayerSettings.seeking) {
            setVideoPlayerSettings({...videoPlayerSettings, played: state.played, playedSeconds: state.playedSeconds})
        }
      }
    
      const handleEnded = () => {
        setVideoPlayerSettings({...videoPlayerSettings, playing: videoPlayerSettings.loop })
      }
    
      const handleDuration = (duration) => {
        setVideoPlayerSettings({...videoPlayerSettings, duration })
      }
    
      const handleClickFullscreen = () => {
        findDOMNode(player).requestFullscreen().catch(
            (err) => 
            {console.log("Could not activate full-screen mode :(")}
        );
      }

    useEffect(() => {
        setMenuResources(similarResources)
        // videoToPlay(selectedResource)
    }, [selectedResource, similarResources])


    useEffect(() => {
        let total = 0
        similarResources.forEach((res) => {
            total += res.duration
        })
        setTotalDuration(total)
        setTotalResources(similarResources.length)
        setVideoUrl('')
    }, [similarResources])

    useEffect(() => {
        setSelectedResource(activeResource)
        // videoToPlay(activeResource)
        if(activeResource.type == 'video') {
            loadVideo(activeResource.vimeoUrl)
        } 
    }, [activeResource])


    const secondsToTime = seconds => {
        var date = new Date(0);
        date.setSeconds(seconds); // specify value for SECONDS here
        return date.toISOString().substring(11, 19);
    }

    const resoureDataTypeToMenuName = type => {
        switch (type) {
            case 'material':
                return 'Download Materials'
                break;
            case 'video-url':
                return 'External video'
                break;    
            case 'text-url':
                return 'External material'
                break;
            case 'video':
                return 'Video'
                break;
            case 'text':
                return 'Text'
                break;
            default:
                break;
        }
    }

    const resourceDataTypeToIcon = type => {
        switch (type) {
            case 'material':
                return `<svg class="icon" xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" onClick={() => handleClickResource(item, resource)}>
                            <path d="M18 15V18H6V15H4V18C4 19.1 4.9 20 6 20H18C19.1 20 20 19.1 20 18V15H18ZM17 11L15.59 9.59L13 12.17V4H11V12.17L8.41 9.59L7 11L12 16L17 11Z" fill="#005EFF" />
                        </svg>`
                break;
            case 'video-url':
                return `<svg class="icon" xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" onClick={() => handleClickResource(item, resource)} >
                            <path d="M17 7H14C13.45 7 13 7.45 13 8C13 8.55 13.45 9 14 9H17C18.65 9 20 10.35 20 12C20 13.65 18.65 15 17 15H14C13.45 15 13 15.45 13 16C13 16.55 13.45 17 14 17H17C19.76 17 22 14.76 22 12C22 9.24 19.76 7 17 7ZM8 12C8 12.55 8.45 13 9 13H15C15.55 13 16 12.55 16 12C16 11.45 15.55 11 15 11H9C8.45 11 8 11.45 8 12ZM10 15H7C5.35 15 4 13.65 4 12C4 10.35 5.35 9 7 9H10C10.55 9 11 8.55 11 8C11 7.45 10.55 7 10 7H7C4.24 7 2 9.24 2 12C2 14.76 4.24 17 7 17H10C10.55 17 11 16.55 11 16C11 15.45 10.55 15 10 15Z" fill="#005EFF" />
                        </svg>`
                break;    
            case 'text-url':
                return `<svg class="icon" xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" onClick={() => handleClickResource(item, resource)} >
                            <path d="M12 5.12499C11.54 5.12499 11.1666 4.75166 11.1666 4.29166V2.83333C11.1666 2.37333 11.54 2 12 2C12.46 2 12.8333 2.37333 12.8333 2.83333V4.29166C12.8333 4.75166 12.46 5.12499 12 5.12499Z" fill="#005EFF" />
                            <path d="M12 22C11.54 22 11.1666 21.6267 11.1666 21.1667V16.5833C11.1666 16.1233 11.54 15.75 12 15.75C12.46 15.75 12.8333 16.1233 12.8333 16.5833V21.1667C12.8333 21.6267 12.46 22 12 22Z" fill="#005EFF" />
                            <path d="M15.7491 22C15.6008 22 15.4508 21.9608 15.315 21.8783L11.565 19.5866C11.1725 19.3466 11.0483 18.8333 11.2883 18.4408C11.5283 18.0475 12.0416 17.925 12.4341 18.1641L16.1841 20.4558C16.5766 20.6958 16.7008 21.2091 16.4608 21.6016C16.3041 21.8583 16.03 22 15.7491 22Z" fill="#005EFF" />
                            <path d="M8.25083 21.9998C7.97 21.9998 7.69584 21.8581 7.53917 21.6006C7.29917 21.2081 7.42334 20.6948 7.81584 20.4548L11.5658 18.1631C11.9583 17.924 12.4717 18.0465 12.7117 18.4398C12.9517 18.8323 12.8275 19.3456 12.435 19.5856L8.685 21.8773C8.54917 21.9606 8.39917 21.9998 8.25083 21.9998V21.9998Z" fill="#005EFF" />
                            <path d="M20.5416 3.6665H3.45833C2.65416 3.6665 2 4.32067 2 5.12483V15.5415C2 16.3456 2.65416 16.9998 3.45833 16.9998H20.5416C21.3458 16.9998 21.9999 16.3456 21.9999 15.5415V5.12483C21.9999 4.32067 21.3458 3.6665 20.5416 3.6665ZM16.1666 10.5415C16.1666 10.794 16.0141 11.0223 15.7808 11.119C15.7033 11.1506 15.6225 11.1665 15.5416 11.1665C15.3791 11.1665 15.2191 11.1031 15.1 10.9831L14.5 10.384L13.0666 11.8173C12.8566 12.029 12.5266 12.0606 12.2783 11.8956L11.4225 11.3248L8.86581 13.5165C8.74748 13.6165 8.60248 13.6665 8.45831 13.6665C8.28248 13.6665 8.10748 13.5923 7.98415 13.4481C7.75915 13.1865 7.78998 12.7915 8.05165 12.5665L10.9683 10.0665C11.1816 9.88482 11.4883 9.86565 11.7216 10.0207L12.545 10.5698L13.6158 9.49982L13.0158 8.89982C12.8366 8.72149 12.7841 8.45232 12.88 8.21899C12.9775 7.98566 13.205 7.83316 13.4583 7.83316H15.5416C15.8866 7.83316 16.1666 8.11316 16.1666 8.45816V10.5415Z" fill="#005EFF" />
                        </svg>`
                break;
            case 'video':
                return `<svg class="icon" xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" onClick={() => handleClickResource(item, resource)}>
                            <path d="M12 2C6.48 2 2 6.48 2 12C2 17.52 6.48 22 12 22C17.52 22 22 17.52 22 12C22 6.48 17.52 2 12 2ZM10 15.5V8.5C10 8.09 10.47 7.85 10.8 8.1L15.47 11.6C15.74 11.8 15.74 12.2 15.47 12.4L10.8 15.9C10.47 16.15 10 15.91 10 15.5Z" fill="#005EFF" />
                        </svg>`
                break;
            case 'text':
                return `<svg class="icon" width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg"  onClick={() => handleClickResource(item, resource)}>
                            <path d="M16 0H1.99C0.89 0 0 0.9 0 2L0.00999999 16C0.00999999 17.1 0.9 18 2 18H12L18 12V2C18 0.9 17.1 0 16 0ZM5 5H13C13.55 5 14 5.45 14 6C14 6.55 13.55 7 13 7H5C4.45 7 4 6.55 4 6C4 5.45 4.45 5 5 5ZM8 11H5C4.45 11 4 10.55 4 10C4 9.45 4.45 9 5 9H8C8.55 9 9 9.45 9 10C9 10.55 8.55 11 8 11ZM11 16.5V12C11 11.45 11.45 11 12 11H16.5L11 16.5Z" fill="#005EFF"/>
                        </svg>`
                break;
            default:
                break;
        }
    }

    const handleClickResourceInsidePopup = (resourceData, resource) => {
        setActiveResource({ ...resourceData, completed: resource.completed })
    }

    const downloadMaterialInsidePopup = (resourceData) => {
        setdownloadMaterialSpinner(true)
        downloadMaterials(resourceData.id, resourceData.service)
            .then(() => {
                setdownloadMaterialSpinner(false)
            })
            .catch((err) => {
                setdownloadMaterialSpinner(false)
            })
       
    }

    return (

        <VideoPopupWrapper show={openVideo}>
            <CloseVideoPopup src="/close-video.svg" onClick={handleCloseResource} />
            <LeftSidebarWrapper>
                <VideoMenuWrapper>
                    <VideoBarHeader>
                        <VideoBarHeaderTitle>
                            <p>Module curriculum</p>
                        </VideoBarHeaderTitle>
                    </VideoBarHeader>
                    <VideoBarBody>
                        {
                            similarResources.map((resource, index) => {
                                return <div>
                                        <p style={{display: "flex", justifyContent: "space-between", alignItems: "center"}} onClick={() => handleClickResourceInsidePopup(resource.resourceData[0], resource)} className={resource.id === activeResource.resource_id ? 'simResources activiSimilarResource' : 'simResources'} key={index}>
                                            {index + 1 + '. ' + resource.title}
                                            {selectedResource.resource_id == resource.id ?  
                                                <CompletedActionBtn disabled={selectedResource.completed ? true : false} key={selectedResource.id} completed={selectedResource.completed} onClick={handleCompletedResource} id={selectedResource.resource_id}>{selectedResource.completed ? 'Completed' : 'Mark as Complete'}</CompletedActionBtn>
                                                : ''
                                            }
                                        </p>
                                            {resource.resourceData.map((resourceData, index) => {
                                                return <p  onClick={() => handleClickResourceInsidePopup(resourceData, resource)} className={resourceData.id === activeResource.id ? 'simResources resourceData activiSimilarResource' : 'simResources resourceData'} key={resourceData.id + index}>
                                                    <span  dangerouslySetInnerHTML={{__html: resourceDataTypeToIcon(resourceData.type)}}></span> <span>{resourceData.title?.length > 2 ? resourceData.title : resoureDataTypeToMenuName(resourceData.type)}</span>
                                                </p>
                                            })}
                                </div>
                            })

                        }
                       
                    </VideoBarBody>
                </VideoMenuWrapper>
            </LeftSidebarWrapper>
                {/* {activeResource.type == 'video' ? */}
                <ResourceWrapper>
                    <MobileVideosFilter>
                        <Collapse accordion bordered={false} ghost={true} activeKey={open} onChange={() => setOpen(prev => open.includes('1') ? ['0'] : ['1'])}>
                            <Panel onChange={() => setOpen(prev => [1])} header={
                                <MobileVideosFilterHeader>
                                    <p>Module curriculum</p>
                                    <img src="/featured-videos.svg" alt="videos" />
                                </MobileVideosFilterHeader>
                            }
                                key="1"
                                showArrow={false}>
                                <MobileVideosWrapper>
                                    <div className="modulesWrapper">
                                        {
                                            selectedResource && menuResources.map((resource, index) => {
                                                // return <p key={index} onClick={() => handleClickResource(resource.resourceData.filter(item => (item.type === 'video' || item.type === 'text'))[0], resource)} className={resource.id === selectedResource.resource_id ? 'active simResources' : (resource.completed ? 'completed simResources' : 'uncompleted simResources')} data-id={resource.id} data-url={resource.resourceData.filter(item => item.type === 'video').data}>{index + 1 + '. ' + resource.title}</p>
                                                return <div>
                                                    <p style={{display: "flex", justifyContent: "space-between", alignItems: "center"}} onClick={() => handleClickResourceInsidePopup(resource.resourceData[0], resource)} className={resource.id === activeResource.resource_id ? 'simResources title activiSimilarResource' : 'title simResources'} key={index}>
                                                        {index + 1 + '. ' + resource.title}
                                                        {selectedResource.resource_id == resource.id ?  
                                                            <CompletedActionBtn disabled={selectedResource.completed ? true : false} key={selectedResource.id} completed={selectedResource.completed} onClick={handleCompletedResource} id={selectedResource.resource_id}>{selectedResource.completed ? 'Completed' : 'Mark as Complete'}</CompletedActionBtn>
                                                            : ''
                                                        }
                                                    </p>
                                                        {resource.resourceData.map((resourceData, index) => {
                                                            return <p onClick={() => handleClickResourceInsidePopup(resourceData, resource)} className={resourceData.id === activeResource.id ? 'simResources resourceData activiSimilarResource title' : 'simResources resourceData title'} key={resourceData.id + index}>
                                                                 <span  dangerouslySetInnerHTML={{__html: resourceDataTypeToIcon(resourceData.type)}}></span> <span>{resoureDataTypeToMenuName(resourceData.type)}</span>
                                                            </p>
                                                        })}
                                            </div>
                                            
                                            })
                                            
                                        }
                                    </div>
                                </MobileVideosWrapper>
                            </Panel>
                        </Collapse>
                    </MobileVideosFilter>
                    {activeResource.type == 'video' ?
                    <VideoWrapper>
                        <VideoHeader>
                            <VideoHeaderTitle>
                                <VideoHeaderDetails icon="/resources.svg" title={totalResources + " Resources"} />
                                <VideoHeaderDetails icon="/time.svg" title={totalDuration < 60 ? totalDuration + " Minutes" : (Math.floor(totalDuration / 60) + " Hours ")} />
                            </VideoHeaderTitle>
                            <VideoHeaderProgressBar></VideoHeaderProgressBar>
                        </VideoHeader>
                        {/* <VideoControlsWrapper>
                            <div class="video-controls">
                                {/* <div>
                                    Played
                                    <progress max={1} value={videoPlayerSettings.played} />
                                </div> * /}
                                <div class="control">
                                    <VideoControlBtn onClick={handlePlayPause}>{videoPlayerSettings.playing ? 'Pause' : 'Play'}</VideoControlBtn>
                                </div>
                                <div  class="control">
                                    <VideoControlBtn onClick={handleClickFullscreen}>Fullscreen</VideoControlBtn>
                                </div>
                                <div class="control">
                                    <VideoControlBtn onClick={handleSetPlaybackRate} value={1}>1x</VideoControlBtn>
                                    <VideoControlBtn onClick={handleSetPlaybackRate} value={1.5}>1.5x</VideoControlBtn>
                                    <VideoControlBtn onClick={handleSetPlaybackRate} value={2}>2x</VideoControlBtn>
                                </div>
                                <div class="control">
                                    <span>Seek</span>
                                    <input
                                        type='range' min={0} max={0.999999} step='any'
                                        value={videoPlayerSettings.played}
                                        onMouseDown={handleSeekMouseDown}
                                        onChange={handleSeekChange}
                                        onMouseUp={handleSeekMouseUp}
                                    />
                                </div>
                                <div class="control">
                                    <span>Volume</span>
                                    <input type='range' min={0} max={1} step='any' value={videoPlayerSettings.volume} onChange={handleVolumeChange} />
                                </div>
                                <div class="control">
                                    <span>Mute</span>
                                    <input id='muted' type='checkbox' checked={videoPlayerSettings.muted} onChange={handleToggleMuted} />
                                </div>
                            </div>
                        </VideoControlsWrapper> */}
                        {/* <DurationBox>
                            {secondsToTime(videoPlayerSettings.playedSeconds)} / {secondsToTime(videoPlayerSettings.duration)}
                        </DurationBox> */}
                        <VideoBody>
                            {
                                videoPlayerSettings.url ?
                                <ReactPlayer
                                    ref={ref}
                                    className='react-player'
                                    width='100%'
                                    height='100%'
                                    url={videoPlayerSettings.url}
                                    pip={videoPlayerSettings.pip}
                                    playing={videoPlayerSettings.playing}
                                    controls={videoPlayerSettings.controls}
                                    controlsList="nodownload"
                                    light={videoPlayerSettings.light}
                                    loop={videoPlayerSettings.loop}
                                    playbackRate={videoPlayerSettings.playbackRate}
                                    volume={videoPlayerSettings.volume}
                                    muted={videoPlayerSettings.muted}
                                    onReady={() => console.log('onReady')}
                                    onStart={() => console.log('onStart')}
                                    onPlay={handlePlay}
                                    onEnablePIP={handleEnablePIP}
                                    onDisablePIP={handleDisablePIP}
                                    onPause={handlePause}
                                    onBuffer={() => console.log('onBuffer')}
                                    onPlaybackRateChange={handleOnPlaybackRateChange}
                                    onSeek={e => console.log('onSeek', e)}
                                    onEnded={handleEnded}
                                    onError={e => console.log('onError', e)}
                                    onProgress={handleProgress}
                                    onDuration={handleDuration}
                                    config={{
                                        file:{
                                            attributes: {
                                                controlsList: 'nodownload'
                                            }
                                        }
                                    }}
                                />
                                    :
                                    ''
                            }
                            <MobileVideoHeader>
                                <span id="videoTitle">{activeResource.title}</span>
                                {/* {
                                    selectedResource.description && selectedResource.description.length > 2 ?
                                        <p className="mobileDescription">{ReactHtmlParser(selectedResource.description)}</p> : ''
                                } */}
                            </MobileVideoHeader>

                        </VideoBody>
                        <VideoActions>
                            <span id="videoTitle">{activeResource.title}</span>

                        </VideoActions>
                        {/* {
                            selectedResource.description && selectedResource.description.length > 2 ?
                                <p className="description">{ReactHtmlParser(selectedResource.description)}</p> : ''
                        } */}
                    </VideoWrapper>
                    : activeResource.type == 'material' ? 
                        <GeneralWrapper>
                            <p>By clicking the download button, the materials provided with this lecture will be downloaded to your computer.</p>
                            <ResourceDataActionBtn onClick={() => downloadMaterialInsidePopup(activeResource)}>Download Materials</ResourceDataActionBtn>
                            {
                                downloadMaterialSpinner ? <Loader type="ThreeDots" color="#0A08E5" height={30} width={30} /> : ''
                            }
                        </GeneralWrapper>
                    : activeResource.type == 'video-url' ? 
                        <GeneralWrapper>
                            <p>By clicking the below link, you will be taken to an external page to watch a video related to the current lesson.</p>
                            <ResourceDataActionBtn onClick={() => window.open(activeResource.data)}>Follow link</ResourceDataActionBtn>
                        </GeneralWrapper>
                    : activeResource.type == 'text-url' ?
                        <GeneralWrapper>
                            <p>By clicking the below link, you will be taken to an external page where you can read additional information related to the current lesson.</p>
                            <ResourceDataActionBtn onClick={() => window.open(activeResource.data)}>Follow link</ResourceDataActionBtn>
                        </GeneralWrapper>
                    : activeResource.type == 'text' ? 
                        <GeneralWrapper dangerouslySetInnerHTML={{__html: activeResource.data}}></GeneralWrapper>
                    : <GeneralWrapper></GeneralWrapper>
                    }
                </ResourceWrapper>
                
        </VideoPopupWrapper>
    )
}


export default VideoPopup
