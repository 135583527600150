import React from 'react'

import PageWrapper from '../Components/Wrappers/PageWrapper'
import Header from '../Components/Common/Header/Header'
import HomepageContentWrapper from '../Components/Wrappers/HomepageContentWrapper'
import Footer from '../Components/Common/Footer/Footer'

function Homepage() {
    return (
        <PageWrapper>
            <Header />
            <HomepageContentWrapper />
            <Footer />
        </PageWrapper>
    )
}

export default Homepage
