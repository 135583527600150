const axios = require('axios');
const baseUrl = process.env.REACT_APP_API_URL || 'http://localhost:8887/api'


export function materialsByGroup() {
    const AuthStr = 'Bearer ' + (localStorage.getItem('userAT') || '')
    return axios.get(baseUrl + '/materials', { headers: { Authorization: AuthStr } })
        .then(function (response) {
            if (response.status === 200) {
                return response.data.data;
            } else {
                throw new Error("Bad Request");
            }
        })
        .catch(function (error) {
            console.log("Api failed", error);
            throw error.response
        });
}

export function updateResource(data) {
    const AuthStr = 'Bearer ' + (localStorage.getItem('userAT') || '')
    return axios.put(baseUrl + '/materials', data, { headers: { Authorization: AuthStr } })
        .then(function (response) {
            if (response.status === 200) {
                return response.data.data;
            } else {
                throw new Error("Bad Request");
            }
        })
        .catch(function (error) {
            throw error.response
        });
}

export function downloadMaterials(id, service) {
    const AuthStr = 'Bearer ' + (localStorage.getItem('userAT') || '')
    return axios.get(baseUrl + '/materials/download/' + id + '/' + service, { headers: { Authorization: AuthStr }, responseType: 'blob' })
        .then(function (response) {
            if (response.status === 200) {
                // let contentType = response.headers['content-type']
                let fileName = response.headers['file-name']
                // let extension = fileName.substring(fileName.lastIndexOf('.'), fileName.length)
                const url = window.URL.createObjectURL(new Blob([response.data]));
                const link = document.createElement('a');
                link.href = url;
                link.setAttribute('download', fileName);
                document.body.appendChild(link);
                link.click();
                link.remove()
            } else {
                throw new Error("Bad Request");
            }
        })
        .catch(function (error) {
            throw error.response
        });
}

export function sendVideoStatsData(videoStats) {
    const AuthStr = 'Bearer ' + (localStorage.getItem('userAT') || '')
    return axios.post(baseUrl + `/video/stats`, videoStats, { headers: { Authorization: AuthStr } })
        .then(function (response) {
            // if (response.status === 200) {
            //     localStorage.setItem("userAT", response.data.access_token)
            //     return response.data;
            // } else {
            //     throw new Error("Bad Request");
            // }
        })
        .catch(function (error) {
            // console.log("Api failed", error.response);
            // throw error.response
        });
}