import * as types from './actionTypes'
import * as messagesApi from "../../api/messagesApi"
import { logout } from './userActions';
import { navigate } from '@reach/router';


export function messages(messages) {
    return { type: types.ALL_MESSAGES, messages }
}

export function getMessages() {
    return function (dispatch) {
        return messagesApi.getUserMessages()
            .then((result) => {
                dispatch(messages(result.messages))
            }).catch((err) => {
                if(err.status === 401 && err.statusText === "Unauthorized"){
                    localStorage.removeItem('userAT')
                    dispatch(logout())
                    navigate('/login')
                } 
                throw err
            });
    }
}

