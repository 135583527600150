import React, { useState, useEffect } from 'react'
import styled from 'styled-components'

import { navigate } from "@reach/router"
import ReactInputMask from 'react-input-mask'
import { setOfferStatus } from '../../../api/jobOfferApi'
import Form from 'react-bootstrap/Form';
import { ErrorMessage } from '../../../Components/Common/ValidationMessages/Error';

const BackgroundTalentMarketWrapper = styled.div`
    position: fixed;
    left: 0;
    top: 0;
    z-index: 10000;
    background-color: rgb(229, 229, 229, 0.9);
    min-height: -webkit-fill-available;
    width: 100%;
    height: 100%;
    display: ${props => props.show ? 'block' : 'none'};
`

const TalentMarketWrapper = styled.div`
    text-align: center;
    padding: 40px;
    position: absolute;
    width: 480px;
    left: 50%;
    margin-left: -240px;
    top: 286px;
    margin-top: -120px;
    background-color: #FFFFFF;
    border: 1px solid #005EFF; 
    border-radius: 10px;
    z-index:5000;

    p {
        margin: auto;
        width: 70%;
        font-weight: bold;
        font-size: 24px;
        line-height: 22px;
        padding-bottom: 10px;
        color: black!important;
    }
    span {
        display: block;
        padding-bottom: 10px;
        font-size: 12px;
        color: black;
    }
    input {
        border-top: none;
        border-left: none;
        border-right: none;
        outline: none;
        color: black;
    }

    @media (min-width: 320px) and (max-width: 480px) {
        padding: 20px;
        width: 320px;
        height: 300px;
        margin-left: -160px;
        margin-top: -150px;
    }
`

const CancelButton = styled.button`
    background: #005EFF;
    border: none;
    outline: none;
    padding: 0 20px;
    height: 40px;
    font-style: normal;
    font-weight: bold;
    font-size: 12px;
    line-height: 18px;
    color: #FFFFFF;
    margin: auto;  
    margin-top: 20px;

`

const DeclineTalentMarketButton = styled.button`
    background: #FFF;
    padding: 0 20px;
    font-style: normal;
    font-weight: bold;
    font-size: 12px;
    line-height: 18px;
    color: red;
    height: 40px;
    outline: none;
    margin-left: 10px; 
    margin-top: 20px;
`

function DeclineTalentMarket({ show, offers, handleCancelButtonClicked }) {
    const [inputs, setInputs] = useState({});
    const [errorMsg, setErrorMsg] = useState("");

    const handleInputChange = (e) => {
        e.persist()
        setInputs(inputs => ({ ...inputs, [e.target.name]: e.target.value }))
    }

    const handleDeclineBtnClicked = (e) => {
        e.preventDefault()
        if (offers) {
            const dataToUpdate = {
                offerId: offers.id,
                status: false,
                comment: inputs.comment || ''
            }
            setOfferStatus(dataToUpdate)
                .then(() => {
                    window.location.href = '/'
                })
                .catch((msg) => {
                    setErrorMsg(msg)
                })
        }
    }
    return (
        <BackgroundTalentMarketWrapper show={show}>
            <TalentMarketWrapper>
                <p>Are you sure you want to decline this offer?</p>
                <span>Once the offer is declined, you cannot undo the action.</span>
                <div class="decline">
                    <span>Why are you declining this offer?</span>
                    <input type="text" name="comment" onChange={handleInputChange} />
                    <ErrorMessage>{errorMsg}</ErrorMessage>
                </div>
                <CancelButton onClick={handleCancelButtonClicked}>Cancel</CancelButton>
                <DeclineTalentMarketButton backgroundColor="transparent" color="#65676C" onClick={handleDeclineBtnClicked}>Decline</DeclineTalentMarketButton>
            </TalentMarketWrapper>
        </BackgroundTalentMarketWrapper>
    )
}

export default DeclineTalentMarket;
