import React from 'react'
import styled from 'styled-components'

const BackgroundCategoryCompletedWrapper = styled.div`
    position: fixed;
    left: 0;
    top: 0;
    z-index: 10000;
    background-color: rgb(229, 229, 229, 0.9);
    min-height: -webkit-fill-available;
    width: 100%;
    height: 100%;
    display: ${props => props.show ? 'block' :  'none'};
`

const CategoryCompletedWrapper = styled.div`
    text-align: center;
    padding: 40px;
    position: absolute;
    width: 400px;
    height: auto;
    left: 50%;
    margin-left: -200px;
    top: 200px;
    background-color: #FFFFFF;
    border-radius: 8px;
    z-index:5000;
    background-image: url('/congratulations.svg');
    background-repeat: no-repeat;
    background-position: 50% 30%;

    p {
        font-weight: bold;
        font-size: 24px;
        line-height: 28px;
        color: #000000;
        margin-bottom: 55px;
    }

    p:not(:first-child) {
        margin-top: 4%;
        font-size: 14px;
        line-height: 16px;
        text-align: center;
        color: #65676C;
        margin-top: 70px;
    }

    @media (min-width: 320px) and (max-width: 480px) {
        padding: 20px;
        width: 320px;
        height: 300px;
        margin-left: -160px;
        margin-top: -150px;
    }
`

const ClaimButton = styled.button`
    color: white;
    outline: none;
    border: none;
    cursor: pointer;
    font-weight: bold;
    font-size: 12px;
    line-height: 14px;
    width: 80%;
    height: 50px;
    margin: 10px;

    background: #005EFF;
    border-radius: 100px;
`

export default function CategoryCompleted({ show, activeCategory, handleClaimBtnClicked }) {
    return (
        <BackgroundCategoryCompletedWrapper show={show}>
            <CategoryCompletedWrapper>
                <p>Congratulations !</p>
                <img src={process.env.REACT_APP_FILES_URL + activeCategory.completedAchievmentIcon} alt="achievment_icon" width="85"/>
                <p>Congratulations! {activeCategory.achievmentDescription}</p>
                <ClaimButton onClick={handleClaimBtnClicked}>Claim Reward</ClaimButton>
            </CategoryCompletedWrapper>
        </BackgroundCategoryCompletedWrapper>
    )
}
