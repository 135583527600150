const axios = require('axios');
const baseUrl = process.env.REACT_APP_API_URL || 'http://localhost:8887/api'

export function allJobOffers() {
    const AuthStr = 'Bearer ' + (localStorage.getItem('userAT') || '')
    return axios.get(baseUrl + '/hiringProposals', { headers: { Authorization: AuthStr } })
        .then(function (response) {
            if (response.status === 200) {
                return response.data.data;
            } else {
                throw new Error("Bad Request");
            }
        })
        .catch(function (error) {
            throw error.response
        });
}

export function setOfferStatus(data) {
    const AuthStr = 'Bearer ' + (localStorage.getItem('userAT') || '')
    return axios.put(baseUrl + '/hiringProposals', data, { headers: { Authorization: AuthStr } })
        .then(function (response) {
            if (response.status === 200) {
                return response.data;
            } else {
                throw new Error("Bad Request");
            }
        })
        .catch(function (error) {
            if (error.response.status === 422) {
                throw error.response.data.message;
            }
            throw "An error occured";// error.response
        });
}