import React, { useEffect } from 'react'
import Col from 'react-bootstrap/Col';
import UserDetails from './UserDetails';
import UserSkills from './UserSkills';
import AcademyDetails from './AcademyDetails';
import WebDetails from './WebDetails';
import PrivacyDetails from './PrivacyDetails';
import styled from 'styled-components'

import { trackPromise } from 'react-promise-tracker'
import { connect } from 'react-redux'

import * as userActions from '../../redux/actions/userActions'
import * as countryActions from '../../redux/actions/countryActions'
import { Element } from 'react-scroll';
import * as skillActions from '../../redux/actions/skillActions'


const FormsContainerWrapper = styled(Col)`
    min-height: 100vh;
`


function FormsContainer({ user, countries, skills, getUserDetails, getAllCountries, getAllSkills }) {
    useEffect(() => {
        trackPromise(getUserDetails())
        trackPromise(getAllCountries())
        trackPromise(getAllSkills())
    }, [getUserDetails, getAllCountries, getAllSkills]
    )


    return (
        <FormsContainerWrapper xs={12} sm={12} md={12} lg={9}>
            <div>
                <Element name="basicInfo">
                    <UserDetails user={user.user} countries={countries.countries} />
                </Element>
                <Element name="academyInfo">
                    <AcademyDetails user={user.user} />
                </Element>
                <Element name="bio">
                    <UserSkills user={user.user} skills={skills.skills} />
                </Element>
                <Element name="onWeb">
                    <WebDetails user={user.user} />
                </Element>
                <Element name="privacy">
                    <PrivacyDetails user={user.user} />
                </Element>
            </div >
        </FormsContainerWrapper >
    )
}

function mapStateToProps(state) {
    return {
        user: state.userReducer,
        countries: state.countryReducer,
        skills: state.skillReducer
    }
}

const mapDispatchToProps = {
    getUserDetails: userActions.getUserDetails,
    getAllCountries: countryActions.getAllCountries,
    getAllSkills: skillActions.getAllSkills
}

export default connect(mapStateToProps, mapDispatchToProps)(FormsContainer)
