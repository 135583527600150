import React from 'react'
import styled from 'styled-components'
import Col from 'react-bootstrap/Col'
import ScrollspyComponent from 'react-scrollspy'
import { Link } from 'react-scroll'

const ScrollspyContainer = styled(Col)`
    font-weight: bold;
    font-size: 18px;
    line-height: 21px;
    color: #191919;
    padding-right: 0px !important;
    padding-left: 0px !important;
    margin-top: 20%;

    a {
        color: #191919;
    }

    @media (max-width: 991px) {
        display: none;
    }

    @media (min-width: 320px) and (max-width: 480px) {
        display: none;
    }

`
const StyledLink = styled(Link)`
    display: block;
    font-family: Ubuntu;
    font-style: normal;
    font-weight: bold;
    font-size: 14px;
    line-height: 16px;
    margin-bottom: 2%;
    color: #000000;
    cursor: pointer;
`

const BulletPoint = styled.div`
    display: inline-block;
    width: 8px;
    height: 8px;
    border-radius: 100px;
    background-color: transparent;
    margin-left: 5px;
    cursor: auto;
`

export default function Scrollspy() {
    return (
        <ScrollspyContainer xs={12} sm={12} md={3}>
            <div className="sticky-top">
                <StyledLink activeClass="activeLink" to="basicInfo" spy={true} smooth={true} duration={50}>
                    Basic info
                    <BulletPoint />
                </StyledLink>
                <StyledLink activeClass="activeLink" to="academyInfo" spy={true} smooth={true} duration={50}>
                    Academy Info
                    <BulletPoint />
                </StyledLink>
                <StyledLink activeClass="activeLink" to="bio" spy={true} smooth={true} duration={50}>
                    Bio
                    <BulletPoint />
                </StyledLink>
                <StyledLink activeClass="activeLink" to="onWeb" spy={true} smooth={true} duration={50}>
                    On The Web / CV
                    <BulletPoint />
                </StyledLink>
                <StyledLink activeClass="activeLink" to="privacy" spy={true} smooth={true} duration={50}>
                    Privacy
                    <BulletPoint />
                </StyledLink>
            </div>
        </ScrollspyContainer>
    )
}
