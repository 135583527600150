import React, { useState, useEffect } from 'react'
import styled from 'styled-components';
import StarRatings from 'react-star-ratings'
import SurveyInstructorSelect from '../SurveyInstructorSelect';
import $ from "jquery"

const QuestionWrapper = styled.div`
    width: 90%;
    margin: auto;
    padding-bottom: 40px;
`

const Wrapper = styled.div`
    margin-top: 20px;

    & .lowGradeComment {
        font-size: 12px;
        color: red;
    }
`

export default function SurveyQuestion({ id, name, rating, ratingChangeAction, inputChangeAction, statusChange, type, question, stars, description, instructors, instructorType, comment }) {
    const [isQuestionActive, setIsQuestionActive] = useState(true)
    useEffect(() => {
        if (!isQuestionActive) {
            $(`.${instructorType}`).attr('disabled', 'disabled')
            $(`.${instructorType}`).val('')
            $(`.text${instructorType}`).attr('disabled', 'disabled')
            $(`.text${instructorType}`).val('')
        } else {
            $(`.${instructorType}`).removeAttr('disabled')
            $(`.text${instructorType}`).removeAttr('disabled')
        }
    }, [isQuestionActive])
    const handleCheckboxChange = (e) => {
        let status = !isQuestionActive
        $(`.${instructorType}`).each(function () {
            statusChange($(this).attr('name'), status)
        })
        setIsQuestionActive(() => status)
    }

    const printAnswerBasedOnType = () => {
        // if type is NULL in database, we should print both stars and textarea
        if (type === 'RATING') {
            return printRatingAnswer();
        } else if (type === 'ANSWER') {
            return printCommentAnswer();
        } else {
            return printRatingWithCommentAnswer();
        }
    }

    const printRatingAnswer = () => {
        return (<Wrapper>
            <input type="checkbox" name={"status_" + id} onChange={handleCheckboxChange} value={id} /> <span>I didn't attend</span>
            <br />
            {
                isQuestionActive ?
                    <StarRatings
                        rating={rating}
                        starRatedColor="#0A08E5"
                        numberOfStars={stars}
                        name={name}
                        changeRating={ratingChangeAction}
                        starHoverColor="#0A08E5"
                    />
                    :
                    <StarRatings
                        rating={0}
                        starRatedColor="#0A08E5"
                        numberOfStars={stars}
                        name={name}
                        starHoverColor="#0A08E5"
                    />
            }
        </Wrapper>)
    }

    const printCommentAnswer = () => {
        return (<div className="form-group">
            <textarea className={"form-control text" + instructorType} rows="3" name={name} onChange={inputChangeAction} />
        </div>)
    }

    const printRatingWithCommentAnswer = () => {
        return (<Wrapper>
            {
                isQuestionActive ?
                    <StarRatings
                        rating={rating}
                        starRatedColor="#0A08E5"
                        numberOfStars={stars}
                        name={name}
                        changeRating={ratingChangeAction}
                        starHoverColor="#0A08E5"
                    />
                    :
                    <StarRatings
                        rating={0}
                        starRatedColor="#0A08E5"
                        numberOfStars={stars}
                        name={name}
                        starHoverColor="#0A08E5"
                    />
            }
            <p>Leave a comment: </p>
            <div className="form-group">
                <textarea className={"form-control text " + instructorType} rows="3" name={name} onChange={inputChangeAction} />
                {rating && rating <= 3 && comment == null ? <p class="lowGradeComment">Comment is required</p> : ''}
            </div>
        </Wrapper>)
    }

    return (
        <QuestionWrapper>
            <SurveyInstructorSelect question={question} description={description} id={id} changeAction={inputChangeAction} instructors={instructors} instructorType={instructorType} />
            {printAnswerBasedOnType()}
        </QuestionWrapper>
    )
}
