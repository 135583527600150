import React, { useState, useEffect } from 'react'
import styled, { keyframes } from 'styled-components'
import { trackPromise } from 'react-promise-tracker'
import { fadeInUp } from 'react-animations';

import ScrollSpyWrapper from '../RedesignComponents/Homepage/ScrollSpyWrapper'
import ContentWrapper from '../RedesignComponents/Homepage/ContentWrapper'
import MessagesWrapper from '../RedesignComponents/Homepage/MessagesWrapper'
import NotifySurveyModal from '../Common/Modal/NotifySurveyModal'

import { connect } from 'react-redux'
import * as userActions from "../../redux/actions/userActions"
import * as messageActions from "../../redux/actions/messageActions"
import * as pointsActions from "../../redux/actions/pointActions"

const fadeInAnimation = keyframes`${fadeInUp}`;

export const HomepageWrapper = styled.div`
    animation: 1s ${fadeInAnimation};
    display: flex;
    justify-content: space-between;
    width: 98%;
    margin: auto;
`

function HomepageContentWrapper({ user, getUserDetails, getMessages, getTotalPoints }) {

    useEffect(() => {
        trackPromise(getUserDetails())
        trackPromise(getMessages())
        trackPromise(getTotalPoints())
    }, [getUserDetails, getMessages, getTotalPoints]
    )

    return (
        <HomepageWrapper>
            <ScrollSpyWrapper />
            <ContentWrapper />
            <MessagesWrapper />
            {
                user && user.activeSurvey ?
                    <NotifySurveyModal show={true} title="Hello, new survey available!" desc="By answering this survey, give us your feedback about your experience with our academies." surveyId={user.activeSurvey} />
                    : null
            }
        </HomepageWrapper>
    )
}

const mapDispatchToProps = {
    getUserDetails: userActions.getUserDetails,
    getMessages: messageActions.getMessages,
    getTotalPoints: pointsActions.getTotalPoints
}

function mapStateToProps(state) {
    return {
        user: state.userReducer.user
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(HomepageContentWrapper)
