import * as types from './actionTypes'
import * as lectureApi from "../../api/lectureApi"
import { logout } from './userActions';
import { navigate } from '@reach/router';


export function allUpcomingLectures(lectures) {
    return { type: types.ALL_UPCOMING_LECTURES, lectures }
}

export function getUpcomingLectures() {
    return function (dispatch) {
        return lectureApi.allUpcomingLectures()
            .then((result) => {
                dispatch(allUpcomingLectures(result.lectureLinks))
            }).catch((err) => {
                if(err.status === 401 && err.statusText === "Unauthorized"){
                    localStorage.removeItem('userAT')
                    dispatch(logout())
                    navigate('/login')
                }
                throw err
            });
    }
}
