import React, { useState, useEffect, useCallback } from 'react'
import styled from 'styled-components'
import ReactHtmlParser from 'react-html-parser';
import {Animated} from "react-animated-css"
import { Collapse } from 'antd'
import 'antd/dist/antd.css'

import { connect } from 'react-redux'

const { Panel } = Collapse

const Wrapper = styled.div`
    width: 15%;
    @media screen and (max-width: 700px) {
        width: 0%;
    }
`
const InboxPanel = styled.div`
    background: #FFFFFF;
    box-shadow: 0px 8px 30px rgba(0, 0, 0, 0.08);
    border-radius: 100px 0px 0px 100px;
    width: 60px;
    height: 400px;
    position: absolute;
    right: 0;
    margin-top: 150px;
    align-items: center;
    justify-content: center;
    display: flex;
    cursor: pointer;

    img{
        cursor: pointer;
    }

    @media screen and (max-width: 700px) {
        border-radius: 100px 100px 0px 0px;
        position: fixed;
        bottom: 0px;
        width: 100%;
        height: 60px;
        z-index: 200;
    }
`
const SlidingPanel = styled.div`
    position: absolute;
    z-index: 100;
    width: 400px;
    height: 600px;
    right: 0;
    padding: 30px;
    background: #FFFFFF;
    box-shadow: 0px 8px 16px rgba(0, 0, 0, 0.16);
    border-radius: 20px 0px 0px 20px;
    .closeInbox{
        float: right;
    }

    @media screen and (max-width: 700px) {
        z-index: 200;
        bottom: -150px;
        width: 80vw;
        height: 80vh;
        margin: auto;
    }
`

const SPHeader = styled.div`
    display: flex;
    justify-content: flex-start;
    align-items: flex-end;


    p {
        margin: 0px 0px 0px 30px;
        font-family: Ubuntu;
        font-style: normal;
        font-weight: bold;
        font-size: 18px;
        line-height: 18px;
        color: #4D4D4D;
    }
`

const SPBody = styled.div`
    margin-top: 40px;
    height: 400px;
    overflow: auto;
`

const AnimatedStyle = {
    position: "absolute",
    right: "0px",
    top: "-100px",
}

const PanelHeaderStyle = {
    display: "flex",
    justifyContent: "flex-start",
    alignItems: "flex-end"
}

const PanelHeaderTitleStyle = {
    margin: "0px 0px 0px 20px",
    fontFamily: "Ubuntu",
    fontStyle: "normal",
    fontWeight: "bold",
    fontSize: "16px",
    lineHeight: "18px",
    color: "#191919"
}

const Author = styled.p`
    color: #232426;
    font-weight: bold;
    font-size: 14px;
    line-height: 16px;
    margin-top: 10px;
`

function MessagesWrapper({ messages }) {
    const [messagesArray, setMessagesArray] = useState([])
    const [showMessagePanel, setShowMessagePanel] = useState(false)

    useEffect(() => {
        setMessagesArray(() => {
            if(messages){
                let allMessages = Object.keys(messages).map((messageId) => { return messages[messageId] })
                let findLatestSurvey = false
                let finalArray = []
                allMessages.forEach((msg) => {
                    if(msg.author === 'Admin User' && !findLatestSurvey){
                        finalArray.push(msg)
                        findLatestSurvey = true
                    }else if (msg.author !== 'Admin User'){
                        finalArray.push(msg)
                    }
                })
                return finalArray
            }else{
                return []
            }
        })

    }, [messages])

    const handleInboxClick = (e) => {
        if(e.target.classList.contains('inbox')){
            setShowMessagePanel(!showMessagePanel)
        }
    }

    return (
        <Wrapper>
                <InboxPanel onClick={handleInboxClick} className="inbox">
                    <img src="/inbox.svg" alt="inbox" className="inbox" onClick={handleInboxClick}/>
                    <Animated animationIn={window.innerWidth > 700 ? "fadeInRightBig" : "fadeInUpBig" } animationOut="fadeOutRight" isVisible={showMessagePanel} style={AnimatedStyle} animateOnMount={false}>
                        <SlidingPanel>
                            <img src="/close-inbox.svg" alt="close icon" className="closeInbox inbox" onClick={handleInboxClick}/>
                            <SPHeader>
                                <img src="/inbox.svg" alt="inbox header"/>
                                <p>Messages from Brainster</p>
                            </SPHeader>
                            <SPBody>
                                <Collapse accordion bordered={false} ghost>
                                    {
                                        messagesArray.map((message) => {
                                            return <Panel header={
                                                    <div style={PanelHeaderStyle}>
                                                        <img src="/msg-icon.svg" alt="MSG Icon"/>
                                                        <p style={PanelHeaderTitleStyle}>{message.title}</p>
                                                    </div>
                                                } key={message.id} showArrow={false}>
                                                <span>{ ReactHtmlParser(message.message) }</span>
                                                {message.link ?
                                                    <p>More info <a href={message.link} style={{ color: "#0A08E5"}} rel="noopener noreferrer" target="_blank">Here</a></p>
                                                    :
                                                    ""
                                                }
                                                <Author>-  {message.author}</Author>
                                            </Panel>
                                        })
                                    }
                                </Collapse>
                            </SPBody>
                        </SlidingPanel>
                    </Animated>
                </InboxPanel>
            </Wrapper>
    )
}

function mapStateToProps(state){
    return {
        messages: state.messageReducer.messages
    }
}

export default connect(mapStateToProps, null)(MessagesWrapper)
