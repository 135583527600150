import React, { useState, useEffect} from 'react'
import Container from 'react-bootstrap/Container';
import MaterialsInfoContent from '../MaterialspageComponents/MaterialsInfoContent';
import MaterialsWrapper from '../MaterialspageComponents/MaterialsWrapper';
import CategoryCompleted from '../Common/CategoryCompleted/CategoryCompleted';
import { navigate } from '@reach/router';

export default function MaterialsContentWrapper({ slug, activeCategory }) {
    const [totalResources, setTotalResources] = useState(0)
    const [totalCompleted, setTotalCompleted] = useState(0)
    const [totalProgress, setTotalProgress] = useState(0)
    const [totalDuration, setTotalDuration] = useState(0)
    const [showRewardPopup, setshowRewardPopup] = useState(false)



    useEffect(() => {
        setTotalProgress((totalCompleted/totalResources).toFixed(2) * 100)
    }, [totalResources, totalCompleted])

    const categoryCompleted = () => {
        if(totalResources - totalCompleted === 1){
            setshowRewardPopup(true)
        }
    }

    const handleClaimBtnClicked = () => {
        setshowRewardPopup(false)
        navigate('/')
    }

    return (
        <Container fluid className="materialsContainer">
            <MaterialsWrapper slug={slug}/>
            <CategoryCompleted show={showRewardPopup} activeCategory={activeCategory} handleClaimBtnClicked={handleClaimBtnClicked}/>
        </Container>
    )
}
